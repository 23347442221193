import { isoDateCodec, validDateTimeCodecS } from "@/utils/codecs";
import { Eq } from "fp-ts/lib/Eq";
import { Ord } from "fp-ts/lib/Ord";
import { Eq as EqString, Ord as OrdString } from "fp-ts/lib/string";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import { branchIdCodec, extensionRoleCodec, userIdCodec } from "./client";
import {
  applicationIdCodec,
  ApplicationStatusType,
  applicationStatusTypeCodec,
  applicationTypeCodec,
} from "./payload";

export const applicationAssigneeCodec = t.type({
  userId: userIdCodec,
  firstName: tt.NonEmptyString,
  lastName: tt.NonEmptyString,
  authorizationRoleType: t.string.pipe(extensionRoleCodec),
});

export type ApplicationAssignee = t.TypeOf<typeof applicationAssigneeCodec>;

export const EqApplicationStatus: Eq<ApplicationStatusType> = EqString;
export const OrdApplicationStatus: Ord<ApplicationStatusType> = OrdString;

export const applicationListItemCodec = t.type({
  applicationId: applicationIdCodec,
  applicantsName: tt.NonEmptyString,
  isCreditCheckAuthorized: t.boolean,
  mortgageType: t.string.pipe(applicationTypeCodec),
  dateSubmitted: t.string.pipe(validDateTimeCodecS),
  assignees: t.array(applicationAssigneeCodec),
  status: t.string.pipe(applicationStatusTypeCodec),
  percentComplete: t.number,
});

export type ApplicationListItem = t.TypeOf<typeof applicationListItemCodec>;

export const applicationsFilterCodec = t.type({
  startDate: t.string.pipe(isoDateCodec),
  endDate: t.string.pipe(isoDateCodec),
  statusesToInclude: t.array(t.string.pipe(applicationStatusTypeCodec)),

  branchId: tt.optionFromNullable(branchIdCodec),
  assignedToUserId: tt.optionFromNullable(userIdCodec),
});
export type ApplicationsFilterPayload = t.TypeOf<
  typeof applicationsFilterCodec
>;
