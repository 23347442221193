import { EmployersPayload } from "./../../data/payload";
import "@/assets/styles/Summary/summary.css";
import * as Documents from "@/components/Documents";
import * as EmployerResolver from "@/components/EmployerResolver";
import * as FinancialInformation from "@/components/FinancialInformation";
import * as ManualDocClassifier from "@/components/ManualDocClassifier";
import * as PersonalInformation from "@/components/PersonalInformation";
import * as SurveyInitiation from "@/components/SurveyInitiation";
import { DocumentTypeId } from "@/data/applicationDocument";
import { Application, ApplicationId, SurveyPayload } from "@/data/payload";
import { Api } from "@/utils/api";
import {
  AsyncOperationStatus,
  Finished,
  Started,
} from "@/utils/asyncOperationStatus";
import { InProgress, NotStarted, Resolved } from "@/utils/deferred";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  effectOfFunc_,
  effectsBatch,
  mapEffect,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult, showApiError } from "@/utils/request";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as Tup from "fp-ts/lib/Tuple";
import { Lens } from "monocle-ts";
import { ApplicationViewMode, Model } from "./model";

export type ApplicantId =
  | {
      type: "Primary";
    }
  | {
      type: "CoApplicant";
      index: number;
    };

export type Action =
  | {
      type: "SaveSurvey";
      payload: SurveyPayload;
      operation: AsyncOperationStatus<ApiResult<ApplicationId>>;
    }
  | {
      type: "StartManualDocumentSelection";
      documentTypeId: DocumentTypeId
    }
  | {
      type: "CancelManualDocumentSelection";
    }
  | {
      type: "MoveToEditMode";
    }
  | {
      type: "SetMissingEmployer";
      payload: EmployersPayload;
    }
  | {
      type: "ClearMissingEmployer";
    }
  | {
      type: "PollEmployers";
      applicationId: ApplicationId;
      operation: AsyncOperationStatus<ApiResult<Application>>;
    }
  | {
      type: "SurveyInitiationAction";
      action: SurveyInitiation.Action;
    }
  | {
      type: "PersonalInformationAction";
      applicantId: ApplicantId;
      action: PersonalInformation.Action;
    }
  | {
      type: "FinancialInformationAction";
      applicantId: ApplicantId;
      action: FinancialInformation.Action;
    }
  | {
      type: "DocumentsAction";
      action: Documents.Action;
    }
  | {
      type: "EmployerResolverAction";
      action: EmployerResolver.Action;
    }
  | {
      type: "SubmitManualClasification";
      file: File;
      operation: AsyncOperationStatus<ApiResult<void>>;
    }
  | {
      type: "ManualClassificationFileSelected";
      file: O.Option<File>;
    }
  | {
      type: "ExportDocuments";
      applicationId: ApplicationId;
      operation: AsyncOperationStatus<ApiResult<string>>;
    }
  | {
      type: "ResetExportStatus";
    }
  | {
      type: "ShowExportWarning";
    }
  | {
      type: "AcceptExportWarning";
    };

export const SaveSurvey =
  (payload: SurveyPayload) =>
  (operation: AsyncOperationStatus<ApiResult<ApplicationId>>): Action => ({
    type: "SaveSurvey",
    payload,
    operation,
  });

export const SetMissingEmployer = (payload: EmployersPayload): Action => ({
  type: "SetMissingEmployer",
  payload,
});

export const ClearMissingEmployer = (): Action => ({
  type: "ClearMissingEmployer",
});

export const SurveyInitiationAction = (
  action: SurveyInitiation.Action,
): Action => ({
  type: "SurveyInitiationAction",
  action,
});

export const PersonalInformationAction =
  (applicantId: ApplicantId) =>
  (action: PersonalInformation.Action): Action => ({
    type: "PersonalInformationAction",
    applicantId,
    action,
  });

export const FinancialInformationAction =
  (applicantId: ApplicantId) =>
  (action: FinancialInformation.Action): Action => ({
    type: "FinancialInformationAction",
    applicantId,
    action,
  });

export const PollEmployers =
  (applicationId: ApplicationId) =>
  (operation: AsyncOperationStatus<ApiResult<Application>>): Action => ({
    type: "PollEmployers",
    applicationId,
    operation,
  });

export const DocumentsAction = (action: Documents.Action): Action => ({
  type: "DocumentsAction",
  action,
});

export const EmployerResolverAction = (
  action: EmployerResolver.Action,
): Action => ({
  type: "EmployerResolverAction",
  action,
});

export const StartManualDocumentSelection = (documentTypeId: DocumentTypeId) => (): Action => ({
  type: "StartManualDocumentSelection",
  documentTypeId
});

export const CancelManualDocumentSelection = (): Action => ({
  type: "CancelManualDocumentSelection",
});
export const MoveToEditMode = (): Action => ({
  type: "MoveToEditMode",
});

export const SubmitManualClasification =
  (file: File) =>
  (operation: AsyncOperationStatus<ApiResult<void>>): Action => ({
    type: "SubmitManualClasification",
    file,
    operation,
  });

export const ManualClassificationFileSelected = (
  file: O.Option<File> 
) => (): Action => ({
  type: "ManualClassificationFileSelected",
  file,
});

export const ExportDocuments =
  (applicationId: ApplicationId) =>
  (operation: AsyncOperationStatus<ApiResult<string>>): Action => ({
    type: "ExportDocuments",
    applicationId,
    operation,
  });

export const ResetExportStatus = (): Action => ({
  type: "ResetExportStatus",
});
export const ShowExportWarning = (): Action => ({
  type: "ShowExportWarning",
});
export const AcceptExportWarning = (): Action => ({
  type: "AcceptExportWarning",
});

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    console.log(`Wizard action: ${action.type}`);

    switch (action.type) {
      case "SaveSurvey":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                model,
                effectOfAsync(
                  api.saveSurvey(action.payload),
                  flow(Finished, SaveSurvey(action.payload)),
                ),
              ];
            case "Finished":
              return pipe(
                action.operation.result,
                E.fold(
                  (err) => [
                    model,
                    effectOfFunc_(
                      (e) => alert(`Failed to save survey: ${showApiError(e)}`),
                      err,
                    ),
                  ],
                  (applicationId) => {
                    const [documentsModel, documentsEffect] =
                      Documents.init(applicationId, model.documents.canDownloadDocs);
                    return [
                      { ...model, documents: documentsModel },
                      effectsBatch([
                        effectOfFunc_(
                          () => alert("Survey saved successfully"),
                          undefined,
                        ),
                        mapEffect(DocumentsAction)(documentsEffect),
                      ]),
                    ];
                  },
                ),
              );
          }
        }
        break;

      case "SurveyInitiationAction": {
        return [
          {
            ...model,
            surveyInitiation: SurveyInitiation.update(
              model.surveyInitiation,
              action.action,
            ),
          },
          noEffect,
        ];
      }

      case "SetMissingEmployer": {
        const [employerResolverModel] = EmployerResolver.init(
          model,
          action.payload,
        );
        return [
          {
            ...model,
            employerResolverModel: O.some(employerResolverModel),
          },
          noEffect,
        ];
      }

      case "ClearMissingEmployer": {
        return [
          {
            ...model,
            employerResolverModel: O.none,
            employers: O.none,
          },
          noEffect,
        ];
      }
      
      case "MoveToEditMode": {
        return [
          {
            ...model,
            mode: ApplicationViewMode.Edit          
          },
          noEffect,
        ];
      }

      case "PersonalInformationAction":
        {
          switch (action.applicantId.type) {
            case "Primary": {
              const lens = Lens.fromPath<Model>()([
                "primaryApplicant",
                "personalInformation",
              ]);

              return [
                lens.modify(PersonalInformation.update(action.action))(model),
                noEffect,
              ];
            }

            case "CoApplicant": {
              const lens = Lens.fromPath<Model>()([
                "mortgageInformation",
                "coApplicants",
                action.applicantId.index,
                "personalInformation",
              ]);

              return [
                lens.modify(PersonalInformation.update(action.action))(model),
                noEffect,
              ];
            }
          }
        }
        break;

      case "FinancialInformationAction":
        {
          switch (action.applicantId.type) {
            case "Primary": {
              const lens = Lens.fromPath<Model>()([
                "primaryApplicant",
                "financialInformation",
              ]);

              return [
                lens.modify(FinancialInformation.update(action.action))(model),
                noEffect,
              ];
            }

            case "CoApplicant": {
              const lens = Lens.fromPath<Model>()([
                "mortgageInformation",
                "coApplicants",
                action.applicantId.index,
                "financialInformation",
              ]);

              return [
                lens.modify(FinancialInformation.update(action.action))(model),
                noEffect,
              ];
            }
          }
        }
        break;

      case "DocumentsAction": {
        return pipe(
          Documents.update(api, model.documents, action.action),
          Tup.bimap(mapEffect(DocumentsAction), (documents) => ({
            ...model,
            documents,
          })),
        );
      }
      case "EmployerResolverAction": {
        return pipe(
          EmployerResolver.update(api)(
            pipe(
              model.employerResolverModel,
              O.getOrElse(() => null as unknown as EmployerResolver.Model),
            ),
            action.action,
          ),
          Tup.bimap(
            mapEffect(EmployerResolverAction),
            (employerResolverModel) => ({
              ...model,
              employerResolverModel: O.some(employerResolverModel),
            }),
          ),
        );
      }
      case "PollEmployers":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                model,
                effectOfAsync(
                  api.getApplication(action.applicationId),
                  flow(Finished, PollEmployers(action.applicationId)),
                ),
              ];
            case "Finished":
              return pipe(
                action.operation.result,
                E.fold(
                  (err) => [
                    model,
                    effectOfFunc_(
                      (e) =>
                        alert(
                          `Unable to get employment details: ${showApiError(e)}`,
                        ),
                      err,
                    ),
                  ],
                  (application) => {
                    return [
                      {
                        ...model,
                        employers: pipe(
                          O.some(application.survey.primaryApplicant.employers),
                          O.map((v): EmployersPayload[] => {
                            const employersForCoApplicants =
                              application.survey.jointApplicants.map(
                                (v) => v.applicant.employers,
                              );
                            return [
                              ...v,
                              ...pipe(
                                employersForCoApplicants,
                                A.flatMap((v) => v),
                              ),
                            ];
                          }),
                        ),
                      },
                      noEffect,
                    ];
                  },
                ),
              );
          }
        }
        break;

      case "StartManualDocumentSelection":
        {
          return [
            {
              ...model,
              manualClassification: {
                isOpen: true,
                file: O.none,
                documentTypeId: action.documentTypeId,
                submissionsState: NotStarted(),
              },
            },
            noEffect,
          ];
        }
        break;
      case "CancelManualDocumentSelection":
        {
          return [
            {
              ...model,
              manualClassification: {
                isOpen: false,
                file: O.none,
                documentTypeId: NaN as DocumentTypeId,
                submissionsState: NotStarted(),
              },
            },
            noEffect,
          ];
        }
        break;

      case "ManualClassificationFileSelected":
        {
          return [
            {
              ...model,
              manualClassification: {
                isOpen: true,
                file: action.file,
                documentTypeId: model.manualClassification.documentTypeId,
                submissionsState: NotStarted(),
              },
            },
            noEffect,
          ];
        }
        break;
      case "SubmitManualClasification":
        {
          switch (action.operation.status) {
            case "Started": {
              return [
                {
                  ...model,
                  manualClassification:
                    ManualDocClassifier.updateDocSubmissionResult(
                      model.manualClassification,
                      InProgress(),
                    ),
                },
                effectOfAsync(
                  api.submitManualClassification(
                    model.applicationId,
                    model.manualClassification.documentTypeId,
                  )(action.file),
                  flow(Finished, SubmitManualClasification(action.file)),
                ),
              ];
            }
            case "Finished":
              if (E.isRight(action.operation.result)) {
                return [
                  {
                    ...model,
                    manualClassification: ManualDocClassifier.init(),
                  },
                  noEffect,
                ];
              }
              return [
                {
                  ...model,
                  manualClassification:
                    ManualDocClassifier.updateDocSubmissionResult(
                      model.manualClassification,
                      Resolved(action.operation.result),
                    ),
                },
                noEffect,
              ];
          }
        }
        break;
      case "ExportDocuments": {
        switch (action.operation.status) {
          case "Started": {
            const apiCallerAction = effectOfAsync(
              api.exportDocuments(action.applicationId),
              flow(Finished, ExportDocuments(action.applicationId)),
            );

            return [
              {
                ...model,
                exportWarningShown: O.none,
                exportStatus: InProgress(),
              },
              pipe(
                model.exportWarningShown,
                O.chain((v) => O.fromPredicate(identity<boolean>)(v)),
                O.map((v) => (v ? apiCallerAction : noEffect)),
                O.getOrElse(() => apiCallerAction),
              ),
            ];
          }
          case "Finished":
            return [
              {
                ...model,
                exportStatus: Resolved(action.operation.result),
              },
              noEffect,
            ];
        }
        break;
      }
      case "ResetExportStatus": {
        return [
          {
            ...model,
            exportStatus: NotStarted(),
          },
          noEffect,
        ];
      }

      case "ShowExportWarning":
        {
          return [{ ...model, exportWarningShown: O.some(false) }, noEffect];
        }
        break;
      case "AcceptExportWarning": {
        return [
          { ...model, exportWarningShown: O.some(true) },
          effectOfAction(flow(Started, ExportDocuments(model.applicationId))()),
        ];

        break;
      }
    }
  };
