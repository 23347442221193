import {
  Button,
  Col,
  Icon,
  IconType,
  Label,
  Modal,
  Row,
  Select,
  Table,
} from "@/components/basic";
import { ApplicationListItem } from "@/data/applicationsList";
import {
  CustomerProfileAverages,
  DashboardAnalytics,
  LivingArrangementsComposition,
  LoanTypesComposition,
  ReferralSourceItem,
  SurveyActivity,
} from "@/data/dashboardAnalytics";
import { Started } from "@/utils/asyncOperationStatus";
import { EqSameDate, nesHead } from "@/utils/codecs";
import { ChildProps } from "@/utils/reducerWithEffect";
import { showApiError } from "@/utils/request";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import * as Eq from "fp-ts/Eq";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as SG from "fp-ts/lib/Semigroup";
import * as M from "fp-ts/Map";
import { Eq as EqNumber } from "fp-ts/number";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { Eq as EqString } from "fp-ts/string";
import Highcharts from "highcharts";
import { last } from "lodash-es";
import { DateTime, Duration } from "luxon";
import { useEffect, useMemo, useRef } from "react";
import {
  Action,
  ApplicationIntervalToggled,
  BranchChanged,
  CancelFirstLook,
  CancelInvites,
  IntervalChanged,
  InviteTeamClicked,
  LoadAnalytics,
  LoadApplications,
} from "./action";
import "@/assets/styles/Dashboard/dashboard.css";
import {
  analyticsIntervals,
  analyticsIntervalValues,
  EqAnalyticsInterval,
  Model,
  ReportingInterval,
  subtractIntervalFrom,
} from "./model";
import { WelcomeView } from "./welcome-view";
import { ApplicationProgress } from "../ApplicationProgressBar";
import { Tag } from "../basic/Tag";
import { InviteMembers } from "./invite-members";

const pieChart =
  (ref: HTMLDivElement) =>
  (title: string) =>
  (data: Highcharts.PointOptionsObject[]) =>
    Highcharts.chart(ref, {
      chart: {
        width: 200,
        height: 420,
      },

      title: {
        text: title,
        align: "center",
      },
      tooltip: {
        enabled: false,
      },

      plotOptions: {
        pie: {
          // size: "240%",
          innerSize: "65%",
          // center: ["50%", "100%"],
          // startAngle: -90,
          // endAngle: 90,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          animation: false,
        },
      },

      series: [
        {
          type: "pie",
          name: title,
          colors: ["#6975FF", "#084FD7", "#64BDC6", "#EECA34", "#FE7135"],
          data,
          showInLegend: true,
        },
      ],
    });

const lineChart =
  (ref: HTMLDivElement) =>
  (title: string) =>
  (series: Highcharts.SeriesOptionsType[]) =>
    Highcharts.chart(ref, {
      chart: {
        type: "line",
        width: 530,
        height: 300,
      },
      title: {
        text: title,
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%b %e}",
        },
      },
      yAxis: {
        title: {
          text: title,
        },
      },
      series,
    });

const plotLivigArrangementsComposition = (
  livingArrangemnets: LivingArrangementsComposition,
): Highcharts.PointOptionsObject[] => [
  { name: "Living rent free", y: livingArrangemnets.livingRentFreePrecent },
  {
    name: "Own and making payments",
    y: livingArrangemnets.ownAndMakingPaymentsPercent,
  },
  {
    name: "Own and paid in full",
    y: livingArrangemnets.ownAndPaidInFullPercent,
  },
  { name: "Rent", y: livingArrangemnets.rentPercent },
];

const plotLoanTypesComposition = (
  loanTypes: LoanTypesComposition,
): Highcharts.PointOptionsObject[] => [
  { name: "Refinance", y: loanTypes.refinancePercent },
  { name: "Purchase", y: loanTypes.purchasePercent },
  { name: "Home equity", y: loanTypes.homeEquityPercent },
];

const plotNewSurveys = (
  surveyActivity: SurveyActivity,
): Highcharts.PointOptionsObject => ({
  x: surveyActivity.activityDate.toMillis(),
  y: surveyActivity.newSurveys,
});

const plotApplicationExports = (
  surveyActivity: SurveyActivity,
): Highcharts.PointOptionsObject => ({
  x: surveyActivity.activityDate.toMillis(),
  y: surveyActivity.applicationsExported,
});

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model } = props;

  const applicationsCount = pipe(
    model.analytics.status,
    O.fromPredicate((v) => v == "Resolved"),
    O.chain(constant(O.some(model.applications))),
    O.chain((applications) =>
      pipe(
        applications,
        O.fromPredicate((v) => v.status == "Resolved"),
      ),
    ),
    O.chain((v) => (E.isRight(v.value) ? O.some(v.value.right) : O.none)),
    O.map((v) => v.length),
    O.getOrElse(constant(NaN)),
  );

  const usersCount = pipe(
    props.model.client,
    O.fromPredicate((v) => v.status == "Resolved"),
    O.chain((v) => (E.isRight(v.value) ? O.some(v.value.right) : O.none)),
    O.map(
      (v) =>
        v.users.length +
        v.branches.reduce((usersCount, b) => usersCount + b.users.length, 0),
    ),
    O.getOrElse(constant(NaN)),
  );

  const shouldShowWelcomeView =
    applicationsCount == 0 && !Number.isNaN(usersCount);

    const onInvitesDone = O.some(flow(CancelInvites, props.dispatch))

  if(O.isSome(model.invitedMembers)) {
    return <InviteMembers {...{...props, onInvitesDone} }/>
  }
  return (
    <Col>    
      {shouldShowWelcomeView ? (
        <WelcomeView {...{ usersCount, applicationsCount }} />
      ) : (
        <>
          <AnalyticsView {...props} />
          <ApplicationsView {...props} />
        </>
      )}
      {props.model.isFirstLook && (
        <Modal
          onClose={O.some(flow(CancelFirstLook, props.dispatch))}
          title={O.some("Welcome to AppCatcher")}
        >
          <Col alignHorizontal="center" gap="sm" padding="sm" grow={0} width="2/4">
            Congrats! You are all set now. Next, you can invite your team into AppCatcher to start processing applications!
            <Col gap="md" >
            <Button
              className="width-auto"
              type="primary"
              onClick={O.some(flow(CancelFirstLook, props.dispatch))}
            >
              Go to Dashboard
            </Button>
            <Button type="secondary" onClick={O.some((flow(InviteTeamClicked, props.dispatch)))}>
              Invite Team Members
            </Button>
            </Col>
          </Col>
        </Modal>
      )}
    </Col>
  );
}

function AnalyticsView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  switch (model.analytics.status) {
    case "NotStarted":
      return (
        <Button
          type="primary"
          onClick={O.some(
            flow(
              Started,
              LoadAnalytics(DateTime.now().minus({ days: 7 }), DateTime.now()),
              dispatch,
            ),
          )}
        >
          Load Analytics
        </Button>
      );
    case "InProgress":
    case "Updating":
      return <div className="chart-container">Loading analytics...</div>;
    case "Resolved":
      return pipe(
        model.analytics.value,
        E.fold(
          (error) => (
            <Col padding="sm" gap="sm" className="chart-container">
              <Label>Failed to load analytics</Label>
              {showApiError(error)}
            </Col>
          ),
          (analytics) => (
            <AnalyticsContent
              analytics={analytics}
              selectedBranch={model.selectedBranch}
              selectedInterval={model.selectedInterval}
              dispatch={dispatch}
            />
          ),
        ),
      );
  }
}

function ApplicationsView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  switch (model.applications.status) {
    case "NotStarted":
      return (
        <Button
          type="primary"
          onClick={O.some(
            flow(
              Started,
              LoadApplications(
                subtractIntervalFrom(DateTime.now())("week"),
                DateTime.now(),
              ),
              dispatch,
            ),
          )}
        >
          Load Applications
        </Button>
      );
    case "InProgress":
    case "Updating":
      return <div className="chart-container">Loading applications...</div>;

    case "Resolved":
      return pipe(
        model.applications.value,
        E.fold(
          (error) => (
            <Col padding="sm" gap="sm" className="chart-container">
              <Label>Failed to load applications</Label>
              {showApiError(error)}
            </Col>
          ),
          (applications) => (
            <ApplicationsContent
              applications={applications}
              applicationsInterval={model.applicationsInterval}
              dispatch={dispatch}
            />
          ),
        ),
      );
  }
}

type AnalyticsContentProps = Pick<Props, "dispatch"> & {
  analytics: DashboardAnalytics;
  selectedBranch: Option<string>;
  selectedInterval: ReportingInterval;
};

function AnalyticsContent(props: AnalyticsContentProps): JSX.Element {
  const { dispatch } = props;
  const branches = useMemo(
    () =>
      pipe(
        props.analytics.customerProfileAverages.map((x) => x.branch),
        A.concat(props.analytics.livingArrangements.map((x) => x.branch)),
        A.concat(props.analytics.loanTypes.map((x) => x.branch)),
        A.concat(props.analytics.referralSources.map((x) => x.branch)),
        A.concat(props.analytics.surveyActivities.map((x) => x.branch)),
        A.uniq(EqString),
      ),
    [props.analytics],
  );

  const timeToExport = useMemo(
    () =>
      Duration.fromObject({
        hours: props.analytics.averageTimeToExportInHours,
      }).toFormat("d 'days' h 'hours'"),
    [props.analytics],
  );

  const customerAveragesMap: Map<string, CustomerProfileAverages> = useMemo(
    () =>
      pipe(
        props.analytics.customerProfileAverages,
        A.map(({ branch, ...averages }): [string, CustomerProfileAverages] => [
          branch,
          averages,
        ]),
        M.fromFoldable(EqString, SG.last(), A.Foldable),
      ),
    [props.analytics.customerProfileAverages],
  );

  const customerAveragesData: Option<CustomerProfileAverages> = useMemo(
    () =>
      pipe(
        props.selectedBranch,
        O.chain((branch) => M.lookup(EqString)(branch)(customerAveragesMap)),
      ),
    [customerAveragesMap, props.selectedBranch],
  );

  const livingArranegmentsMap: Map<string, LivingArrangementsComposition> =
    useMemo(
      () =>
        pipe(
          props.analytics.livingArrangements,
          A.map(
            ({
              branch,
              ...livingArrangements
            }): [string, LivingArrangementsComposition] => [
              branch,
              livingArrangements,
            ],
          ),
          M.fromFoldable(EqString, SG.last(), A.Foldable),
        ),
      [props.analytics.livingArrangements],
    );

  const livingArranegmentsData: Option<Highcharts.PointOptionsObject[]> =
    useMemo(
      () =>
        pipe(
          props.selectedBranch,
          O.chain((branch) =>
            M.lookup(EqString)(branch)(livingArranegmentsMap),
          ),
          O.map(plotLivigArrangementsComposition),
        ),
      [livingArranegmentsMap, props.selectedBranch],
    );

  const loanTypesMap: Map<string, LoanTypesComposition> = useMemo(
    () =>
      pipe(
        props.analytics.loanTypes,
        A.map(
          ({ branch, ...loanTypeItems }): [string, LoanTypesComposition] => [
            branch,
            loanTypeItems,
          ],
        ),
        M.fromFoldable(EqString, SG.last(), A.Foldable),
      ),
    [props.analytics],
  );

  const loanTypesData: Option<Highcharts.PointOptionsObject[]> = useMemo(
    () =>
      pipe(
        props.selectedBranch,
        O.chain((branch) => M.lookup(EqString)(branch)(loanTypesMap)),
        O.map(plotLoanTypesComposition),
      ),
    [loanTypesMap, props.selectedBranch],
  );

  const referralSourcesMap: Map<string, ReferralSourceItem[]> = useMemo(
    () =>
      pipe(
        props.analytics.referralSources,
        A.map(
          ({ branch, referralSourceItems }): [string, ReferralSourceItem[]] => [
            branch,
            referralSourceItems,
          ],
        ),
        M.fromFoldable(
          EqString,
          A.getUnionSemigroup(
            Eq.struct({ description: EqString, percent: EqNumber }),
          ),
          A.Foldable,
        ),
      ),
    [props.analytics],
  );

  const referralSourcesData: Option<Highcharts.PointOptionsObject[]> = useMemo(
    () =>
      pipe(
        props.selectedBranch,
        O.chain((branch) => M.lookup(EqString)(branch)(referralSourcesMap)),
        O.map(
          A.map(
            (source): Highcharts.PointOptionsObject => ({
              name: source.description,
              y: source.percent,
            }),
          ),
        ),
      ),
    [referralSourcesMap, props.selectedBranch],
  );

  const surveyActivitiesMap: Map<string, SurveyActivity[]> = useMemo(
    () =>
      pipe(
        props.analytics.surveyActivities,
        A.map(({ branch, ...activity }): [string, SurveyActivity[]] => [
          branch,
          [activity],
        ]),
        M.fromFoldable(
          EqString,
          A.getUnionSemigroup(
            Eq.struct({
              activityDate: EqSameDate,
              newSurveys: EqNumber,
              applicationsExported: EqNumber,
            }),
          ),
          A.Foldable,
        ),
      ),
    [props.analytics],
  );

  const reducer = (
    sum: Highcharts.PointOptionsObject[],
    a: Highcharts.PointOptionsObject,
  ) => {
    const prev = last(sum);

    if (!prev?.y) {
      sum.push({ ...a });
      return sum;
    }
    sum.push({ ...a, y: prev.y + (a?.y ? a.y : 0) });
    return sum;
  };

  const surveyActivitiesData = useMemo(
    () =>
      pipe(
        props.selectedBranch,
        O.chain((branch) => M.lookup(EqString)(branch)(surveyActivitiesMap)),
        O.map((activities): Highcharts.SeriesOptionsType[] => [
          {
            type: "line",
            name: "New Surveys",
            data: activities.map(plotNewSurveys).reduce(reducer, []),
          },
          {
            type: "line",
            name: "Applications Exported",
            data: activities.map(plotApplicationExports).reduce(reducer, []),
          },
        ]),
      ),
    [surveyActivitiesMap, props.selectedBranch],
  );

  return (
    <Col padding="sm" gap="sm">
      <Row gap="sm">
        <Select
          options={branches}
          selected={props.selectedBranch}
          valueEq={EqString}
          placeholder="Select branch"
          renderLabel={identity}
          onChange={flow(BranchChanged, dispatch)}
        />
        <Select
          options={analyticsIntervals}
          selected={O.some(props.selectedInterval)}
          valueEq={EqAnalyticsInterval}
          renderLabel={(key) => analyticsIntervalValues[key]}
          onChange={(key) =>
            flow(DateTime.now, IntervalChanged(key), dispatch)()
          }
        />
      </Row>
      <Row gap="sm" wrap>
        {O.isSome(surveyActivitiesData) && (
          <LineChart
            series={surveyActivitiesData.value}
            title="Survey Activities"
          />
        )}
        {O.isSome(livingArranegmentsData) && (
          <PieChart
            data={livingArranegmentsData.value}
            title="Current living arrangement"
          />
        )}
        {O.isSome(loanTypesData) && (
          <PieChart data={loanTypesData.value} title="Refinance vs Purchase" />
        )}
        {O.isSome(referralSourcesData) && (
          <PieChart
            data={referralSourcesData.value}
            title="Top Referral Sources"
          />
        )}
      </Row>
      <Row gap="sm" wrap>
        <Col gap="sm" padding="sm" className="chart-container">
          <Label>Your work items</Label>
          <Button type="secondary" onClick={O.some(() => {})}>
            <Col gap="xs">
              <Label>{props.analytics.applicationsRequiringFollowUp}</Label>
              <span>Applications requiring follow up</span>
              <b>View applications &gt;</b>
            </Col>
          </Button>
          <Button type="secondary" onClick={O.some(() => {})}>
            <Col gap="xs">
              <Label>{timeToExport}</Label>
              <span>average time spent to export</span>
              <b>My metrics &gt;</b>
            </Col>
          </Button>
        </Col>
        {O.isSome(customerAveragesData) && (
          <CustomerStatistics
            customerProfileAverages={customerAveragesData.value}
          />
        )}
      </Row>
    </Col>
  );
}

type ApplicationsContentProps = {
  applications: ApplicationListItem[];
  applicationsInterval: ReportingInterval;
  dispatch: (action: Action) => void;
};

const getClientNameForProflieId = (name: string) => {
  if (!name) {
    return "";
  }
  const spilttedName = name.split(" ");
  const hasLastName = spilttedName.length !== 1;
  if (hasLastName) {
    return `${spilttedName[0].charAt(0).toUpperCase()}${spilttedName[spilttedName.length - 1].charAt(0).toUpperCase()}`;
  } else {
    return spilttedName[0].charAt(0).toUpperCase();
  }
};

function ApplicationsContent(props: ApplicationsContentProps): JSX.Element {
  return (
    <Col padding="sm">
      {pipe(
        props.applicationsInterval,
        O.fromPredicate((v) => v == "week"),
        O.fold(
          () => (
            <Row alignHorizontal="left">
              <p className="text-bold text-md"> Activities </p>
              <Button
                onClick={O.some(
                  flow(ApplicationIntervalToggled, props.dispatch),
                )}
                type="flat"
                className="link"
              >
                See Recent
              </Button>
            </Row>
          ),
          () => (
            <Row alignHorizontal="left">
              <p className="text-bold text-md"> Recent Activity </p>
              <Button
                onClick={O.some(
                  flow(ApplicationIntervalToggled, props.dispatch),
                )}
                type="flat"
                className="link"
              >
                See All
              </Button>
            </Row>
          ),
        ),
      )}

      <Table
        className="data-table applications-table"
        data={props.applications}
        itemKey={(app) => app.applicationId}
        columns={[
          {
            columnKey: "applicantsName",
            header: (
              <Row padding="xs">
                <Label className="table-header-label">Client</Label>
              </Row>
            ),
            view: (app) => (
              // <Col padding="sm" gap="xl" className="data-table-cell cell-first applicant-column">
              //   <Label>{app.applicantsName}</Label>
              // </Col>
              <Row
                padding="sm"
                gap="xs"
                className="data-table-cell cell-first applicant-column"
                alignVertical="center"
              >
                <Col>
                  <div className="applicant-id cp">
                    {getClientNameForProflieId(app.applicantsName)}
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Label className="appl-name">{app.applicantsName}</Label>
                  </Row>
                  <Row>
                    <Label className="applicant-description">RR --</Label>
                  </Row>
                </Col>
              </Row>
            ),
          },
          {
            columnKey: "status",
            header: (
              <Row padding="xs">
                <Label className="table-header-label">Status</Label>
              </Row>
            ),
            view: (app) => (
              <Col padding="sm" gap="xl" className="data-table-cell">
                <ApplicationProgress status={app.status} showProgressBar={true}></ApplicationProgress>
              </Col>
            ),
          },
          {
            columnKey: "mortgageType",
            header: (
              <Row padding="xs">
                <Label className="table-header-label">Mortgage type</Label>
              </Row>
            ),
            view: (app) => (
              <Col
                padding="sm"
                gap="xl"
                className="data-table-cell mortagage-type-column"
                alignVertical="center"
              >
                <Tag
                  type="neutral"
                  iconAlign="none"
                  icon={O.some("info")}
                  className="text-sm font-weight-500"
                >
                  {app.mortgageType}
                </Tag>
              </Col>
            ),
          },
          {
            columnKey: "dateSubmitted",
            header: (
              <Row padding="xs">
                <Label className="table-header-label">Submitted</Label>
              </Row>
            ),
            view: (app) => (
              <Col
                padding="sm"
                gap="xl"
                className="data-table-cell"
                alignVertical="center"
              >
                <Tag
                  type="neutral"
                  iconAlign="none"
                  icon={O.some("info")}
                  className="text-sm font-weight-500"
                >
                  {app.dateSubmitted.toLocaleString(DateTime.DATE_MED)}
                </Tag>
              </Col>
            ),
          },
          {
            columnKey: "assignees",
            header: (
              <Row padding="xs">
                <Label className="table-header-label">Assigned</Label>
              </Row>
            ),
            view: (app) => (
              <Col
                padding="sm"
                gap="xl"
                className="data-table-cell cell-last"
                alignVertical="center"
              >
                <Label className="font-weight-500 cp">
                  <i className="fa-solid fa fa-user-o ml-right-xxs" />
                  {app.assignees
                    .map(
                      (assignee) =>
                        `${nesHead(assignee.firstName)} ${assignee.lastName}`,
                    )
                    .join(", ")}
                </Label>
              </Col>
            ),
          },
        ]}
      />
    </Col>
  );
}

type PieChartProps = {
  data: Highcharts.PointOptionsObject[];
  title: string;
};

function PieChart(props: PieChartProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      pieChart(chartRef.current)(props.title)(props.data);
    }
  }, [props.data, chartRef, props.title]);

  return <div ref={chartRef} className="chart-container"></div>;
}

type LineChartProps = {
  series: Highcharts.SeriesOptionsType[];
  title: string;
};

function LineChart(props: LineChartProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      lineChart(chartRef.current)(props.title)(props.series);
    }
  }, [props.series, chartRef, props.title]);

  return <div ref={chartRef} className="chart-container"></div>;
}

type StatisticsItemProps = {
  icon: IconType;
  title: string;
  value: string | number;
};

function StatisticsItem(props: StatisticsItemProps): JSX.Element {
  return (
    <Row gap="xs" alignVertical="center">
      <div className="icon-container">
        <Icon type={props.icon} />
      </div>
      <Col>
        <Label>{props.title}</Label>
        <Label>
          <b>{props.value}</b>
        </Label>
      </Col>
    </Row>
  );
}

type CustomerStatisticsProps = {
  customerProfileAverages: CustomerProfileAverages;
};

function CustomerStatistics(props: CustomerStatisticsProps): JSX.Element {
  return (
    <Col padding="md" gap="md" className="chart-container">
      <Label>Customer Profile</Label>
      <div className="grid-3">
        <StatisticsItem
          icon="user"
          title="Average Age"
          value={props.customerProfileAverages.averageAge}
        />
        <StatisticsItem
          icon="wallet"
          title="Average Income"
          value={pipe(
            props.customerProfileAverages.averageAnnualIncome,
            O.fold(
              () => "unknown",
              (income) => `$${income.toLocaleString()}`,
            ),
          )}
        />
        <StatisticsItem
          icon="map"
          title="Most common location"
          value={props.customerProfileAverages.mostCommonLocation}
        />
        <StatisticsItem
          icon="list-check"
          title="Average survey completion"
          value={pipe(
            props.customerProfileAverages.averageTimeToCompleteSurveyInSeconds,
            O.fold(
              () => "unknown",
              (seconds) =>
                Duration.fromObject({ seconds }).toFormat("mm 'min' ss 'secs'"),
            ),
          )}
        />
        <StatisticsItem
          icon="file-lines"
          title="Average document upload"
          value={pipe(
            props.customerProfileAverages
              .averageTimeToCompleteDocumentUploadInHours,
            O.fold(
              () => "unknown",
              // days, hours
              (hours) =>
                Duration.fromObject({ hours }).toFormat("d 'days' hh 'hours'"),
            ),
          )}
        />
        <StatisticsItem
          icon="gauge"
          title="Average credit score"
          value={pipe(
            props.customerProfileAverages.averageCreditScore,
            O.getOrElseW(() => "unknown"),
          )}
        />
      </div>
    </Col>
  );
}
