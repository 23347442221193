import appLogo from "@/assets/images/AppCatcher.svg";
import "@/assets/styles/App.css";
import { Api, useApi } from "@/utils/api";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  effectsBatch,
  noEffect,
  useReducerWithEffects,
} from "@/utils/reducerWithEffect";
import { ApiError } from "@/utils/request";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import * as AccountSettings from "@/components/AccountSettings";
import * as ApplicationsList from "@/components/ApplicationsList";
import { Button, Col, Icon, Label, Modal, Row } from "@/components/basic";
import * as Dashboard from "@/components/Dashboard";
import * as HomebuyerLanding from "@/components/HomebuyerLanding";
import * as HomePage from "@/components/HomePage";
import * as InactiveBorrower from "@/components/InactiveBorrower";
import * as Invite from "@/components/Invite";
import * as LoInvite from "@/components/LoInvite";
import * as SubmitApplication from "@/components/SubmitApplication";
import * as Summary from "@/components/Summary";
import * as UserManagement from "@/components/UserManagement";
import * as Wizard from "@/components/Wizard";
import {
  ClientStatus,
  ClientStatusResponse,
  EqExtensionRole,
  ExtensionRole,
} from "@/data/client";
import { AuthenticationResult } from "@azure/msal-common";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import * as S from "fp-ts/Set";
import * as T from "fp-ts/Task";
import { Task } from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import { DateTime } from "luxon";
import { StrictMode, useEffect, useMemo } from "react";
import { Route, Switch, useLocation } from "wouter";
import {
  AccountCompanySettingsSelected,
  AccountSettingsAction,
  AccountSettingsSelected,
  Action,
  ApplicationLoaded,
  ApplicationSelected,
  ApplicationsListAction,
  ApplicationsListSelected,
  BillingSettingsSelected,
  DashboardAction,
  DashboardSelected,
  HomebuyerLandingSelected,
  HomePageAction,
  HomepageRequested,
  InactiveBorrrowerAction,
  Initialized,
  InviteAction,
  LoanOfficerSignup,
  LogoutInitiated,
  LoInviteAction,
  NewApplicationSelected,
  RouteToHomeBuyer,
  SaveSurveyRequested,
  SubmitApplicationRequested,
  SummaryAction,
  update,
  UserLoginRedirected,
  UserManagementAction,
  UserManagementSelected,
  WizardAction,
} from "./app/action";
import { init, Model } from "./app/model";
import { ProfileMenu } from "./components/basic/Menu/ProfileMenu";
import { CreateClient } from "./components/SignUp";
import { CreateClientPayload } from "./data/payload";
import {
  initPageTask,
  toAccountSettingsPage,
  toApplicationListPage,
  toApplyNowPage,
  toBranchesPage,
  toDashboardPage,
  toInactiveBorrower,
  toInvitePage,
  toSummaryPage,
  tryCompleteBackOfficeSignin,
  tryCompleteSurvey,
  userInfoAndClientStatus,
} from "./routing/navigation";
import routes, { getRouteMatches, RouteMeta } from "./routing/routes";
import { Finished } from "./utils/asyncOperationStatus";
import { PageTypes } from "./utils/page-types";
import { GetDeviceInfo } from "./utils/viewResolver";
import { isLoanOfficer, isSuperUser } from "./utils/user";

const toHomePage: [Model, Effect<Action>] = [
  {
    page: {
      type: PageTypes.HomePage,
      homepageModel: HomePage.initHomePageModel(),
    },
    clientStatus: O.none,
    user: O.none,
    dialog: O.none,
  },
  noEffect,
];

const initTask = (
  api: Api,
  location: string,
  performUserFlow: (
    authority: string,
    state: string,
    loginHint: string,
  ) => TE.TaskEither<Error, void>,
  handleRedirectTask: Task<AuthenticationResult | null>,
): T.Task<[Model, Effect<Action>]> => {
  return pipe(
    pipe(
      TE.fromTask(handleRedirectTask),
      TE.chain((result) => {
        switch (result?.authority.toUpperCase()) {
          case import.meta.env.VITE_BO_SIGNUP_AUTHORITY.toUpperCase():
            return tryCompleteSurvey(result, api);

          case import.meta.env.VITE_LO_SIGNUP_AUTHORITY.toUpperCase():
            return tryCompleteBackOfficeSignin(result, api);

          case null:
          case undefined:
            return userInfoAndClientStatus(api); //TE.of(O.none);

          default:
            return userInfoAndClientStatus(api);
        }
      }),
      TE.chain(
        O.traverse(TE.ApplicativeSeq)(
          ([user, clientStatus]): TE.TaskEither<
            ApiError,
            [Model, Effect<Action>]
          > => {
            return pipe(
              routes,
              A.findFirst((v) => {
                return v.pattern.test(location);
              }),
              O.match(
                () => {
                  return initPageTask(api)(user, clientStatus);
                },
                (
                  routeMeta,
                ): TE.TaskEither<ApiError, [Model, Effect<Action>]> => {
                  const matches = getRouteMatches(location, routeMeta);

                  let userHasRole = false;
                  if (routeMeta.roles) {
                    userHasRole =
                      routeMeta.roles.find((requestedRole: ExtensionRole) => {
                        return S.elem(EqExtensionRole)(requestedRole)(
                          user.roles,
                        );
                      }) != null;

                    if (!userHasRole && !routeMeta.accessPredicate) {
                      alert("You don't have access to that area!");
                      return RouteToHomeBuyer(api, user);
                    }
                  }
                  const navigationOptions = {
                    matches,
                    api,
                    userHasRole,
                    routeMeta,
                    user,
                    clientStatus,
                  };
                  if (clientStatus.status == ClientStatus.Inactive) {
                    return toInactiveBorrower(navigationOptions);
                  }

                  switch (routeMeta.type) {
                    case "Summary":
                      return toSummaryPage(navigationOptions);

                    case "Invite":
                      return toInvitePage(navigationOptions);
                    case "HomebuyerLanding":
                      return RouteToHomeBuyer(api, user);
                    case "Dashboard":
                      return toDashboardPage(navigationOptions);
                    case "ApplicationsList":
                      return toApplicationListPage(navigationOptions);
                    case "ApplyNow":
                      return toApplyNowPage(navigationOptions);
                    case "AccountSettings":
                      return toAccountSettingsPage(navigationOptions);
                    case "Branches": {
                      return toBranchesPage(navigationOptions);
                    }
                    default:
                      return initPageTask(api)(user, clientStatus);
                  }
                },
              ),
            );
          },
        ),
      ),

      TE.mapBoth(
        (e) => e,
        (v) => v,
      ),
      TE.orElse(
        (error): TE.TaskEither<ApiError, O.Option<[Model, Effect<Action>]>> =>
          pipe(
            api.getClientStatus(),
            TE.chain((clientStatus) =>
              TE.right(
                O.some([
                  {
                    ...init(),
                    page: {
                      type: PageTypes.LoginRedirection,
                      redirectionModel: {
                        clientStatus,
                        backOfficePayload:
                          error.type == "BackOfficeSignupRedirectException"
                            ? O.some(error.payload)
                            : O.none,
                        user: O.none,
                        borrowerPayload:
                          error.type == "BorrowerSignupRedirectException"
                            ? error.user
                            : O.none,
                      },
                    },
                  },
                  noEffect,
                ]),
              ),
            ),
          ),
      ),

      TE.chain(
        (
          errorStatusAsOption,
        ): TE.TaskEither<ApiError, [Model, Effect<Action>]> => {
          if (O.isNone(errorStatusAsOption)) {
            return pipe(
              api.getClientStatus(),
              TE.map((clientStatus) =>
                routeWhileUnsigned(
                  routes,
                  location,
                  performUserFlow,
                  clientStatus,
                ),
              ),
            );
          }
          const [model] = errorStatusAsOption.value;
          switch (model.page.type) {
            case PageTypes.LoginRedirection: {
              const borrowerPayload =
                model.page.redirectionModel.borrowerPayload;
              if (O.isSome(borrowerPayload)) {
                const [user, clientStatus] = borrowerPayload.value;
                return initPageTask(api)(user, clientStatus);
              } else if (
                O.isSome(model.page.redirectionModel.backOfficePayload)
              ) {
                return TE.right([
                  init(),
                  effectsBatch([
                    effectOfAction(HomepageRequested()),
                    effectOfAction(HomePageAction(HomePage.SignupRequested())),
                    effectOfAction(
                      HomePageAction(
                        HomePage.SignupAction(
                          CreateClient({} as CreateClientPayload)(
                            Finished(
                              E.right(
                                pipe(
                                  model.page.redirectionModel.backOfficePayload
                                    .value,
                                ),
                              ),
                            ),
                          ),
                        ),
                      ),
                    ),
                  ]),
                ]);
              } else {
                return TE.right(toHomePage);
              }
            }

            default:
              return TE.right(errorStatusAsOption.value);
          }
        },
      ),

      TE.getOrElse(() =>
        T.of(
          routeWhileUnsigned(routes, location, performUserFlow, {
            status: ClientStatus.Inactive,
          }),
        ),
      ),
    ),
  );
};

function routeWhileUnsigned(
  routes: RouteMeta[],
  location: string,
  performUserFlow: (
    authority: string,
    state: string,
    loginHint: string,
  ) => TE.TaskEither<Error, void>,
  clientStatus: ClientStatusResponse,
): [Model, Effect<Action>] {
  return pipe(
    routes,
    A.findFirst((v) => {
      return v.pattern.test(location);
    }),
    O.match(
      () => {
        return toHomePage;
      },
      (routeMeta): [Model, Effect<Action>] => {
        switch (routeMeta.type) {
          case "ApplyNow":
            if (clientStatus.status == ClientStatus.Deprecated) {
              return [
                {
                  ...init(),
                  page: {
                    type: PageTypes.InactiveBorrower,
                    inactiveBorrowerModel: {
                      user: O.none,
                    },
                  },
                  user: O.none,
                  dialog: O.none,
                  clientStatus: O.some(clientStatus),
                },
                noEffect,
              ];
            }
            return [
              init(),
              effectsBatch([effectOfAction(NewApplicationSelected())]),
            ];

          case "Login":
            return [
              init(),
              effectOfAsync(
                performUserFlow(
                  import.meta.env.VITE_LO_SIGNIN_AUTHORITY,
                  "/dashboard",
                  "",
                ),
                UserLoginRedirected,
              ),
            ];
            break;
          case "LoInvite":
          case "Invite": {
            const url = new URL(window.location.href);
            const authority =
              routeMeta.type == "Invite"
                ? import.meta.env.VITE_BO_SIGNIN_RESET_AUTHORITY
                : import.meta.env.VITE_LO_SIGNIN_RESET_AUTHORITY;

            const getQueryParam = (param: string) =>
              pipe(
                url.searchParams.get(param),
                O.fromNullable,
                O.fold(constant(""), identity),
              );

            return [
              init(),
              effectOfAsync(
                performUserFlow(
                  authority,
                  "/",
                  pipe(
                    [
                      getQueryParam("login_hint"),
                      getQueryParam("phone_number"),
                    ],
                    ([email, phoneNo]) => JSON.stringify({ email, phoneNo }),
                  ),
                ),
                UserLoginRedirected,
              ),
            ];
          }

          default:
            return toHomePage;
        }
      },
    ),
  );
}

type AppProps = {
  model: Model;
  dispatch: (action: Action) => void;
};

function AppContent({ model, dispatch }: AppProps): JSX.Element {
  if (model.page.type == "Initializing") {
    return <div>Loading...</div>;
  }
  console.log(model.page.type);
  return (
    <Switch>
      {model.page.type == PageTypes.ApplyNow && (
        <Route path="/applynow/:loanOfficerId?">
          {(params) => {
            if (model.page.type != "ApplyNow") {
              return <></>;
            }
            return (
              <Wizard.View
                model={model.page.wizardModel}
                dispatch={flow(WizardAction, dispatch)}
                referringLoanOfficerId={O.fromNullable(
                  Number(params.loanOfficerId),
                )}
                onFinish={(payload) =>
                  flow(constant(payload), SaveSurveyRequested, dispatch)()
                }
              ></Wizard.View>
            );
          }}
        </Route>
      )}
      {model.page.type == "Summary" && (
        <Route path="/summary/:applicationId">
          <Summary.View
            model={model.page.summaryModel}
            dispatch={flow(SummaryAction, dispatch)}
            completeApplication={flow(SubmitApplicationRequested, dispatch)}
          />
        </Route>
      )}
      {model.page.type == "HomebuyerLanding" && (
        <Route path="/homebuyer-landing">
          {() => {
            if (model.page.type != "HomebuyerLanding") {
              return <></>;
            }
            const action = pipe(
              model.page.application,
              O.fold(NewApplicationSelected, ApplicationLoaded),
            );

            return (
              <HomebuyerLanding.View
                application={model.page.application}
                completeApplication={flow(constant(action), dispatch)}
              />
            );
          }}
        </Route>
      )}
      {model.page.type == "Invite" && (
        <Route path="/invite">
          <Invite.View
            model={model.page.inviteModel}
            dispatch={flow(InviteAction, dispatch)}
            onApplicationSelected={flow(ApplicationSelected, dispatch)}
          />
        </Route>
      )}
      {model.page.type == "LoInvite" && (
        <Route path="/internalsignup">
          <LoInvite.View
            model={model.page.loInviteModel}
            dispatch={flow(LoInviteAction, dispatch)}
          />
        </Route>
      )}
      {model.page.type == "ApplicationsList" && (
        <Route path="/applications-list">
          <ApplicationsList.View
            onApplicationSelected={flow(ApplicationSelected, dispatch)}
            model={model.page.applicationsListModel}
            dispatch={flow(ApplicationsListAction, dispatch)}
          />
        </Route>
      )}
      {model.page.type == "Dashboard" && (
        <Route path="/dashboard">
          <Dashboard.View
            model={model.page.dashboardModel}
            dispatch={flow(DashboardAction, dispatch)}
          />
        </Route>
      )}
      {model.page.type == "Branches" && (
        <Route path="/branches" nest>
          <UserManagement.View
            model={model.page.branchesModel}
            dispatch={flow(UserManagementAction, dispatch)}
          />
        </Route>
      )}
      {model.page.type == "AccountSettings" && (
        <Route path="account-settings" nest>
          <AccountSettings.View
            model={model.page.accountSettingsModel}
            dispatch={flow(AccountSettingsAction, dispatch)}
          />
        </Route>
      )}
      {model.page.type == PageTypes.HomePage && (
        <Route path="/">
          <HomePage.View
            model={model.page.homepageModel}
            onSignup={(email: string, phoneNo: string) =>
              flow(LoanOfficerSignup(email, phoneNo), dispatch)()
            }
            dispatch={flow(HomePageAction, dispatch)}
          />
        </Route>
      )}
      {model.page.type == PageTypes.InactiveBorrower && (
        <Route path="/inactive">
          <InactiveBorrower.View
            model={model.page.inactiveBorrowerModel}
            dispatch={flow(InactiveBorrrowerAction, dispatch)}
          />
        </Route>
      )}
    </Switch>
  );
}

function App(): JSX.Element {
  const [api, logoutTask, performUserFlow, handleRedirectTask] = useApi();

  const [location, setLocation] = useLocation();

  const initialModelEffects = useMemo(() => {
    return initTask(api, location, performUserFlow, handleRedirectTask);
  }, [api, location, performUserFlow, handleRedirectTask]);

  const [model, dispatch] = useReducerWithEffects(
    update(api, performUserFlow),
    init(),
    effectOfAsync(initialModelEffects, ([m, e]: [Model, Effect<Action>]) => {
      console.log(m.page.type);
      return Initialized(e)(m);
    }),
  );

  const deviceInfo = GetDeviceInfo();

  useEffect(() => {
    switch (model.page.type) {
      case PageTypes.ApplyNow:
        if (!location.startsWith("/applynow")) {
          setLocation("/applynow");
        }
        break;

      case "Summary":
        setLocation(`/summary/${model.page.summaryModel.applicationId}`);
        break;

      case "HomebuyerLanding":
        setLocation("/homebuyer-landing");
        break;

      case "ApplicationsList":
        setLocation("/applications-list");
        break;

      case "Dashboard":
        setLocation("/dashboard");
        break;

      case "Branches":
        if (!location.startsWith("/branches")) {
          setLocation("/branches");
        }
        break;

      case "AccountSettings":
        if (!location.startsWith("/account-settings")) {
          setLocation("/account-settings");
        }
        break;

      case PageTypes.HomePage:
        setLocation("/");
        break;

      case PageTypes.InactiveBorrower:
        setLocation("/inactive");
        break;

      case "LoInvite":
        if (!location.startsWith("/internalsignup")) {
          setLocation("/internalsignup");
        }
        break;

      default:
        // if (location != "/") {
        //   setLocation("/");
        // }
        break;
    }
  }, [model, location, setLocation]);

  const menuItems = pipe(
    model.user,
    O.fold(
      () => [],
      (user) => {
        let items = [
          {
            label: "Settings",
            onClick: O.some(
              flow(constant(user), AccountSettingsSelected, dispatch),
            ),
          },

          {
            label: "Logout",
            onClick: O.some(
              flow(constant(logoutTask), LogoutInitiated, dispatch),
            ),
          },
        ];

        if (isSuperUser(user)) {
          items= pipe(
            items,
            A.insertAt(1, {
              label: "Account",
              onClick: O.some(
                flow(constant(user), AccountCompanySettingsSelected, dispatch),
              ),
            }),
            O.fold(() => items, identity)
          );
        }

        return items;
      },
    ),
  );

  return (
    <StrictMode>
      <Row className="app-container">
        {pipe(
          O.some(deviceInfo),
          O.map((deviceInfo) => {
            switch (deviceInfo) {
              case "Mobile-Portrait":
              case "Tablet-Portrait":
              case "Tablet-Landscape":
              case "Mobile-Landscape":
                return <></>;
              default:
                return sideNavBar({ model, dispatch });
            }
          }),
          O.getOrElse(() => <></>),
        )}

        <Col grow={1} background="whiteSmoke">
          {O.isSome(model.user) ? (
            <ProfileMenu user={model.user.value} menuItems={menuItems} />
          ) : (
            <></>
          )}
          <AppContent model={model} dispatch={dispatch} />
        </Col>
      </Row>

      {O.isSome(model.dialog) && (
        <Modal onClose={O.none} title={O.none}>
          <SubmitApplication.View
            model={model.dialog.value}
            goToPortal={flow(O.some, HomebuyerLandingSelected, dispatch)}
          />
        </Modal>
      )}
    </StrictMode>
  );
}

const sideNavBar = ({ model, dispatch }: AppProps): JSX.Element => {
  if (O.isSome(model.user) && model.user.value.roles.size > 0) {
    return (
      <Col padding="sm" gap="sm" background="white" alignHorizontal="center">
        <img
          src={appLogo}
          alt="AC"
          style={{ height: "2.5rem", marginBottom: "3rem" }}
        />
        {NavBarItems({ model, dispatch })}
      </Col>
    );
  } else {
    return <></>;
  }
};

export function NavBarItems({ model, dispatch }: AppProps): JSX.Element {
  if (O.isSome(model.user) && model.user.value.roles.size > 0) {
    return (
      <>
        <Col alignHorizontal="center">
          <Button
            type="tertiary"
            className="ac-nav-icon add-app-btn"
            onClick={O.some(flow(NewApplicationSelected, dispatch))}
          >
            <Icon type="plus" />
          </Button>
        </Col>
        <Col
          alignHorizontal="center"
          onClick={flow(DateTime.now, DashboardSelected, dispatch)}
        >
          <Button
            type="tertiary"
            className="ac-menu-icon"
            onClick={O.some(() => {})}
          >
            <Icon type="house" />
          </Button>
          <Label className="nav-label cp">Home</Label>
        </Col>
        <Col
          alignHorizontal="center"
          onClick={flow(ApplicationsListSelected, dispatch)}
        >
          <Button
            type="tertiary"
            className="ac-menu-icon"
            onClick={O.some(() => {})}
          >
            <Icon type="layer-group" />
          </Button>
          <Label className="nav-label cp">Clients</Label>
        </Col>
        {/*
            When using the onClick for Col like the above nav-items, gettings error while navigating, 
            TODO: Debug and fix the issue.
          */}
        <Col alignHorizontal="center">
          <Button
            type="tertiary"
            className="ac-nav-icon"
            onClick={O.some(flow(UserManagementSelected, dispatch))}
          >
            <Icon type="layer-group" />
          </Button>
          <Label className="nav-label cp">Users</Label>
        </Col>
        <Col
          alignHorizontal="center"
          onClick={flow(
            constant(model.user.value),
            BillingSettingsSelected,
            dispatch,
          )}
        >
          {isLoanOfficer(model.user.value) && (
            <>
              <Button
                type="tertiary"
                className="ac-nav-icon"
                onClick={O.some(() => {})}
              >
                <Icon type="layer-group" />
              </Button>
              <Label className="nav-label cp">Billing</Label>
            </>
          )}
        </Col>
        <Col
          alignHorizontal="center"
          onClick={flow(
            constant(model.user.value),
            AccountSettingsSelected,
            dispatch,
          )}
        >
          <Button
            type="tertiary"
            className="ac-nav-icon"
            onClick={O.some(() => {})}
          >
            <Icon type="gear" />
          </Button>
          <Label className="nav-label cp">Settings</Label>
        </Col>
        <Col alignHorizontal="center">
          <Button
            type="tertiary"
            className="ac-nav-icon"
            onClick={O.some(() => {})}
          >
            <Icon type="gear" />
          </Button>
          <Label className="nav-label cp">Support</Label>
        </Col>
      </>
    );
  } else {
    return <></>;
  }
}

export default App;
