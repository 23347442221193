import {
  Effect,
  effectOfAction,
  effectsBatch,
} from "@/utils/reducerWithEffect";
import { Action, GetCurrentClient, LoadAnalytics, LoadApplications } from "./action";
import { ApiResult } from "@/utils/request";
import { Deferred, NotStarted } from "@/utils/deferred";
import { DateTime } from "luxon";
import { flow } from "fp-ts/lib/function";
import { Started } from "@/utils/asyncOperationStatus";
import { DashboardAnalytics } from "@/data/dashboardAnalytics";
import { ApplicationListItem } from "@/data/applicationsList";
import { Option, none } from "fp-ts/lib/Option";
import { Eq } from "fp-ts/lib/Eq";
import { Eq as EqString } from "fp-ts/lib/string";
import { Client } from "@/data/client";
import * as O from 'fp-ts/Option';

export type Model = {
  selectedBranch: Option<string>;
  selectedInterval: ReportingInterval;
  analytics: Deferred<ApiResult<DashboardAnalytics>>;
  applications: Deferred<ApiResult<ApplicationListItem[]>>;
  client: Deferred<ApiResult<Client>>;

  isFirstLook: boolean,
  applicationsInterval: ReportingInterval
  invitedMembers: O.Option<InvitedMember[]>
};

export const analyticsIntervalValues = {
  week: "Last 7 days",
  month: "Last 30 days",
  year: "Last year",
};

export type InvitedMember = {
  email: string, 
  phoneNumber: string  
}

export type ReportingInterval = keyof typeof analyticsIntervalValues;
export const EqAnalyticsInterval: Eq<ReportingInterval> = EqString;
export const analyticsIntervals: ReportingInterval[] = [
  "week",
  "month",
  "year",
] as const;

export const subtractIntervalFrom =
  (dateTime: DateTime) =>
  (interval: ReportingInterval): DateTime => {
    switch (interval) {
      case "week":
        return dateTime.minus({ days: 7 });
      case "month":
        return dateTime.minus({ days: 30 });
      case "year":
        return dateTime.minus({ years: 1 });
    }
  };

export const init = (date: DateTime, isFirstLook: boolean): [Model, Effect<Action>] => {
  return [
    {
      selectedBranch: none,
      selectedInterval: "month",
      analytics: NotStarted(),
      applications: NotStarted(),
      client: NotStarted(),
      isFirstLook,
      applicationsInterval: "week",
      invitedMembers:none
    },
    effectsBatch([
      effectOfAction(
        flow(
          Started,
          LoadAnalytics(subtractIntervalFrom(date)("month"), date),
        )(),
      ),
      effectOfAction(flow(Started, LoadApplications(subtractIntervalFrom(date)("week"), date))()),
      effectOfAction(flow(Started, GetCurrentClient)()),
    ]),
  ];
};
