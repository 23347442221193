// import { useMemo } from "react";
import { Button, Modal, TogglePanel } from "@/components/basic";
import { Started } from "@/utils/asyncOperationStatus";
import { ChildProps } from "@/utils/reducerWithEffect";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import {
  AcceptExportWarning,
  Action,
  CancelManualDocumentSelection,
  ClearMissingEmployer,
  DocumentsAction,
  EmployerResolverAction,
  ManualClassificationFileSelected,
  MoveToEditMode,
  PollEmployers,
  ResetExportStatus,
  SetMissingEmployer,
  StartManualDocumentSelection,
  SubmitManualClasification,
} from "./action";
import { ApplicationViewMode, Model } from "./model";
import * as Documents from "@/components/Documents";

import * as ManualDocClassifier from "@/components/ManualDocClassifier";
import * as Review from "@/components/Review";
import { ApplicationId, EmployersPayload } from "@/data/payload";
import { showApiError } from "@/utils/request";
import { useEffect, useMemo, useRef } from "react";
import * as EmployerResolver from "../EmployerResolver";
import { EditApplication } from "./edit";
import { DocumentTypeId } from "@/data/applicationDocument";

export type Props = ChildProps<Model, Action> & {
  completeApplication: (applicationId: ApplicationId) => void;
};

export type SubPageProps = {
  onScrollToDocuments: O.Option<() => void >,
  children: JSX.Element
}

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const setMissingEmployerAction = flow(SetMissingEmployer, dispatch);
  const clearMissingEmployerAction = flow(ClearMissingEmployer, dispatch);

  const pollEmployeesAction = useMemo(
    () => flow(Started, PollEmployers(model.applicationId), dispatch),
    [model.applicationId, dispatch],
  );

  const documentSectionContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    pollEmployeesAction();
    const intervalId = setInterval(() => {
      pollEmployeesAction();
    }, 1000 * 10);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (model.manualClassification.isOpen) {
      return;
    }
    const missingEmployer = pipe(
      model.employers,
      O.flatMap((employers: EmployersPayload[]) =>
        pipe(
          employers,
          A.filter((employer) => O.isNone(employer.employerInfo)),
          A.head,
        ),
      ),
      O.map((employer) => {
        if (O.isNone(model.employerResolverModel)) {
          setMissingEmployerAction(employer);
        }
        return employer;
      }),
    );

    if (O.isNone(missingEmployer) && O.isSome(model.employerResolverModel)) {
      clearMissingEmployerAction();
    }
  }, [
    model.employers,
    setMissingEmployerAction,
    clearMissingEmployerAction,
    model.employerResolverModel,
    model.manualClassification.isOpen,
  ]);

  const exportingModal = pipe(
    model.exportStatus,
    O.fromPredicate((exportStatus) =>
      ["InProgress", "Resolved"].includes(exportStatus.status),
    ),
    O.map((exportStatus) => {
      switch (exportStatus.status) {
        case "InProgress":
          return (
            <Modal onClose={O.none} title={O.some("Export Status")}>
              Please wait while we are exporting your application.
            </Modal>
          );
        case "Resolved": {
          const { value } = exportStatus;
          if (E.isLeft(value)) {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Status")}
              >
                There was an error exporting the documents.
                {showApiError(value.left)}
              </Modal>
            );
          } else {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Status")}
              >
                Successfuly exported the application!
              </Modal>
            );
          }
        }
        default:
          return <></>;
      }
    }),
    O.getOrElse(() => <></>),
  );

  const documentsSection = (
    <>
      <div ref={documentSectionContainer} />
      <TogglePanel id="documents-section" title="Documents">
        <Documents.View
          model={model.documents}
          dispatch={flow(DocumentsAction, dispatch)}
          onManualClassification={(documentTypeId: DocumentTypeId) =>
            flow(StartManualDocumentSelection(documentTypeId), dispatch)
          }
        />
      </TogglePanel>
      <Review.Verify payload={model.application} onEdit={() => { }}>
        <span></span>    
      </Review.Verify>
    </>
  );
  return (
    <>
      {pipe(
        model.employerResolverModel,
        O.map((employerResolverModel) => {
          return (
            <Modal onClose={O.none} title={O.none}>
              <EmployerResolver.View
                model={employerResolverModel}
                dispatch={flow(EmployerResolverAction, dispatch)}
                onEmployerResolved={flow(ClearMissingEmployer, dispatch)}
              />
            </Modal>
          );
        }),
        O.getOrElse(() => <></>),
      )}
      {
        //show warning on 'incomplete' status exports
        pipe(
          model.exportWarningShown,
          O.chain((v) =>
            pipe(
              v,
              O.fromPredicate((v) => !v),
            ),
          ),
          O.map(() => (
            <Modal
              onClose={O.none}
              title={O.some(
                "This application is incomplete. You may continue with export, but much of the data will be missing from the exported data",
              )}
            >
              <Button
                type="primary"
                onClick={O.some(flow(AcceptExportWarning, dispatch))}
              >
                Ok
              </Button>
            </Modal>
          )),
          O.getOrElse(() => <></>),
        )
      }
      {pipe(
        model.manualClassification?.isOpen,
        O.fromPredicate(identity),
        O.map(() => (
          <ManualDocClassifier.View
            model={model.manualClassification}
            onFileSelect={(file: O.Option<File>) =>
              flow(ManualClassificationFileSelected(file), dispatch)
            }
            onDocumentUpload={(file: File) =>
              flow(Started, SubmitManualClasification(file), dispatch)
            }
            onClose={O.some(flow(CancelManualDocumentSelection, dispatch))}
          />
        )),
        O.getOrElse(() => <></>),
      )}
      {exportingModal}
      {pipe(
        model.mode,
        O.fromPredicate((v) => v == ApplicationViewMode.Edit),
        O.fold(
          () => (
            <Review.View
              payload={model.application}
              onEdit={flow(MoveToEditMode, dispatch)}
            >
              {documentsSection}
            </Review.View>
          ),
          () => (
            <EditApplication
              onScrollToDocuments={O.some(() =>
                documentSectionContainer.current?.scrollIntoView({
                  behavior: "smooth",
                }),
              )}
              {...props}
            >
              {documentsSection}
            </EditApplication>
          ),
        ),
      )}
    </>
  );
}
