import { EqApplicationStatus } from "@/data/applicationsList";
import { EqUserId, UserId } from "@/data/client";
import {
  ApplicationId,
  ApplicationStatusType,
  ApplicationType,
  EqApplicationType,
} from "@/data/payload";
import { Api } from "@/utils/api";
import {
  AsyncOperationStatus,
  Finished,
  Started,
} from "@/utils/asyncOperationStatus";
import { InProgress, NotStarted, Resolved } from "@/utils/deferred";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import { orderBy } from "lodash-es";
import { SortDirection } from "../basic";
import { Model, SubmissionDateFilterOption } from "./model";

const noneIfEmpty = <A>(set: Set<A>): Option<Set<A>> =>
  set.size === 0 ? O.none : O.some(set);

export type Action =
  | {
      type: "SearchChanged";
      search: string;
    }
  | {
      type: "SortChanged";
      columnKey: string;
      direction: SortDirection;
    }
  | {
      type: "StatusFilterAllSelected";
    }
  | {
      type: "StatusFilterToggled";
      status: ApplicationStatusType;
    }
  | {
      type: "CreditCheckAuthorizedFilterAllSelected";
    }
  | {
      type: "CreditCheckAuthorizedFilterToggled";
      isAuthorized: boolean;
    }
  | {
      type: "MortgageTypeFilterAllSelected";
    }
  | {
      type: "MortgageTypeFilterToggled";
      mortgageType: ApplicationType;
    }
  | {
      type: "SubmissionDateFilterAllSelected";
    }
  | {
      type: "SubmissionDateFilterSelected";
      submissionDateFilter: SubmissionDateFilterOption;
    }
  | {
      type: "AssigneeFilterAllSelected";
    }
  | {
      type: "AssigneeFilterToggled";
      userId: UserId;
    }
  | {
      type: "ExportDocuments";
      applicationId: ApplicationId;
      operation: AsyncOperationStatus<ApiResult<string>>;
    }
  | {
      type: "ResetExportStatus";
    }
  | {
      type: "ShowExportWarning";
      applicationId: ApplicationId;
    }
  | {
      type: "AcceptExportWarning";
    };

export const SearchChanged = (search: string): Action => ({
  type: "SearchChanged",
  search,
});

export const SortChanged = (
  columnKey: string,
  direction: SortDirection,
): Action => ({
  type: "SortChanged",
  columnKey,
  direction,
});

export const StatusFilterAllSelected = (): Action => ({
  type: "StatusFilterAllSelected",
});

export const StatusFilterToggled = (status: ApplicationStatusType): Action => ({
  type: "StatusFilterToggled",
  status,
});

export const CreditCheckAuthorizedFilterAllSelected = (): Action => ({
  type: "CreditCheckAuthorizedFilterAllSelected",
});

export const CreditCheckAuthorizedFilterToggled = (
  isAuthorized: boolean,
): Action => ({
  type: "CreditCheckAuthorizedFilterToggled",
  isAuthorized,
});

export const MortgageTypeFilterAllSelected = (): Action => ({
  type: "MortgageTypeFilterAllSelected",
});

export const MortgageTypeFilterToggled = (
  mortgageType: ApplicationType,
): Action => ({
  type: "MortgageTypeFilterToggled",
  mortgageType,
});

export const SubmissionDateFilterAllSelected = (): Action => ({
  type: "SubmissionDateFilterAllSelected",
});

export const SubmissionDateFilterSelected = (
  submissionDateFilter: SubmissionDateFilterOption,
): Action => ({
  type: "SubmissionDateFilterSelected",
  submissionDateFilter,
});

export const AssigneeFilterAllSelected = (): Action => ({
  type: "AssigneeFilterAllSelected",
});

export const AssigneeFilterToggled = (userId: UserId): Action => ({
  type: "AssigneeFilterToggled",
  userId,
});

export const ExportDocuments =
  (applicationId: ApplicationId) =>
  (operation: AsyncOperationStatus<ApiResult<string>>): Action => ({
    type: "ExportDocuments",
    applicationId,
    operation,
  });

export const ResetExportStatus = (): Action => ({
  type: "ResetExportStatus",
});

export const ShowExportWarning =
  (applicationId: ApplicationId) => (): Action => ({
    type: "ShowExportWarning",
    applicationId,
  });
export const AcceptExportWarning = (): Action => ({
  type: "AcceptExportWarning",
});

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "SearchChanged": {
        return [{ ...model, search: action.search }, noEffect];
      }

      case "StatusFilterAllSelected":
        return [{ ...model, statusFilter: O.none }, noEffect];

      case "StatusFilterToggled":
        return [
          {
            ...model,
            statusFilter: pipe(
              model.statusFilter,
              O.fold(
                () => O.some(S.singleton(action.status)),
                flow(S.toggle(EqApplicationStatus)(action.status), noneIfEmpty),
              ),
            ),
          },
          noEffect,
        ];

      case "CreditCheckAuthorizedFilterAllSelected":
        return [{ ...model, creditCheckAuthorizedFilter: O.none }, noEffect];

      case "CreditCheckAuthorizedFilterToggled":
        return [
          {
            ...model,
            creditCheckAuthorizedFilter: O.some(action.isAuthorized),
          },
          noEffect,
        ];

      case "MortgageTypeFilterAllSelected":
        return [{ ...model, mortgageTypeFilter: O.none }, noEffect];

      case "MortgageTypeFilterToggled":
        return [
          {
            ...model,
            mortgageTypeFilter: pipe(
              model.mortgageTypeFilter,
              O.fold(
                () => O.some(S.singleton(action.mortgageType)),
                flow(
                  S.toggle(EqApplicationType)(action.mortgageType),
                  noneIfEmpty,
                ),
              ),
            ),
          },
          noEffect,
        ];

      case "SubmissionDateFilterAllSelected":
        return [{ ...model, submissionDateFilter: O.none }, noEffect];

      case "SubmissionDateFilterSelected":
        return [
          {
            ...model,
            submissionDateFilter: O.some(action.submissionDateFilter),
          },
          noEffect,
        ];

      case "AssigneeFilterAllSelected":
        return [{ ...model, assigneeFilter: O.none }, noEffect];

      case "AssigneeFilterToggled":
        return [
          {
            ...model,
            assigneeFilter: pipe(
              model.assigneeFilter,
              O.fold(
                () => O.some(S.singleton(action.userId)),
                flow(S.toggle(EqUserId)(action.userId), noneIfEmpty),
              ),
            ),
          },
          noEffect,
        ];

      case "ExportDocuments": {
        switch (action.operation.status) {
          case "Started":
            {
              const apiCallerAction = effectOfAsync(
                api.exportDocuments(action.applicationId),
                flow(Finished, ExportDocuments(action.applicationId)),
              );

              return [
                {
                  ...model,
                  exportWarningShown: O.none,
                  exportStatus: InProgress(),
                },
                pipe(
                  model.exportWarningShown,
                  O.chain(([v]) => O.fromPredicate(identity<boolean>)(v)),
                  O.map((v) => (v ? apiCallerAction : noEffect)),
                  O.getOrElse(() => apiCallerAction),
                ),
              ];
            }
            break;

          case "Finished":
            return [
              {
                ...model,
                exportStatus: Resolved(action.operation.result),
              },
              noEffect,
            ];
        }
        break;
      }
      case "ResetExportStatus": {
        return [
          {
            ...model,
            exportStatus: NotStarted(),
          },
          noEffect,
        ];
      }

      case "ShowExportWarning":
        {
          return [
            {
              ...model,
              exportWarningShown: O.some([false, NaN as ApplicationId]),
            },
            noEffect,
          ];
        }
        break;
      case "AcceptExportWarning":
        {
          const [_, applicationId] = pipe(
            model.exportWarningShown,
            O.fold(
              () => [false, NaN] as [boolean, ApplicationId],
              (v) => v,
            ),
          );
          return [
            { ...model, exportWarningShown: O.some([true, applicationId]) },
            effectOfAction(flow(Started, ExportDocuments(applicationId))()),
          ];
        }
        break;

      case "SortChanged":
        {
          return [
            {
              ...model,
              applications: orderBy(
                model.applications,
                action.columnKey,
                action.direction,
              ),
            },
            noEffect,
          ];
        }
        break;
    }
  };
