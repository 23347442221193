import { Button, Checkbox, Col, Label, Row } from "@/components/basic";
import { deferredToOption, isResolved } from "@/utils/deferred";
import { ChildProps } from "@/utils/reducerWithEffect";
import * as E from "fp-ts/lib/Either";
import { constFalse, constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { useEffect, useMemo } from "react";
import { Route, Switch, useLocation } from "wouter";
import {
  Action,
  BillingSelected,
  CientSettingsPayloadPrepared,
  CompanySettingsSelected,
  EditCompanyAction,
  EditUserAction,
  GeneralSelected,
  NotificationsSelected,
  SettingsPayloadPrepared,
  SmsNotificationsToggled,
  UserPayloadPrepared,
} from "./action";
import * as EditUser from "./EditUser";
import * as EditCompany from "./EditCompany";
import { Model } from "./model";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  return (
    <Col padding="xs" gap="xs">
      <Label>Account</Label>
      <Row gap="xs">
        <Button
          type={model.page.type === "General" ? "primary" : "secondary"}
          onClick={O.some(flow(GeneralSelected, dispatch))}
        >
          General
        </Button>

        {model.canSeeAccountSettings && (
          <Button
            type={model.page.type === "Company" ? "primary" : "secondary"}
            onClick={O.some(flow(CompanySettingsSelected, dispatch))}
          >
            Account
          </Button>
        )}

        {model.canSeeBilling && (
          <Button
            type={model.page.type === "Billing" ? "primary" : "secondary"}
            onClick={O.some(flow(BillingSelected, dispatch))}
          >
            Billing
          </Button>
        )}

        <Button
          type={model.page.type === "Notifications" ? "primary" : "secondary"}
          onClick={O.some(flow(NotificationsSelected, dispatch))}
        >
          Notifications
        </Button>
      </Row>
      <PageView {...props} />
    </Col>
  );
}

export function PageView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const [_, setLocation] = useLocation();

  const { page } = model;

  useEffect(() => {
    switch (page.type) {
      case "General":
        setLocation("/general");
        break;

      case "Billing":
        setLocation("/billing");
        break;

      case "Company":
        setLocation("/company");
        break;

      case "Notifications":
        setLocation("/notifications");
        break;
    }
  }, [page, setLocation]);
  const settings = useMemo(
    () => pipe(model.accountSettings, deferredToOption, O.chain(O.fromEither)),
    [model.accountSettings],
  );

  const paymentProviderUrl = pipe(
    model.paymentProviderMeta,
    O.fromPredicate((v) => v.status == "Resolved"),
    O.chain((v) => O.fromEither(v.value)),
    O.map((v) => v.paymentProviderCustomerPortalUrl),
  );
  return (
    <Switch>
      {page.type == "General" && (
        <Route path="/general">
          {() => {
            switch (model.editUserModel.status) {
              case "NotStarted":
                return <></>;
              case "InProgress":
              case "Updating":
                return <>Loading...</>;
              case "Resolved":
                return pipe(
                  model.editUserModel.value,
                  E.fold(
                    () => <Label>Failed to load account information</Label>,
                    (editUserModel) => (
                      <EditUser.View
                        model={editUserModel}
                        dispatch={flow(EditUserAction, dispatch)}
                        onSave={flow(UserPayloadPrepared, dispatch)}
                      />
                    ),
                  ),
                );
            }
          }}
        </Route>
      )}
      {page.type == "Company" && model.canSeeAccountSettings && (
        <Route path="/company">
          {() => {
            switch (model.editCompanyModel.status) {
              case "NotStarted":
                return <></>;
              case "InProgress":
              case "Updating":
                return <>Loading...</>;
              case "Resolved":
                return pipe(
                  model.editCompanyModel.value,
                  E.fold(
                    () => <Label>Failed to load account information</Label>,
                    (editCompanyModel) => (
                      <EditCompany.View
                        model={editCompanyModel}
                        dispatch={flow(EditCompanyAction, dispatch)}
                        onSave={flow(CientSettingsPayloadPrepared, dispatch)}
                      />
                    ),
                  ),
                );
            }
          }}
        </Route>
      )}
      {page.type == "Billing" && model.canSeeBilling && (
        <Route path="/billing">
          <Col>
            <p className="text-lg">Billing Information </p>
            <p>
              Billing information is managed via Stripe. Use the button below to
              go to the Stripe Customer Portal where you can manage your billing
              settings and view your billing history.
            </p>
            {model.paymentProviderMeta.status == "Resolved" &&
              pipe(
                paymentProviderUrl,
                O.fold(
                  () => (
                    <p>
                      There is a problem getting your Stripe account. Please
                      contact support
                    </p>
                  ),
                  (v) => (
                    <a
                      className="button button-primary text-underline-none"
                      href={v}
                    >
                      Go to Stripe Customer Portal
                    </a>
                  ),
                ),
              )}
          </Col>
        </Route>
      )}
      {page.type == "Notifications" && (
        <Route path="/notifications">
          {() => {
            return (
              <Col padding="xs" gap="xs">
                <Label>Notifications</Label>
                <Checkbox
                  checked={pipe(
                    settings,
                    O.fold(
                      constFalse,
                      ({ smsNotificationsDisabled }) =>
                        !smsNotificationsDisabled,
                    ),
                  )}
                  disabled={!isResolved(model.accountSettings)}
                  label="Allow SMS notifications"
                  onChange={flow(SmsNotificationsToggled, dispatch)}
                />
                <Row>
                  <Button
                    type="primary"
                    onClick={pipe(
                      settings,
                      O.filter((_) => isResolved(model.accountSettings)),
                      O.map((s) =>
                        flow(constant(s), SettingsPayloadPrepared, dispatch),
                      ),
                    )}
                  >
                    Save
                  </Button>
                </Row>
              </Col>
            );
          }}
        </Route>
      )}
    </Switch>
  );
}
