import { Button, Col, Label, Modal, Row } from "@/components/basic";
import * as O from "fp-ts/Option";
import { constant, flow, pipe } from "fp-ts/lib/function";
import {
  Action,
  DeleteTeamInitiated,
  DialogClosed,
  EditTeamAction,
  EditTeamInitiated,
  NewTeamInitiated,
} from "./action";
import { ChildProps } from "@/utils/reducerWithEffect";
import { Model, DialogModel } from "./model";
import { Branch, BranchId, EqBranchId, TeamId } from "@/data/client";
import * as EditTeam from "@/components/UserManagement/EditTeam";
import { TeamPayload } from "@/data/payload";
import { Option } from "fp-ts/Option";
import * as M from "fp-ts/Map";
import { useMemo } from "react";
import { TeamsTable } from "../TeamsTable";

export type Props = ChildProps<Model, Action> & {
  branches: Map<BranchId, Branch>;
  onSelectTeam: (teamId: TeamId) => void;
  onSaveTeam: (teamId: Option<TeamId>, payload: TeamPayload) => void;
  onDeleteTeam: (teamId: TeamId) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;
  const selectedBranch = useMemo(
    () => M.lookup(EqBranchId)(model.branchId, props.branches),
    [props.branches, model.branchId],
  );

  return pipe(
    selectedBranch,
    O.fold(
      () => <Label>Branch not found</Label>,
      (branch) => (
        <Col padding="sm" grow={1}>
          <Row
            padding="sm"
            alignVertical="center"
            alignHorizontal="space-between"
          >
            <Label>
              <b>{branch.name}</b>
            </Label>
            <Button
              type="secondary"
              onClick={O.some(flow(NewTeamInitiated, dispatch))}
            >
              Create a new team
            </Button>
          </Row>
          <TeamsTable
            teams={branch.teams}
            onSelectTeam={props.onSelectTeam}
            onEditTeam={O.some(flow(EditTeamInitiated, dispatch))}
            onDeleteTeam={O.some(flow(DeleteTeamInitiated, dispatch))}
          />
          {O.isSome(model.dialog) && (
            <Modal onClose={O.none} title={O.none}>
              <DialogView
                dialog={model.dialog.value}
                dispatch={dispatch}
                onSaveTeam={(teamId) => (payload) =>
                  props.onSaveTeam(teamId, payload)
                }
                onDeleteTeam={props.onDeleteTeam}
              />
            </Modal>
          )}
        </Col>
      ),
    ),
  );
}

type DialogViewProps = {
  dialog: DialogModel;
  dispatch: (action: Action) => void;
  onSaveTeam: (teamId: Option<TeamId>) => (payload: TeamPayload) => void;
  onDeleteTeam: (teamId: TeamId) => void;
};

function DialogView(props: DialogViewProps): JSX.Element {
  switch (props.dialog.type) {
    case "NewTeamDialog":
      return (
        <EditTeam.View
          model={props.dialog.dialogModel}
          dispatch={flow(EditTeamAction, props.dispatch)}
          onSave={props.onSaveTeam(O.none)}
          onCancel={flow(DialogClosed, props.dispatch)}
        />
      );

    case "EditTeamDialog":
      return (
        <EditTeam.View
          model={props.dialog.dialogModel}
          dispatch={flow(EditTeamAction, props.dispatch)}
          onSave={props.onSaveTeam(O.some(props.dialog.teamId))}
          onCancel={flow(DialogClosed, props.dispatch)}
        />
      );

    case "DeleteTeamDialog":
      return (
        <Col>
          <Label>Are you sure you want to delete this team?</Label>
          <Row>
            <Button
              type="secondary"
              onClick={O.some(flow(DialogClosed, props.dispatch))}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={O.some(
                flow(constant(props.dialog.teamId), props.onDeleteTeam),
              )}
            >
              Delete
            </Button>
          </Row>
        </Col>
      );
  }
}
