import {
  IncomeSourcePayload,
  IncomeSourceType,
  SelfEmploymentType,
  WorkScheduleType,
} from "@/data/payload";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";

type WorkScheduleModel = {
  type: WorkScheduleType;
  atLeast2Years: boolean;
  receivesBonusOvertimeCommission: boolean;
};

type W2WagesIncomeSource = Extract<IncomeSourcePayload, { type: "W2Wages" }>;
type SelfEmployedIncomeSource = Extract<
  IncomeSourcePayload,
  { type: "SelfEmployed" }
>;

const initWorkScheduleModel = (
  incomeSource: Option<W2WagesIncomeSource>,
): WorkScheduleModel =>
  pipe(
    incomeSource,
    O.fold(
      () => ({
        type: "FullTime",
        atLeast2Years: false,
        receivesBonusOvertimeCommission: false,
      }),
      ({ workSchedule, receivesBonusOvertimeCommission }) => ({
        type: workSchedule.type,
        atLeast2Years:
          (workSchedule.type === "PartTime" || workSchedule.type === "Both") &&
          workSchedule.atLeast2Years,
        receivesBonusOvertimeCommission,
      }),
    ),
  );

type SelfEmploymentModel = {
  type: SelfEmploymentType;
  w2Wages: boolean;
};

const initSelfEmploymentModel = (
  incomeSource: Option<SelfEmployedIncomeSource>,
): SelfEmploymentModel =>
  pipe(
    incomeSource,
    O.fold(
      () => ({
        type: "ScheduleC_or_1099",
        w2Wages: false,
      }),
      ({ selfEmployment }) => ({
        type: selfEmployment.type,
        w2Wages:
          selfEmployment.type === "Corporation" && selfEmployment.w2Wages,
      }),
    ),
  );

export type Model = {
  w2Wages: boolean;
  selfEmployed: boolean;
  activeDutyMilitary: boolean;
  retirementIncome: boolean;
  socialSecurityDisability: boolean;
  other: boolean;

  workSchedule: WorkScheduleModel;
  selfEmployment: SelfEmploymentModel;
  otherDescription: string;
};

const matchIncomeSource =
  <T extends IncomeSourceType>(incomeSourceType: T) =>
  (
    incomeSource: IncomeSourcePayload,
  ): incomeSource is Extract<IncomeSourcePayload, { type: T }> =>
    incomeSource.type === incomeSourceType;

export const init = (payload: Option<IncomeSourcePayload[]>): Model => {
  // w2wages
  const workSchedule = pipe(
    payload,
    O.chain(A.findFirstMap(O.fromPredicate(matchIncomeSource("W2Wages")))),
  );

  const selfEmployment = pipe(
    payload,
    O.chain(A.findFirstMap(O.fromPredicate(matchIncomeSource("SelfEmployed")))),
  );

  const otherIncome = pipe(
    payload,
    O.chain(A.findFirstMap(O.fromPredicate(matchIncomeSource("Other")))),
  );

  return {
    w2Wages: O.isSome(workSchedule),
    selfEmployed: O.isSome(selfEmployment),
    activeDutyMilitary: pipe(
      payload,
      O.chain(A.findFirst(matchIncomeSource("Active_Duty_Military"))),
      O.isSome,
    ),
    retirementIncome: pipe(
      payload,
      O.chain(A.findFirst(matchIncomeSource("Retirement_Income"))),
      O.isSome,
    ),
    socialSecurityDisability: pipe(
      payload,
      O.chain(A.findFirst(matchIncomeSource("Social_Security_Disability"))),
      O.isSome,
    ),
    other: O.isSome(otherIncome),
    workSchedule: initWorkScheduleModel(workSchedule),
    selfEmployment: initSelfEmploymentModel(selfEmployment),
    otherDescription: pipe(
      otherIncome,
      O.fold(
        () => "",
        ({ description }) => description,
      ),
    ),
  };
};

export const result = (model: Model): IncomeSourcePayload[] => {
  const w2Wages: Option<IncomeSourcePayload> = model.w2Wages
    ? O.some({
        type: "W2Wages",
        workSchedule:
          model.workSchedule.type === "PartTime" ||
          model.workSchedule.type === "Both"
            ? {
                type: model.workSchedule.type,
                atLeast2Years: model.workSchedule.atLeast2Years,
              }
            : { type: model.workSchedule.type },
        receivesBonusOvertimeCommission:
          model.workSchedule.receivesBonusOvertimeCommission,
      })
    : O.none;

  const selfEmployed: Option<IncomeSourcePayload> = model.selfEmployed
    ? O.some({
        type: "SelfEmployed",
        selfEmployment:
          model.selfEmployment.type === "Corporation"
            ? {
                type: model.selfEmployment.type,
                w2Wages: model.selfEmployment.w2Wages,
              }
            : { type: model.selfEmployment.type },
      })
    : O.none;

  const activeMilitaryDuty: Option<IncomeSourcePayload> =
    model.activeDutyMilitary
      ? O.some({ type: "Active_Duty_Military" })
      : O.none;

  const retirementIncome: Option<IncomeSourcePayload> = model.retirementIncome
    ? O.some({ type: "Retirement_Income" })
    : O.none;

  const socialSecurityDisability: Option<IncomeSourcePayload> =
    model.socialSecurityDisability
      ? O.some({ type: "Social_Security_Disability" })
      : O.none;

  const other: Option<IncomeSourcePayload> = model.other
    ? O.some({
        type: "Other",
        description: model.otherDescription,
      })
    : O.none;

  return A.compact([
    w2Wages,
    selfEmployed,
    activeMilitaryDuty,
    retirementIncome,
    socialSecurityDisability,
    other,
  ]);
};
