import { SurveyConsentFormItem } from "@/components/Invite";
import { User } from "@/data/client";
import { ConsentItem } from "@/data/consents";
import { ApplicantType } from "@/data/surveyFlowPayload";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { NonEmptyString } from "io-ts-types";
import {
  ConfirmSocialSecurityNumber,
  confirmSocialSecurityNumberCodec,
  OptionalSocialSecurityNumber,
  SocialSecurityNumber,
  socialSecurityNumberCodec,
  ValidDateTime,
  validDateTimeCodec
} from "./codecs";
import { FormField, initCheckboxFormField, initFormField } from "./formField";
import { NullFromEmptyStringType } from "./codecs/nullFromEmptyString";

export type PersonalDetailsForm = {
  name: NonEmptyString;
  socialSecurityNumber: FormField<
    | ConfirmSocialSecurityNumber
    | SocialSecurityNumber
    | OptionalSocialSecurityNumber
  >;
  dateOfBirth: FormField<ValidDateTime | NullFromEmptyStringType>  ;
};

export const personalDetailsForm = (
  user: O.Option<User>,
  { applicantType }: { applicantType: ApplicantType },
): PersonalDetailsForm => {
  return {
    name: pipe(
      user,
      O.chain((v) => (O.isNone(v.firstName) ? O.none : v.firstName)),
      O.getOrElse(() => "New User"),
    ) as NonEmptyString,
    socialSecurityNumber: pipe("", (v) =>
      applicantType == "PrimaryApplicant"
        ? initFormField(confirmSocialSecurityNumberCodec.decode)(v)
        : initFormField(socialSecurityNumberCodec.decode)(v),
    ),
    dateOfBirth: pipe("", v => initFormField(validDateTimeCodec.decode)(v) )
  
  };
};

export const consentFormFields = (
  consentMeta: ConsentItem[],
): SurveyConsentFormItem[] => {
  return pipe(
    O.some(consentMeta),
    O.map(
      A.map((thisConsentMeta) => ({
        ...initCheckboxFormField(
          false,
          thisConsentMeta.title,
          thisConsentMeta.content,          
        ),
        consentVersionId: thisConsentMeta.consentVersionId,        
        requiredForBorrower: thisConsentMeta.requiredForBorrower
      })),
    ),
    O.getOrElse((): SurveyConsentFormItem[] => []),
  );
};
