import { PaymentSetupInfo } from "@/components/SignUp";
import { ClientStatusResponse, User } from "@/data/client";
import { ClientSettingsPayload } from "@/data/payload";
import * as E from "fp-ts/Either";
import { Either } from "fp-ts/Either";
import { flow, identity, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as RA from "fp-ts/ReadonlyArray";
import * as S from "fp-ts/string";
import * as T from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import { TaskEither } from "fp-ts/TaskEither";
import * as t from "io-ts";
import { failure } from "io-ts/PathReporter";

export const makeBearerString = (token: string) =>
  `Bearer ${token.toString()}` as const;
export const makeBearerHeader = (token: string) =>
  ({ Authorization: makeBearerString(token) }) as const;

export interface ResponseErrorPayload {
  code: number;
  message: string;
}

export type ApiError =
  | {
      type: "AuthenticationError";
      error: unknown;
    }
  | {
      // when user returns to the application from redirect auth flow
      type: "BackOfficeSignupRedirectException";
      payload: PaymentSetupInfo;
    }
  | {
      // when user returns to the application from redirect auth flow
      type: "BorrowerSignupRedirectException";
      user: O.Option<[User, ClientStatusResponse]>
    }
  | {
      // when JS throws an exception
      type: "ExceptionError";
      message: string;
    }
  | {
      // when the server returns an error response
      type: "ResponseError";
      responseError: ResponseErrorPayload;
    }
  | {
      // when the response cannot be parsed as JSON
      type: "JsonParseError";
    }
  | {
      // when the response cannot be decoded
      type: "DecodeError";
      decodeError: t.Errors;
    };

export const AuthenticationError = (error: unknown): ApiError => ({
  type: "AuthenticationError",
  error,
});

export const BackOfficeSignupRedirectException = (
  payload: PaymentSetupInfo,
): ApiError => ({
  type: "BackOfficeSignupRedirectException",
  payload,
});

export const BorrowerSignupRedirectException = (user: O.Option<[User, ClientStatusResponse]>): ApiError => ({
  type: "BorrowerSignupRedirectException",
  user
});

export const ExceptionError = (message: string): ApiError => ({
  type: "ExceptionError",
  message,
});
export const ResponseError = (
  responseError: ResponseErrorPayload,
): ApiError => ({
  type: "ResponseError",
  responseError,
});
export const JsonParseError = (): ApiError => ({
  type: "JsonParseError",
});
export const DecodeError = (decodeError: t.Errors): ApiError => ({
  type: "DecodeError",
  decodeError,
});

export type ApiResult<T> = Either<ApiError, T>;

export function showApiError(err: ApiError): string {
  switch (err.type) {
    case "AuthenticationError":
      return `AuthenticationError: "${err.error}"`;
    case "BackOfficeSignupRedirectException":
      return `BackOfficeSignupRedirectException`;
    case "BorrowerSignupRedirectException":
      return `BorrowerSignupRedirectException`;
    case "ExceptionError":
      return `Exception: "${err.message}"`;
    case "ResponseError":
      return `ResponseError: "${JSON.stringify(err.responseError)}"`;
    case "JsonParseError":
      return `JsonParseError`;
    case "DecodeError":
      return `DecodeError: "${failure(err.decodeError)}"`;
  }
}

type JsonPayload<T> = {
  contentType: "application/json";
  data: T;
  encode: t.Encode<T, unknown>;
};

type FormDataPayload = {
  contentType: "multipart/form-data";
  data: FormData;
};

type Payload<T> = JsonPayload<T> | FormDataPayload;

export const JsonPayload =
  <T>(encode: t.Encode<T, unknown>) =>
  (data: T): Payload<T> => ({
    contentType: "application/json",
    data,
    encode,
  });

export const FormDataPayload = (data: FormData): Payload<FormData> => ({
  contentType: "multipart/form-data",
  data,
});

export function formDataFromFile(file: File): Payload<FormData> {
  const formData = new FormData();
  formData.append("file", file);
  return FormDataPayload(formData);
}

export function formDataFromFiles(files: File[]): Payload<FormData> {
  const formData = new FormData();
  files.forEach(file => 
  formData.append("file", file));

  return FormDataPayload(formData);
}

export function formDataFromClientSettings(payload: ClientSettingsPayload): Payload<FormData> {
  const formData = new FormData();
  const {form, files} = payload;
  if(files.length > 0) {
    files.forEach((file) => formData.append("logoFile", file));
   } else {
    formData.append("logoFile", "");
   }

  formData.append('name', form.companyName)
  formData.append('nmlsNumber', form.nmlsNumber)
  formData.append('phone', form.phone)
  formData.append('websiteUrl', form.websiteUrl)
  return FormDataPayload(formData);
}

export type ApiRequestOptions<T> =
  | {
      method: "GET" | "DELETE";
    }
  | {
      method: "POST" | "PUT" | "PATCH";
      payload: Payload<T>;
    };

export const Get = (): ApiRequestOptions<never> => ({
  method: "GET",
});
export const Delete = (): ApiRequestOptions<never> => ({
  method: "DELETE",
});
export const Post = <T>(payload: Payload<T>): ApiRequestOptions<T> => ({
  method: "POST",
  payload,
});

export const Put = <T>(payload: Payload<T>): ApiRequestOptions<T> => ({
  method: "PUT",
  payload,
});

export const Patch = <T>(payload: Payload<T>): ApiRequestOptions<T> => ({
  method: "PATCH",
  payload,
});

export const requestOptions =
  <P>(options: ApiRequestOptions<P>) =>
  (headers: Record<string, string>): RequestInit => {
    switch (options.method) {
      case "GET":
      case "DELETE":
        return {
          method: options.method,
          headers,
        };
      case "POST":
      case "PUT":
      case "PATCH":
        switch (options.payload.contentType) {
          case "application/json":
            return {
              method: options.method,
              headers: { ...headers, "content-type": "application/json" },
              body: JSON.stringify(
                options.payload.encode(options.payload.data),
              ),
            };
          case "multipart/form-data":
            return {
              method: options.method,
              headers,
              body: options.payload.data,
            };
        }
    }
  };

/**
 * Read the Response body as text, handling errors
 * @returns Task that resolves to a string or an ApiError
 */
const mapResponse = (response: Response): TaskEither<ApiError, string> => {
  return response.ok
    ? pipe(() => response.text(), T.map(E.right))
    : pipe(
        () => response.text(),
        T.map((txt) =>
          E.left({
            type: "ResponseError",
            responseError: { code: response.status, message: txt },
          }),
        ),
      );
};

/**
 * Read the Response body as blob, handling errors
 * @returns Task that resolves to a string or an ApiError
 */
export type FileDownloadBlob = {
  blob: Blob;
  fileName: string;
};
const mapBlobResponse = (
  response: Response,
  fileName: string,
): TaskEither<ApiError, FileDownloadBlob> => {
  return response.ok
    ? pipe(
        () => response.blob(),
        T.map((blob) => E.right({ blob, fileName })),
      )
    : pipe(
        () => response.text(),
        T.map((txt) =>
          E.left({
            type: "ResponseError",
            responseError: { code: response.status, message: txt },
          }),
        ),
      );
};

// a type-safe wrapper for the `fetch` function result
type FetchResult =
  | {
      type: "FetchResponse";
      response: Response;
    }
  | {
      type: "FetchError";
      message: string;
    };

export const FetchResponse = (response: Response): FetchResult => ({
  type: "FetchResponse",
  response,
});
export const FetchError = (message: string): FetchResult => ({
  type: "FetchError",
  message,
});

/**
 * Transforms the result of the `fetch` into a TaskEither
 */
export const handleFetchResult = (
  result: FetchResult,
): TaskEither<ApiError, string> => {
  switch (result.type) {
    case "FetchResponse":
      return mapResponse(result.response);
    case "FetchError":
      return pipe(result.message, ExceptionError, E.left, T.of);
  }
};

export const handleBlobResult = (
  result: FetchResult,
): TaskEither<ApiError, FileDownloadBlob> => {
  switch (result.type) {
    case "FetchResponse":
      {
        const contentDispositionHeaderValue = O.fromNullable(
          result.response.headers.get("content-disposition"),
        );

        const contentType = O.fromNullable(
          result.response.headers.get("content-type"),
        );

        const fileName = pipe(
          contentDispositionHeaderValue,
          O.chain((v) => {
            return pipe(
              v,
              S.split(";"),
              RA.map((v) => v.trim()),
              RA.filter((x) => x.startsWith("filename")),
              RA.head,
              O.chain((dispositionHeader) => {
                return pipe(dispositionHeader, S.split("="), RA.lookup(1));
              }),
              O.map((v) => v.trim().replace(/"/g, "")),
            );
          }),
          O.orElse(() => contentType),
          O.fold(
            () => "some-file.zip",
            (v) => {
              switch (v) {
                case "application/pdf":
                  return "sample.pdf";

                case "image/jpeg":
                  return "sample.jpeg";

                default:
                  return "some-file.zip";
              }
            },
          ),
        );

        return mapBlobResponse(result.response, fileName);
      }
      break;
    case "FetchError":
      return pipe(result.message, ExceptionError, E.left, T.of);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleFetchError = (err: any) =>
  err.message && typeof err.message === "string"
    ? FetchError(err.message)
    : FetchError("Unknown error");

/**
 * Try to parse a string as JSON
 * @returns Either an ApiError or the parsed JSON
 */
export const tryParseJson = (text: string) =>
  E.tryCatch<ApiError, unknown>(() => {
    const payload = text.length <= 0 ? null : JSON.parse(text);
    return payload;
  }, JsonParseError);

/**
 * Try to parse a string as JSON
 * @returns Either an ApiError or the parsed JSON
 */
export const tryDownloadFile = ({ blob, fileName }: FileDownloadBlob) =>
  E.tryCatch<ApiError, string>(() => {
    // Create  blob  URL
    const blobUrl = window.URL.createObjectURL(blob);

    // Create a temporary anchor el
    const anchorEl = document.createElement("a");
    anchorEl.href = blobUrl;
    anchorEl.download = fileName;
    anchorEl.style.display = "none";

    // Append the a tag to the DOM and click it to trigger download
    document.body.appendChild(anchorEl);
    anchorEl.click();

    // Clean up
    document.body.removeChild(anchorEl);
    window.URL.revokeObjectURL(blobUrl);
    return fileName;
  }, JsonParseError);

export const makeDownloadRequest =
  <Payload>(url: string, options: ApiRequestOptions<Payload>) =>
  (token: string): TE.TaskEither<ApiError, string> =>
    pipe(
      () =>
        fetch(url, requestOptions(options)(makeBearerHeader(token)))
          .then(FetchResponse)
          .catch(handleFetchError),

      T.chain(handleBlobResult), // TaskEither<ApiError, string> plain text
      TE.chainEitherK(flow(tryDownloadFile)), // TaskEither<ApiError, unknown> valid JSON
    );

export const makeAuthenticatedRequest =
  <T, Payload>(
    url: string,
    options: ApiRequestOptions<Payload>,
    decode: t.Decode<unknown, T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformer: (u: any) => any = (u) =>
      E.tryCatch<ApiError, unknown>(() => identity(u), JsonParseError),
  ) =>
  (token: string): TE.TaskEither<ApiError, T> =>
    pipe(
      () =>
        fetch(url, requestOptions(options)(makeBearerHeader(token)))
          .then(FetchResponse)
          .catch(handleFetchError),

      T.chain(handleFetchResult), // TaskEither<ApiError, string> plain text
      TE.chainEitherK(flow(tryParseJson)), // TaskEither<ApiError, unknown> valid JSON
      TE.chainEitherK(flow(transformer)),
      TE.chainEitherK(flow(decode, E.mapLeft(DecodeError))), // TaskEither<ApiError, T> decoded data
    );

export const makeRequest = <T, Payload>(
  url: string,
  options: ApiRequestOptions<Payload>,
  decode: t.Decode<unknown, T>,
): TE.TaskEither<ApiError, T> =>
  pipe(
    () =>
      fetch(url, requestOptions(options)({}))
        .then(FetchResponse)
        .catch(handleFetchError),
    T.chain(handleFetchResult), // TaskEither<ApiError, string> plain text
    TE.chainEitherK(tryParseJson), // TaskEither<ApiError, unknown> valid JSON
    TE.chainEitherK(flow(decode, E.mapLeft(DecodeError))), // TaskEither<ApiError, T> decoded data
  );


  export function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }