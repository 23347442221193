import * as EditBranch from "@/components/UserManagement/EditBranch";
import { BranchId } from "@/data/client";
import { Option, none } from "fp-ts/lib/Option";

export type DialogModel =
  | {
      type: "NewBranchDialog";
      dialogModel: EditBranch.Model;
    }
  | {
      type: "EditBranchDialog";
      branchId: BranchId;
      dialogModel: EditBranch.Model;
    }
  | {
      type: "DeleteBranchDialog";
      branchId: BranchId;
    };

export const NewBranchDialog = (
  dialogModel: EditBranch.Model,
): DialogModel => ({
  type: "NewBranchDialog",
  dialogModel,
});

export const EditBranchDialog =
  (branchId: BranchId) =>
  (dialogModel: EditBranch.Model): DialogModel => ({
    type: "EditBranchDialog",
    branchId,
    dialogModel,
  });

export const DeleteBranchDialog = (branchId: BranchId): DialogModel => ({
  type: "DeleteBranchDialog",
  branchId,
});

export type Model = {
  search: string;
  dialog: Option<DialogModel>;
};

export const init = (): Model => ({
  search: "",
  dialog: none,
});

export const withDialogClosed = (model: Model): Model => ({
  ...model,
  dialog: none,
});
