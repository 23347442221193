import "@/assets/styles/Tooltip/tooltip.css";
import { User } from "@/data/client";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import { constant, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Button } from "../Button";
import { Icon, IconType } from "../Icon";
import { Row } from "../Row";

type Props = { user: User; menuItems: ProfileMenuDef[] };

export type ProfileMenuDef = {
  icon?: IconType;
  label: string;
  onClick: O.Option<() => void>;
};

export function ProfileMenu(props: Props): JSX.Element {
  return (
    <Row alignVertical="baseline" className="menu-bar">
      <Row grow={1} alignHorizontal="right" gap="md" alignVertical="baseline">
        <Button
          type="tertiary"
          className="ac-menu-icon menu-notification-btn"
          onClick={O.some(() => {})}
        >
          <i className="fa-regular fa-bell menu-notification-icon"></i>
        </Button>
      </Row>

      <Menu
        arrow={true}
        menuButton={
          <MenuButton className="button button-flat text-sm">
            <Row gap="xs" alignVertical="baseline" padding="xs">
              <div className="applicant-id">DM</div>
              {pipe(
                O.of(
                  (firstName: string) => (lastName: string) =>
                    `${firstName} ${lastName}`,
                ),
                O.ap(props.user.firstName),
                O.ap(props.user.lastName),
                O.getOrElseW(constant(props.user.email)),
              )}
              <Icon type="chevron-up"></Icon>
              
            </Row>
          </MenuButton>
        }
        transition
      >
        {props.menuItems.map(({ label, onClick }, i) => (
          <MenuItem
            key={i}
            onClick={() =>
              pipe(
                onClick,
                O.map((v) => v),
                O.getOrElse(() => () => {}),
              )()
            }
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Row>
  );
}
