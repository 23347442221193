import * as t from "io-ts";
import * as tt from "io-ts-types";

const ssnRegex =
  /^(?!000)(?!666)([0-6]\d{2}|7([0-6]\d|7[012]))(?!00)\d{2}(?!0000)\d{4}$/;

interface ValidSSNBrand {
  readonly ValidSSN: unique symbol;
}

export const socialSecurityNumberCodec = t.brand(
  tt.NonEmptyString,
  (s): s is t.Branded<tt.NonEmptyString, ValidSSNBrand> => ssnRegex.test(s),
  "ValidSSN",
);

export const socialSecurityNumberCodecWithMessage = (errorMessage: string) => tt.withMessage(
  socialSecurityNumberCodec,
  () => errorMessage
)

export type SocialSecurityNumber = t.TypeOf<typeof socialSecurityNumberCodec>;

interface OptionalValidSSNBrand {
  readonly OptionalValidSSN: unique symbol;
}

export const optionalSocialSecurityNumberCodec = t.brand(
  t.string,
  (s): s is t.Branded<string, OptionalValidSSNBrand> => {
    if (!s || s.trim().length == 0) {
      return true;
    }
    return ssnRegex.test(s);
  },
  "OptionalValidSSN",
);

export const optionalSocialSecurityNumberCodecWithMessage = (errorMessage: string) =>  tt.withMessage(
  optionalSocialSecurityNumberCodec,
  () => errorMessage
);

export type OptionalSocialSecurityNumber = t.TypeOf<typeof optionalSocialSecurityNumberCodec>;

const ssnConfirmRegex = /^\d{4}$/;

interface ValidConfirmSSNBrand {
  readonly ValidConfirmSSN: unique symbol;
}

export const confirmSocialSecurityNumberCodec = t.brand(
  tt.NonEmptyString,
  (s): s is t.Branded<tt.NonEmptyString, ValidConfirmSSNBrand> => {
    console.log(s, ssnConfirmRegex.test(s));

    return ssnConfirmRegex.test(s);
  },
  "ValidConfirmSSN",
);

export type ConfirmSocialSecurityNumber = t.TypeOf<
  typeof confirmSocialSecurityNumberCodec
>;