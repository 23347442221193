import "@/assets/styles/basic/TogglePanel/togglePanel.css";
import { Col, Row } from "@/components/basic";
import { useState } from "react";

export type TogglePanelProps = {
  id?: string;
  children: React.ReactNode;
  title: string;
};

export function TogglePanel(props: TogglePanelProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Col id={props.id} >
      <Row
        padding="sm"
        alignHorizontal="space-between"
        className="toggle-panel-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3>{props.title}</h3>

        {isOpen ? (
          <i className="fa-solid fa-chevron-up"></i>
        ) : (
          <i className="fa-solid fa-chevron-down"></i>
        )}
      </Row>

      {isOpen && <div className="toggle-panel-content">{props.children}</div>}
    </Col>
  );
}
