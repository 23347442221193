import {
  Button,
  Checkbox,
  Col,
  ConfirmSsnInputField,
  Icon,
  InputField,
  Label,
  Row,
} from "@/components/basic";
import { ChildProps } from "@/utils/reducerWithEffect";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import {
  Action,
  ConsentItemChanged,
  ConsentSubmitted,
  DateOfBirthChanged,
  SocialSecurityNumberChanged,
} from "./action";
import { Model } from "./model";

import { SaveConsentsPayload } from "@/data/consents";
import { ApplicationId } from "@/data/payload";
import { OrdSurveyTips } from "@/data/surveyTips";
import { Started } from "@/utils/asyncOperationStatus";
import { ConfirmSocialSecurityNumber } from "@/utils/codecs";
import { deferredToOption } from "@/utils/deferred";
import { FormField } from "@/utils/formField";
import { showApiError } from "@/utils/request";
import { useMemo } from "react";
import { result } from "./model";
import { Lens } from "monocle-ts";

export type Props = ChildProps<Model, Action> & {
  onApplicationSelected: (applicationId: ApplicationId) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;
  const { tips } = model;

  const surveyValidationResult = useMemo(
    () => result(model),

    [model],
  );

  const areConsentMethodsOk = useMemo(
    () => surveyValidationResult[1].left.length == 0,
    [surveyValidationResult],
  );

  const surveyPayload = useMemo(
    () =>
      pipe(
        surveyValidationResult[0],
        O.fromEither,
        O.map((payload: SaveConsentsPayload): SaveConsentsPayload => {
          const lens = Lens.fromPath<SaveConsentsPayload>()(["consents"]);
          const pageModel = model;

          return lens.modify((model) =>
            pipe(model, (consentItems) => {
              consentItems.push(
                ...pageModel.form.consentItems
                  .filter((v) => !v.requiredForBorrower)
                  .map((v) => ({
                    consentVersionId: v.consentVersionId,
                    method: null,
                    consentProvided: v.raw,
                  })),
              );
              return consentItems;
            }),
          )(payload);
        }),
      ),
    [surveyValidationResult, model],
  );

  const submitConsentAction = useMemo(
    () =>
      pipe(
        areConsentMethodsOk,
        O.fromPredicate(identity),
        O.chain(constant(surveyPayload)),
        O.map((payload: SaveConsentsPayload) =>
          flow(
            Started,
            ConsentSubmitted(payload, props.onApplicationSelected),
            dispatch,
          ),
        ),
      ),
    [surveyPayload, dispatch, props.onApplicationSelected, areConsentMethodsOk],
  );

  const surveyTips = useMemo(() => {
    return pipe(
      tips,
      deferredToOption,
      O.chain(O.fromEither),
      O.fold(
        () => [],
        flow(
          A.filter(
            ({ pageIdentifier }) => "survey-ssn-consent" === pageIdentifier,
          ),
          A.sort(OrdSurveyTips),
        ),
      ),
    );
  }, [tips]);

  return (
    <>
      <Row grow={1} padding="md">
        <Col grow={2} gap="md" background="grey10" padding="xs">
          <Col alignHorizontal="stretch">
            <h3 className="text-lg">
              In order to access your application we have a few steps you need
              to complete.
            </h3>
          </Col>
          <Col padding="xl" gap="xl">
            {model.form?.primaryApplicant && (
              <>
                {model.ssnConsentVerificationFailed ? (
                  <Col gap="xs" className="container error" padding="xs">
                    <div className="text-lg">Contact your loan officer</div>
                    <div>
                      Seems like your having issues with providing the details
                      below. Please contact{" "}
                      {model.verificationStatus.loanOfficer.fullName} at{" "}
                      {model.verificationStatus.loanOfficer.email}
                    </div>
                  </Col>
                ) : null}

                <div className="grid-2 gap-xs">
                  <Label className="column-span-2 text-lg">
                    {model.form.primaryApplicant.name}
                  </Label>

                  <ConfirmSsnInputField
                    label="Last 4 digits of your Social Security Number"
                    field={
                      model.form.primaryApplicant
                        .socialSecurityNumber as FormField<ConfirmSocialSecurityNumber>
                    }
                    onChange={flow(
                      SocialSecurityNumberChanged("PrimaryApplicant"),
                      dispatch,
                    )}
                  />

                  <InputField
                    label="Date Of Birth"
                    type="date"
                    className="column-span-1"
                    field={model.form.primaryApplicant.dateOfBirth}
                    onChange={flow(
                      DateOfBirthChanged("PrimaryApplicant"),
                      dispatch,
                    )}
                  />
                  {pipe(
                    model.form.coApplicant,
                    O.map((v) => {
                      return (
                        <>
                          <InputField
                            label="SSN of Joint Applicant"
                            className="column-span-1"
                            field={v.socialSecurityNumber}
                            onChange={flow(
                              SocialSecurityNumberChanged("CoApplicant"),
                              dispatch,
                            )}
                          />

                          <InputField
                            label="Date Of Birth"
                            type="date"
                            className="column-span-1"
                            field={v.dateOfBirth}
                            onChange={flow(
                              DateOfBirthChanged("CoApplicant"),
                              dispatch,
                            )}
                          />
                        </>
                      );
                    }),
                    O.getOrElse(() => <></>),
                  )}
                </div>
              </>
            )}
            <ConsentView {...props} />
            <div className="grid-6 gap-xs ">
              <Button
                type="primary"
                onClick={submitConsentAction}
                className="padding-md"
              >
                <Row gap="xs" alignHorizontal="center">
                  Continue
                  <Icon type="arrow-right" />
                </Row>
              </Button>
            </div>
          </Col>
        </Col>

        <Col grow={1}>
          {surveyTips.map((tip) => (
            <Col
              key={tip.pageIdentifier + tip.pageTipId}
              padding="sm"
              gap="sm"
              className="container"
              alignHorizontal="left"
            >
              <Row
                padding="xxxs"
                gap="xxxs"
                className="tip-question"
                alignVertical="center"
              >
                <Icon type="lightbulb" />
                <span>Why are you asking this?</span>
              </Row>
              <Row gap="xs">
                <img height={32} src={tip.avatarUrl} />
                <Label>
                  <b>{tip.title}</b>
                </Label>
              </Row>
              <Label>{tip.content}</Label>
            </Col>
          ))}
        </Col>
      </Row>
    </>
  );
}

function ConsentView(props: Props): JSX.Element {
  const { model } = props;
  switch (model.consents.status) {
    case "InProgress":
    case "NotStarted":
      return <>"Loading"</>;
    case "Updating":
    case "Resolved":
      return pipe(
        model.consents.value,
        E.map(() => model.form.consentItems),
        E.fold(
          (error) => (
            <Col padding="sm" gap="sm" className="chart-container">
              <Label>Failed to load consents</Label>
              {showApiError(error)}
            </Col>
          ),
          (consentItems) => {
            return (
              <>
                {consentItems.map((v, i) => {
                  return (
                    <Row padding="xs" key={i}>
                      <Col>
                        <Checkbox
                          className="column-span-2"
                          label={v.title}
                          checked={Boolean(v.raw)}
                          onChange={flow(
                            ConsentItemChanged(v.consentVersionId),
                            props.dispatch,
                          )}
                        />
                        <div
                          dangerouslySetInnerHTML={{ __html: v.description }}
                        ></div>
                      </Col>
                    </Row>
                  );
                })}
              </>
            );
          },
        ),
      );
  }
}
