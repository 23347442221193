import "@/assets/styles/Review/application.css";
import { Button, Col, Icon, Row, TogglePanel } from "@/components/basic";
import { AddressPayload, ApplicantPayload, Application, showBankruptcyStatusType, showIncomeSourceType, showJointApplicantType, showRetirementAccountType, showSourceOfFunds } from "@/data/payload";
import { pipe } from "fp-ts/lib/function";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { Option, fold, some } from "fp-ts/lib/Option";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import { Tag } from "../basic/Tag";

function scrollIntoView(id: string, section?: string, index?: number) {
  const targetId = section && index !== undefined ? `section-${section}-${index}` : id;
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}

function relativeDate(exportedDateOption: Option<DateTime>) {
  return fold(
    () => 'Today', 
    (exportedDate: DateTime) => {
      const now = DateTime.local();
      const diffInDays = now.diff(exportedDate, 'days').days;

      if (diffInDays < 1) {
        return 'Today';
      } else if (diffInDays < 2) {
        return '1 day ago';
      } else {
        return `${Math.floor(diffInDays)} days ago`;
      }
    }
  )(exportedDateOption);
}

function ApplicationSummary(props: Props) {
  return (
    <>
      <Row gap="xs" padding="md" alignHorizontal="right">
        <Button type="primary" onClick={some(props.onEdit)}>Export</Button>
        <Button type="secondary" onClick={some(props.onEdit)}>Edit</Button>
        <Col alignVertical="center">
          <span>More actions <i className="fa-solid fa fa-check ml-left-xxs"></i></span>
        </Col>
        <div></div>
      </Row>
      <div className="application-section">
        <Row gap="xs" className="text-lg grid-item-3 padding-horizontal-md">
          <span>{props.payload.survey.primaryApplicant.fullName}</span>
        </Row>
        <Row gap="xs" className="flex-wrap" padding="md" alignHorizontal="space-between">
          <Col gap="xs">
            <b>Reference</b>
            <span>RR 87654</span>
          </Col>
          <Col gap="xs">
            <b>Status</b>
            <Tag
              type="neutral"
              iconAlign="none"
              icon={O.some("info")}
              className="text-sm font-weight-500"
            >
              {props.payload.status}
            </Tag>
          </Col>
          <Col gap="xs">
            <b>Credit Check</b>
            <span>Authorized <i className="fa-solid fa fa-check ml-left-xxs"></i></span>
          </Col>
          <Col gap="xs">
            <b>Type</b>
            <span>{props.payload.survey.applicationType}</span>
          </Col>
          <Col gap="xs">
            <b>Submitted</b>
            <Tag
              type="neutral"
              iconAlign="none"
              icon={O.some("info")}
              className="text-sm font-weight-500"
            >
        {relativeDate(props.payload.submittedDateUtc)} 
        </Tag>
          </Col>
          <Col gap="xs">
            <b>Assigned to</b>
            <span><Icon type="user" /> John</span>
          </Col>
           {/* <Col gap="xs">
            <b>Application Type</b>
            <span>{showApplicationType(props.payload.applicationType)}</span>
          </Col>
          <Col gap="xs">
            <b>Property Type</b>
            <span>{showPropertyType(props.payload.propertyType)}</span>
          </Col> */}
        </Row>
      </div>
    </>
  );
}

export type Props = {
  payload: Application;
  onEdit: () => void;
  children: ReactNode;
};

function renderCommonContent(props: Props) {
  return (
    <Col gap="md" padding="md" className="w-100">
      <ApplicationSummary {...props} />
      <ApplicantView index={0} {...props.payload} />
      {props.children}
    </Col>
  );
}

export function View(props: Props & { children: ReactNode }): JSX.Element {
  return (
    <div>
      <div className="desktop-view">
        <Row gap="xs" padding="md" className="w-100">
          <Col padding="sm" className="summary-navigation w-25">
            <b className="text-md padding-vertical-lg"><Icon type="arrow-left" /> Go Back</b>
            <ApplicantSummaryItem index={0} {...props.payload.survey.primaryApplicant} />
            {props.payload.survey.jointApplicants.map((coApplicant, index) => (
              <ApplicantSummaryItem key={index} index={index + 1} {...coApplicant.applicant} />
            ))}
          </Col>
          {renderCommonContent(props)}
        </Row>
      </div>

      <div className="mobile-view">
        <Row gap="xs" padding="md" className="w-100">
        {renderCommonContent(props)}
        </Row>
      </div>
    </div>
  );
}

function formatAddress(address: AddressPayload, isCurrent: boolean): string {
  return isCurrent
    ? [address.street, address.city, address.state].join(", ")
    : `Previous Address: ${[address.street, address.city, address.state].join(", ")}`;
}

function ApplicantView(props: Application & { index: number }): JSX.Element {
  const { index, survey } = props;

  const applicants = [
    { ...survey.primaryApplicant, index },
    ...survey.jointApplicants.map((jointApplicant, jointIndex) => ({
      ...jointApplicant.applicant,
      index: jointIndex, 
    })),
  ];

  return (
    <>
      {applicants.map((applicant, applicantIndex) => (
        <TogglePanel key={applicantIndex} title={applicant.fullName}>
          <div className="grid-3">
            <Row gap="xs" className="text-lg grid-item-3 padding-vertical-xl">
              <Icon type="user" />
              <span id={`section-personal-${applicantIndex}`}>Personal Information</span>
            </Row>

            <Col gap="xs" className="grid-item-1">
              <b>Full Name</b>
              <span>{applicant.fullName}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Date of Birth</b>
              <span>{applicant.dateOfBirth?.toLocaleString(DateTime.DATE_SHORT)}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Social Security Number</b>
              <span>{applicant.ssn}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Email</b>
              <span>{applicant.email}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Cell Number</b>
              <span>{applicant.phone}</span>
            </Col>

            <hr className="grid-item-3" />

            <Col gap="xs" className="grid-item-1">
              <b>Current Address</b>
              <span>{pipe(applicant.addresses, NEA.head, (address) => formatAddress(address, true))}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Previous Address</b>
              <span>{pipe(applicant.addresses, NEA.tail, (addresses) => addresses.length > 0 ? formatAddress(addresses[0], false) : "")}</span>
            </Col>

            <hr className="grid-item-3" />

            <Col gap="xs" className="grid-item-1">
              <b>Marital Status</b>
              <span>{applicant.maritalStatus}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Previously Divorced</b>
              <span>{applicant.divorced ? "Yes" : "No"}</span>
            </Col>

            <hr className="grid-item-3" />

            <Col gap="xs" className="grid-item-1">
              <b>Citizenship</b>
              <span>{applicant.citizenshipStatus}</span>
            </Col>

            <Row gap="xs" className="text-lg grid-item-3 padding-vertical-xl">
              <Icon type="house" />
              <span>Mortgage Information</span>
            </Row>

            <Col gap="xs" className="grid-item-1">
              <b>Source of Funds</b>
              {props.survey.sourceOfFunds.map((source) => (
                <span key={source}>{showSourceOfFunds(source)}</span>
              ))}
            </Col>

            <hr className="grid-item-3" />

              <Col gap="xs" className="grid-item-1">
                <b>Application</b>
                <span>Joint Application</span>
              </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Relationship</b>
              {props.survey.jointApplicants.map((relation) => (
                <span key={relation.applicantType}>{showJointApplicantType(relation.applicantType)}</span>
              ))}
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Full Name</b>
              {props.survey.jointApplicants.map((name) => (
                <span key={name.applicant.fullName}>{name.applicant.fullName}</span>
              ))}
            </Col>

            <hr className="grid-item-3" />

            <Col gap="xs" className="grid-item-1">
              <b>Real Estate Agent</b>
              <span> {pipe(
                props.survey.realEstateAgentName,
                O.fold(
                  () => '',
                  (name) => name
                )
              )}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Primary Contact</b>
              <span>Rehan</span>
            </Col>

            <hr className="grid-item-3" />

            <Col gap="xs" className="grid-item-1">
              <b>Email Address</b>
              {props.survey.jointApplicants.map((email) => (
                <span key={email.applicant.email}>{email.applicant.email}</span>
              ))}
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Contact Number</b>
              {props.survey.jointApplicants.map((phone) => (
                <span key={phone.applicant.phone}>{phone.applicant.phone}</span>
              ))}
            </Col>

            <Row gap="xs" className="text-lg grid-item-3 padding-vertical-xl">
              <Icon type="piggy-bank" />
              <span id={`section-financial-${applicantIndex}`}>Financial Information</span>
            </Row>

            <Col gap="xs" className="grid-item-1">
              <b>Current Sources of Income</b>
              {applicant.incomeSources.map((source) => (
                <span key={source.type}>{showIncomeSourceType(source.type)}</span>
              ))}
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Type of work</b>
              <span>{showBankruptcyStatusType(applicant.bankruptcyStatus.type)}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Other Sources of Income</b>
              {applicant.incomeSources.map((source) => (
                <span key={source.type}>{showIncomeSourceType(source.type)}</span>
              ))}
            </Col>

            <hr className="grid-item-3" />

            <Row gap="xs" className="text-lg grid-item-3 padding-vertical-xl">
              <span>Additional Financial Information</span>
            </Row>

            <Col gap="xs" className="grid-item-1">
              <b>Financial History</b>
              <span>{props.survey.primaryApplicant.foreclosureOrShortSale ? "Yes" : "No"}</span>
            </Col>

            <Col gap="xs" className="grid-item-1">
              <b>Retirement Accounts Held</b>
              <span>{showRetirementAccountType([...applicant.retirementAccounts][0])}</span>
              </Col>
          </div>
        </TogglePanel>
      ))}
    </>
  );
}

type ApplicantSummaryItemProps = ApplicantPayload & { index: number };

function ApplicantSummaryItem(props: ApplicantSummaryItemProps): JSX.Element {
  const { index } = props; return (
    <Col padding="md" gap="xs">
      <label className="text-md">{props.fullName}</label>

      <Row gap="xs" alignHorizontal="space-between" className="text-md pointer" onClick={() => scrollIntoView('personal', 'personal', index)}>
        <Icon type="user" />
        <span>Personal Information</span>
        <Icon type="circle-check" />
      </Row>
      <Row gap="xs" alignHorizontal="space-between" className="text-md pointer" onClick={() => scrollIntoView('financial', 'financial', index)}>
        <Icon type="piggy-bank" />
        <span>Financial Information</span>
        <Icon type="circle-check" />
      </Row>
      <Row gap="xs" alignHorizontal="space-between" className="text-md pointer" onClick={() => scrollIntoView('documents','documents',index)}>
        <Icon type="file-lines" />
        <span>Upload Documentation</span>
        <Icon type="circle-check" />
      </Row>
    </Col>
  );
}

export function Verify(verify: Props): JSX.Element {
  return (
    <div>
        <TogglePanel id="verification-section" title="Verification">
        <div>
          {verify.payload.survey.primaryApplicant.consents.map((consent) => (
            <Row alignVertical="center" gap="xs" padding="xs" >
              <Icon type="check" />
              <Col padding="sm">
                <><span className="text-bold">{consent.method}</span><span>{consent.consentProvided ? "Yes" : "No"}</span></>
              </Col>
            </Row>
          ))}
        </div>
      </TogglePanel>
    </div>
  );
}