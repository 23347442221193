import "@/assets/styles/home-page/styles.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import homePageAppCatcherLogo from "@/assets/images/home-page-app-catcher-logo.png";
import homePageSection01MainMobile from "@/assets/images/home-page-section-01-main-mobile.png";
import homePageSection02MainCard01 from "@/assets/images/home-page-section-02-main-card-01.png";
import homePageSection02MainCard02 from "@/assets/images/home-page-section-02-main-card-02.png";
import homePageSection02MainCard03 from "@/assets/images/home-page-section-02-main-card-03.png";
import homePageSection03Main from "@/assets/images/home-page-section-03-main.png";
import homePageSection04Main from "@/assets/images/home-page-section-04-main.png";
import homePageSection05Main from "@/assets/images/home-page-section-05-main.png";
import homePageSection05MainCard01 from "@/assets/images/home-page-section-05-main-card-01.png";
import homePageSection05MainCard02 from "@/assets/images/home-page-section-05-main-card-02.png";
import homePageSection05MainCard03 from "@/assets/images/home-page-section-05-main-card-03.png";
import homePageSection06MainCard01 from "@/assets/images/home-page-section-06-main-card-01.png";
import homePageSection06MainCard02 from "@/assets/images/home-page-section-06-main-card-02.png";
import homePageSection06MainCard03 from "@/assets/images/home-page-section-06-main-card-03.png";
import homePageSection06MainCard04 from "@/assets/images/home-page-section-06-main-card-04.png";
import homePageSection06MainCard05 from "@/assets/images/home-page-section-06-main-card-05.png";
import homePageSection06MainCard06 from "@/assets/images/home-page-section-06-main-card-06.png";
import homePageSection07ManinMobileBg from "@/assets/images/home-page-section-07-main-mobile-bg.png";
import { Button, Col, Icon, Label, Row } from "@/components/basic";
import { FunctionComponent } from "react";
import * as O from "fp-ts/Option";
import { ViewResolver } from "@/utils/viewResolver";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { pipe } from "fp-ts/lib/function";

type SectionProps = {
  onSignup: () => void;
};

const handleOpenPopup = () => {
  window.open(
    "https://calendly.com/appcatcher/30min",
    "_blank",
    `resizable,scrollbars=yes,status=no`,
  );
};
export const HeaderSection: FunctionComponent<SectionProps> = (
  props: SectionProps,
) => {
  const items = [
    {
      label: "Create an account",
      onClick: O.some(() => props.onSignup()),
    },

    {
      label: "Book a demo",
      onClick: O.some(() => handleOpenPopup()),
    },
    {
      label: "Sign In",
      onClick: O.some(() => {
        window.location.href = "/login";
      }),
    },
  ];

  const MenuView = (
    <Row gap="md">
      <Button
        className="action-01"
        type="flat"
        onClick={O.some(() => props.onSignup())}
      >
        Create an account
      </Button>
      <Button
        className="action-02"
        type="secondary"
        onClick={O.some(() => handleOpenPopup())}
      >
        Book a demo
      </Button>
      <Button
        className="action-03"
        type="flat"
        onClick={O.some(() => (window.location.href = "/login"))}
      >
        Sign In
      </Button>
    </Row>
  );

  const MenuCollapseView = (
    <Row gap="md">
      <Menu
        arrow={true}
        menuButton={
          <MenuButton className="button button-flat text-sm">
            <Row gap="xs" alignVertical="baseline" padding="xs">
              <div className="collapse-menu">Menu</div>
              <Icon type="chevron-up"></Icon>
            </Row>
          </MenuButton>
        }
        transition
      >
        {items.map(({ label, onClick }, i) => (
          <MenuItem
            key={i}
            onClick={() =>
              pipe(
                onClick,
                O.map((v) => v),
                O.getOrElse(() => () => {}),
              )()
            }
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Row>
  );
  return (
    <Col className="nav-bar" padding="fallback">
      <Col className="content">
        <Row alignVertical="center" alignHorizontal="space-between">
          <Col>
            <img src={homePageAppCatcherLogo}></img>
          </Col>
          {ViewResolver({
            viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
            resolvedContent: [MenuView, MenuCollapseView],
          })}
        </Row>
      </Col>
    </Col>
  );
};
export const SectionOne: FunctionComponent<SectionProps> = (
  props: SectionProps,
) => {
  const DesktopView = (
    <Row className="section-01" grow={1}>
      <Col gap="md" className="bg" grow={1}>
        <Col
          padding="fallback"
          basis="45vw"
          className="width-limit"
          alignVertical="center"
          gap="lg"
        >
          <div className="dots"></div>
          <Button
            type="inline-inverted"
            className="ai-btn"
            onClick={O.some(() => {})}
          >
            AI-powered ⚡
          </Button>
          <Col gap="md">
            <div className="title">
              Let AI help your customers’ complete a mortgage application in
              minutes.
            </div>
            <div className="description">
              Streamlined, AI-Driven Application Process.
            </div>
            <Row gap="sm">
              <Button
                className="action-01"
                type="secondary"
                onClick={O.some(() => handleOpenPopup())}
              >
                Book a demo
              </Button>
              <Button
                className="action-02"
                type="tertiary"
                onClick={O.some(() => props.onSignup())}
              >
                Create an account
              </Button>
            </Row>
          </Col>
        </Col>
      </Col>
    </Row>
  );

  const CollapsedView = (
    <Col
      className="section-01"
      grow={1}
      alignHorizontal="center"
      gap="sm"
      padding="fallback"
    >
      <Col className="width-100">
        <div className="block-01"></div>
      </Col>
      <Col alignHorizontal="center">
        <Col
          padding="fallback"
          className="width-limit"
          alignVertical="center"
          grow={1}
          gap="lg"
        >
          <Button
            type="inline-inverted"
            className="ai-btn"
            onClick={O.some(() => {})}
          >
            AI-powered ⚡
          </Button>
          <Col gap="lg">
            <div className="title">
              Let AI help your customers’ complete a mortgage application in
              minutes.
            </div>
            <div className="description">
              Streamlined, AI-Driven Application Process.
            </div>
            <Col gap="lg">
              <Button
                className="action-01 width-100"
                type="secondary"
                onClick={O.some(() => {})}
              >
                Book a demo
              </Button>
              <Button
                className="action-02 width-100"
                type="tertiary"
                onClick={O.some(() => {})}
              >
                Create an account
              </Button>
            </Col>
          </Col>
        </Col>
        <Col>
          <img className="img-section" src={homePageSection01MainMobile}></img>
        </Col>
      </Col>
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, CollapsedView],
  });
  return <Row className="section dark">{ViewWrapper}</Row>;
};

export const SectionTwo: FunctionComponent = () => {
  const SectionCards = (
    <>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection02MainCard01}></img>
        </Col>
        <Col className="card-title">AI-Driven Process</Col>
        <Col className="card-description">
          Using advanced AI Technology your clients will enjoy the benefits of
          our automated process, making the entire application process simple
          for the consumer. All while providing you with the necessary data to
          accurately qualify your clients time and time again.
        </Col>
      </Col>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection02MainCard02}></img>
        </Col>
        <Col className="card-title">Fast Application</Col>
        <Col className="card-description">
          Complete in under 2 minutes. App Catcher is redefining how loan
          applications get completed, while lowering your bounce rate while
          improving your consumers experience. The days of asking your clients
          to complete a long or short form application are over.
        </Col>
      </Col>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection02MainCard03}></img>
        </Col>
        <Col className="card-title">Real-Time Tracking</Col>
        <Col className="card-description">
          Monitor progress allows you to see your clients progress without
          losing track of them in the process. App catcher provides super
          users/admins with incredible insights into branch productivity and
          individual users and teams real time tracking ability to nudge a
          client when required.
        </Col>
      </Col>
    </>
  );
  const DesktopView = (
    <Row className="cards" basis="50vh">
      {SectionCards}
    </Row>
  );

  const CollapsedView = (
    <Col className="cards" basis="50vh" gap="fallback">
      {SectionCards}
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, CollapsedView],
  });
  return (
    <Row className="section light">
      <Row className="section-02" grow={1}>
        <Col className="bg" grow={1}>
          <Col
            alignHorizontal="center"
            gap="xxl"
            padding="fallback"
            className="content"
          >
            <div className="title">Simplify Home Financing</div>
            {ViewWrapper}
          </Col>
        </Col>
      </Row>
    </Row>
  );
};

export const SectionThree: FunctionComponent = () => {
  const SectionDetail = (
    <>
      <div className="title">
        Gather your clients information in under 2 minutes.
      </div>
      <div className="description">
        App Catcher uses AI-processing to gather your clients information in
        under 2 minutes, making the application process quick and efficient. The
        days of sending a client a link asking your borrowers to complete an
        complicated application are over. We are raising the bar in simplicity
        and speed!
      </div>
    </>
  );

  const DesktopView = (
    <Row className="section-03" grow={1}>
      <Col gap="md" className="bg" grow={1}>
        <Col
          padding="fallback"
          basis="45vw"
          className="width-limit"
          alignVertical="center"
          gap="lg"
        >
          <Col gap="md">{SectionDetail}</Col>
          <div className="dots"></div>
        </Col>
      </Col>
    </Row>
  );

  const CollapsedView = (
    <Col
      className="section-03"
      grow={1}
      alignHorizontal="center"
      gap="sm"
      padding="fallback"
    >
      <Col alignHorizontal="center">
        <Col>
          <img className="img-section" src={homePageSection03Main}></img>
        </Col>
        <Col
          padding="fallback"
          className="width-limit"
          alignVertical="center"
          grow={1}
          gap="lg"
        >
          <Col gap="lg">{SectionDetail}</Col>
        </Col>
      </Col>
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, CollapsedView],
  });
  return <Row className="section light">{ViewWrapper}</Row>;
};

export const SectionFour: FunctionComponent = () => {
  const SectionDetail = (
    <>
      <div className="title">AI-powered calculations of income and assets</div>
      <div className="description">
        With automated calculations of income and assets, App Catcher ensures
        your loan application is accurate and comprehensive, reducing errors and
        delays. Having a complete and accurate application is a critical step in
        the loan origination process, not to mention improves your support teams
        bandwidth by supplying them with an accurate application time and time
        again.
      </div>
    </>
  );
  const DesktopView = (
    <Row className="section-04" grow={1}>
      <Col gap="md" className="bg" grow={1}>
        <Col className="bg-2" alignHorizontal="right">
          <Col
            padding="fallback"
            basis="45vw"
            className="width-limit"
            alignVertical="center"
            gap="lg"
          >
            <Col gap="md">{SectionDetail}</Col>
          </Col>
        </Col>
      </Col>
    </Row>
  );

  const CollapsedView = (
    <Col
      className="section-04"
      grow={1}
      alignHorizontal="center"
      gap="sm"
      padding="fallback"
    >
      <Col alignHorizontal="center">
        <Col>
          <img className="img-section" src={homePageSection04Main}></img>
        </Col>
        <Col
          padding="fallback"
          className="width-limit"
          alignVertical="center"
          grow={1}
          gap="lg"
        >
          <Col gap="lg">{SectionDetail}</Col>
        </Col>
      </Col>
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, CollapsedView],
  });
  return <Row className="section light">{ViewWrapper}</Row>;
};

export const SectionFive: FunctionComponent = () => {
  const SectionTitle = (
    <>
      <div className="title">
        Fewer errors and faster processing times for you and your teams.
      </div>
    </>
  );

  const SectionCards = (
    <Col gap="fallback" className="cards">
      <Row className="card" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection05MainCard01}></img>
        </Col>
        <Col gap="xxs">
          <Col className="card-title">Loan Officer</Col>
          <Col className="card-description">
            Experience a streamlined workflow with fewer errors and faster
            processing times.
          </Col>
        </Col>
      </Row>
      <Row className="card" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection05MainCard02}></img>
        </Col>
        <Col gap="xxs">
          <Col className="card-title">Homebuyers</Col>
          <Col className="card-description">
            Save time and reduce stress with a quick, user-friendly application
            process.
          </Col>
        </Col>
      </Row>
      <Row className="card" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection05MainCard03}></img>
        </Col>
        <Col gap="xxs">
          <Col className="card-title">Real Estate Agents</Col>
          <Col className="card-description">
            Enjoy faster closings and happier clients, enhancing your service
            reputation.
          </Col>
        </Col>
      </Row>
    </Col>
  );
  const DesktopView = (
    <Row className="section-05" grow={1}>
      <Col
        padding="fallback"
        alignVertical="center"
        gap="lg"
        className="content"
      >
        <Col gap="xl">
          {SectionTitle}
          <Col className="bg" alignHorizontal="left">
            {SectionCards}
          </Col>
        </Col>
      </Col>
    </Row>
  );

  const CollapsedView = (
    <Row className="section-05" grow={1}>
      <Col
        padding="fallback"
        alignVertical="center"
        gap="lg"
        className="content"
      >
        <Col gap="xl">
          {SectionTitle}
          <Col alignHorizontal="center" gap="xl">
            {SectionCards}
            <Col>
              <img className="img-section" src={homePageSection05Main}></img>
            </Col>
          </Col>
        </Col>
      </Col>
    </Row>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, CollapsedView],
  });
  return <Row className="section light">{ViewWrapper}</Row>;
};

export const SectionSix: FunctionComponent = () => {
  const SectionCards = (
    <>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection06MainCard01}></img>
        </Col>
        <Col className="card-title">AI-Driven Questions</Col>
        <Col className="card-description">
          Tailored to gather essential information quickly and accurately.
        </Col>
      </Col>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection06MainCard02}></img>
        </Col>
        <Col className="card-title">Quick Document Upload</Col>
        <Col className="card-description">
          Simplifies the document submission process.
        </Col>
      </Col>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection06MainCard03}></img>
        </Col>
        <Col className="card-title">Automated Loan Application</Col>
        <Col className="card-description">
          Streamlines application creation, ensuring completeness and accuracy.
        </Col>
      </Col>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection06MainCard04}></img>
        </Col>
        <Col className="card-title">Automated Loan Application</Col>
        <Col className="card-description">
          Streamlines application creation, ensuring completeness and accuracy.
        </Col>
      </Col>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection06MainCard05}></img>
        </Col>
        <Col className="card-title">Advanced Reporting</Col>
        <Col className="card-description">
          Offers insights into application trends and operational efficiency.
        </Col>
      </Col>
      <Col className="card" padding="fallback" gap="sm">
        <Col className="icon" padding="xs">
          <img src={homePageSection06MainCard06}></img>
        </Col>
        <Col className="card-title">Real-Time Progress Dashboard</Col>
        <Col className="card-description">
          Provides a comprehensive view of all ongoing applications,
          facilitating better management and support.
        </Col>
      </Col>
    </>
  );

  const DesktopView = (
    <Row
      className="cards"
      alignHorizontal="space-between"
      basis="25vh"
      wrap={true}
    >
      {SectionCards}
    </Row>
  );

  const CollapsedView = (
    <Col
      className="cards"
      alignHorizontal="space-between"
      basis="25vh"
      wrap={true}
      gap="fallback"
    >
      {SectionCards}
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, CollapsedView],
  });
  return (
    <Row className="section dark">
      <Row className="section-06" grow={1}>
        <Col
          gap="md"
          className={ViewResolver({
            viewModes: [
              ["Default"],
              [
                "Mobile-Portrait",
                "Mobile-Landscape",
                "Tablet-Portrait",
                "Tablet-Landscape",
              ],
            ],
            resolvedContent: ["bg", ""],
          })}
          grow={1}
        >
          <Col
            padding="fallback"
            basis="45vw"
            alignVertical="top"
            alignHorizontal="center"
            className="content"
            gap="lg"
          >
            <Col
              gap={ViewResolver({
                viewModes: [
                  ["Default"],
                  [
                    "Mobile-Portrait",
                    "Mobile-Landscape",
                    "Tablet-Portrait",
                    "Tablet-Landscape",
                  ],
                ],
                resolvedContent: ["xl", "md"],
              })}
              alignHorizontal={ViewResolver({
                viewModes: [
                  ["Default"],
                  [
                    "Mobile-Portrait",
                    "Mobile-Landscape",
                    "Tablet-Portrait",
                    "Tablet-Landscape",
                  ],
                ],
                resolvedContent: ["center", "left"],
              })}
            >
              <div className="title">Our Features</div>
              <Col basis="45vh" gap="fallback">
                {ViewWrapper}
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>
    </Row>
  );
};

export const SectionSeven: FunctionComponent<SectionProps> = (
  props: SectionProps,
) => {
  const Buttons = (
    <>
      <Button
        className={ViewResolver({
          viewModes: [
            ["Default"],
            [
              "Mobile-Portrait",
              "Mobile-Landscape",
              "Tablet-Portrait",
              "Tablet-Landscape",
            ],
          ],
          resolvedContent: ["action-01", "action-01 width-100"],
        })}
        type="secondary"
        onClick={O.some(() => handleOpenPopup())}
      >
        Book a demo
      </Button>
      <Button
        className={ViewResolver({
          viewModes: [
            ["Default"],
            [
              "Mobile-Portrait",
              "Mobile-Landscape",
              "Tablet-Portrait",
              "Tablet-Landscape",
            ],
          ],
          resolvedContent: ["action-01", "action-01 width-100"],
        })}
        type="tertiary"
        onClick={O.some(() => props.onSignup())}
      >
        Create an account
      </Button>
    </>
  );
  const ButtonsCollapseView = (
    <Col gap="sm" className="width-100">
      {Buttons}
    </Col>
  );

  const ButtonsView = <Row gap="sm">{Buttons}</Row>;

  const SectionContent = (
    <>
      <div className="title">Get faster, more accurate, applications.</div>
      <div className="description">
        Experience firsthand how AppCatcher can transform your clients mortgage
        application process. Schedule a demo now and step into a world of
        efficiency, accuracy, and security tailored to the needs of mortgage
        professionals and their clients.
      </div>
    </>
  );
  const DesktopView = (
    <Col className="card-section" alignHorizontal="center">
      <Col
        className="content width-limit"
        alignHorizontal="center"
        basis="20vw"
        alignVertical="center"
        padding="fallback"
        gap="md"
      >
        {SectionContent}
        {ViewResolver({
          viewModes: [["Default"], ["Tablet-Landscape", "Tablet-Portrait"]],
          resolvedContent: [ButtonsView, ButtonsCollapseView],
        })}
      </Col>
    </Col>
  );

  const CollapsedView = (
    <>
      <Col alignHorizontal="center">
        <img className="img-section" src={homePageSection07ManinMobileBg}></img>
      </Col>
      <Col className="card-section" alignHorizontal="center">
        <Col
          className="content width-limit"
          alignHorizontal="center"
          basis="20vw"
          alignVertical="center"
          padding="fallback"
          gap="md"
        >
          {SectionContent}
          {ButtonsCollapseView}
        </Col>
      </Col>
    </>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
    resolvedContent: [DesktopView, CollapsedView],
  });
  return (
    <Row className="section dark">
      <Row className="section-07" grow={1}>
        <Col
          className="card"
          padding="fallback"
          grow={1}
          gap={ViewResolver({
            viewModes: [["Default"], ["Mobile-Landscape", "Mobile-Portrait"]],
            resolvedContent: ["fallback", "xs"],
          })}
        >
          {ViewWrapper}
        </Col>
      </Row>
    </Row>
  );
};

export const FooterSection: FunctionComponent = () => {
  const Links = (
    <>
      <a
        className="text-decoration-none"
        href="https://appcatcher.com/Privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Label className="cp usage">Privacy</Label>
      </a>
      <a
        className="text-decoration-none"
        href="https://appcatcher.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Label className="cp usage">Legal Agreement</Label>
      </a>
      <a
        className="text-decoration-none"
        href="https://appcatcher.com/TermsOfUse"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Label className="cp usage">Terms Of Use</Label>
      </a>
    </>
  );
  const LinksView = <Row gap="lg">{Links}</Row>;

  const LinksCollapseView = (
    <Col gap="md" alignHorizontal="center">
      {Links}
    </Col>
  );

  return (
    <Row className="section dark">
      <Row className="section-footer" grow={1}>
        <Col gap="md" grow={1} className="bg" alignHorizontal="center">
          <Col
            padding="fallback"
            className="width-limit"
            alignVertical="center"
            gap="lg"
          >
            <Col
              className="content"
              alignHorizontal="center"
              gap="xl"
              padding="fallback"
            >
              <Col className="logo">
                <img className="img-section" src={homePageAppCatcherLogo}></img>
              </Col>
              <Row className="social-media">
                <Col className="brand-logo" padding="sm">
                  <i className="fa-brands fa-square-facebook"></i>
                </Col>
                <Col className="brand-logo" padding="sm">
                  <i className="fa-brands fa-instagram"></i>
                </Col>
                <Col className="brand-logo" padding="sm">
                  <i className="fa-brands fa-twitter"></i>
                </Col>
              </Row>
              {ViewResolver({
                viewModes: [["Default"], ["Mobile-Portrait"]],
                resolvedContent: [LinksView, LinksCollapseView],
              })}
              <Col alignHorizontal="center">
                <Label className="cp usage">
                  &#169;{new Date().getFullYear()} AppCatcher LTD.
                </Label>
                <Label className="cp usage">All rights reserved</Label>
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>
    </Row>
  );
};
