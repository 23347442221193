import "@/assets/styles/HomebuyerLanding/homebuyerLanding.css";
import { Button, Col, Icon, Label, Row } from "@/components/basic";
import realEstateAgentImage from "@/assets/images/RealEstateAgent.png";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import { Application } from "@/data/payload";
import { useMemo } from "react";
import { constFalse, pipe } from "fp-ts/lib/function";
import { DocumentStatus } from "@/data/applicationDocument";
import { SurveyProgress } from "@/components/SurveyProgress";

export type Props = {
  application: O.Option<Application>;
  completeApplication: () => void;
};

export function View(props: Props): JSX.Element {
  const applicationCompleted = useMemo(
    () =>
      pipe(
        props.application,
        O.fold(constFalse, (application) =>
          pipe(
            application.requiredDocuments,
            A.filter(
              (doc) =>
                doc.isHardRequirement &&
                (doc.status === DocumentStatus.UploadRequired ||
                  doc.status === DocumentStatus.Processing),
            ),
            A.isEmpty,
          ),
        ),
      ),
    [props.application],
  );
  return (
    <Col padding="sm" gap="sm" grow={1} background="grey10">
      <Row
        padding="lg"
        gap="lg"
        className="container"
        alignHorizontal="space-between"
      >
        {applicationCompleted ? (
          <Col
            gap="xs"
            alignHorizontal="stretch"
            className="survey-information"
          >
            <h1>Congratulations! Your application is complete.</h1>
            <span>
              Thanks for completing your application. Hang tight - You’re loan’s
              now in process and your loan officer will be in contact with you
              soon.
            </span>
            <Button type="secondary" onClick={O.none}>
              View application
            </Button>
          </Col>
        ) : (
          <Col
            gap="xs"
            alignHorizontal="stretch"
            className="survey-information"
          >
            <h1>One more step left!</h1>
            <SurveyProgress
              personal="Completed"
              financial="Completed"
              mortgage="Completed"
              verification="Completed"
              documentation="InProgress"
            />
            <span>
              You're almost there! To complete your pre-approval process, we
              need you to upload your required documents within 24 hours. If you
              have any questions, feel free to reach out to your loan officer.
            </span>
            <Col alignHorizontal="left">
              <Button
                type="primary"
                onClick={O.some(props.completeApplication)}
              >
                Complete application
              </Button>
            </Col>
          </Col>
        )}
        <img src={realEstateAgentImage} alt="Real Estate Agent" />
      </Row>
      <Row padding="md" gap="md" className="container">
        <Col gap="xs">
          <Label>Do</Label>
          <AdviseItem
            goodAdvice={true}
            text="Borrow only what you can afford to repay."
          />
          <AdviseItem
            goodAdvice={true}
            text="Make all of your payments on time."
          />
          <AdviseItem
            goodAdvice={true}
            text="Avoid excessive requests or inquiries for credit."
          />
          <AdviseItem
            goodAdvice={true}
            text="Have an emergency account to pay for unexpected expenses."
          />
          <AdviseItem
            goodAdvice={true}
            text="Check your report annually to contest and remove any erroneous information."
          />
        </Col>
        <Col gap="xs">
          <Label>Don't</Label>
          <AdviseItem
            goodAdvice={false}
            text="Don't make large purchases such as cars, furniture, luxury items, etc."
          />
          <AdviseItem
            goodAdvice={false}
            text="Don't close any revolving credit accounts."
          />
          <AdviseItem
            goodAdvice={false}
            text="Don't run up the balances on your exisiting credit cards."
          />
          <AdviseItem
            goodAdvice={false}
            text="Don't acquire any non-sufficient funds or overdraft fees."
          />
          <AdviseItem
            goodAdvice={false}
            text="Don't change jobs, resign, or become self-employed."
          />
        </Col>
      </Row>
    </Col>
  );
}

type AdviseItemProps = {
  text: string;
  goodAdvice: boolean;
};

function AdviseItem({ text, goodAdvice }: AdviseItemProps): JSX.Element {
  return (
    <Row gap="xs">
      <div className={goodAdvice ? "advice-good" : "advice-bad"}>
        <Icon type={goodAdvice ? "circle-check" : "circle-xmark"} />
      </div>
      <span>{text}</span>
    </Row>
  );
}
