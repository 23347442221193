import * as EditUser from "@/components/UserManagement/EditUser";
import {
  Model,
  NewUserDialog,
  EditUserDialog,
  DeleteUserDialog,
} from "./model";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import { BranchId, TeamId, User, UserId } from "@/data/client";
import { Option } from "fp-ts/lib/Option";

export type Action =
  | {
      type: "NewUserInitiated";
      teamId: Option<TeamId>;
      branchId: Option<BranchId>;
    }
  | {
      type: "EditUserInitiated";
      user: User;
    }
  | {
      type: "DeleteUserInitiated";
      userId: UserId;
    }
  | {
      type: "ReassignToChanged";
      userId: UserId;
    }
  | {
      type: "DialogClosed";
    }
  | {
      type: "EditUserAction";
      action: EditUser.Action;
    };

export const NewUserInitiated = (
  branchId: Option<BranchId>,
  teamId: Option<TeamId>,
): Action => ({
  type: "NewUserInitiated",
  branchId,
  teamId,
});

export const EditUserInitiated = (user: User): Action => ({
  type: "EditUserInitiated",
  user,
});

export const DeleteUserInitiated = (userId: UserId): Action => ({
  type: "DeleteUserInitiated",
  userId,
});

export const ReassignToChanged = (userId: UserId): Action => ({
  type: "ReassignToChanged",
  userId,
});

export const DialogClosed = (): Action => ({
  type: "DialogClosed",
});

export const EditUserAction = (action: EditUser.Action): Action => ({
  type: "EditUserAction",
  action,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "NewUserInitiated":
      return {
        ...model,
        dialog: pipe(
          EditUser.init(action.branchId)(action.teamId),
          NewUserDialog,
          O.some,
        ),
      };

    case "EditUserInitiated":
      return {
        ...model,
        dialog: pipe(
          EditUser.initEdit(action.user),
          EditUserDialog(action.user.userId),
          O.some,
        ),
      };

    case "DeleteUserInitiated":
      return {
        ...model,
        dialog: O.some(DeleteUserDialog(action.userId, O.none)),
      };

    case "ReassignToChanged":
      if (
        O.isNone(model.dialog) ||
        model.dialog.value.type !== "DeleteUserDialog"
      ) {
        return model;
      }

      return {
        ...model,
        dialog: O.some({
          ...model.dialog.value,
          reassignTo: O.some(action.userId),
        }),
      };

    case "DialogClosed":
      return { ...model, dialog: O.none };

    case "EditUserAction":
      if (
        O.isNone(model.dialog) ||
        model.dialog.value.type === "DeleteUserDialog"
      ) {
        return model;
      }

      return {
        ...model,
        dialog: O.some({
          ...model.dialog.value,
          dialogModel: EditUser.update(action.action)(
            model.dialog.value.dialogModel,
          ),
        }),
      };
  }
};
