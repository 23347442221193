import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { Button } from "../Button";
import { Col } from "../Col";
import { Icon } from "../Icon";
import { Row } from "../Row";
import "@/assets/styles/basic/Modal/modal.css";

export type ModalProps = {
  children: React.ReactNode;
  onClose: O.Option<() => void>;
  title: O.Option<string>;
};

export function Modal(props: ModalProps): JSX.Element {
  const shouldShowCloseButton = pipe(
    O.isSome(props.onClose),
    O.fromPredicate((onClose) => onClose || O.isSome(props.title)),
  );
  return (
    <Col
      className="modal-dialog"
      alignVertical="center"
      alignHorizontal="center"
    >
      <Col className="modal-content">
        {pipe(
          shouldShowCloseButton,
          O.fold(
            () => <>{props.children}</>,
            () => (
              <Col>
                <Row
                  alignHorizontal="space-between"
                  grow={1}
                  background="action70"
                  padding="xs"
                >
                  <p className="modal-title">
                    {pipe(
                      props.title,
                      O.getOrElse(() => "AppCatcher"),
                    )}
                  </p>
                  <Col padding="xs">
                    {pipe(
                      props.onClose,
                      O.fold(
                        () => <></>,
                        () => (
                          <Button
                            type="inline-inverted"
                            onClick={props.onClose}
                          >
                            <Icon type="xmark" />
                          </Button>
                        ),
                      ),
                    )}
                  </Col>
                </Row>
                <Col padding="xs">{props.children}</Col>
              </Col>
            ),
          ),
        )}
      </Col>
    </Col>
  );
}
