import {
  ApplicationListItem,
  EqApplicationStatus,
} from "@/data/applicationsList";
import { ApplicationId, ApplicationStatusType, ApplicationType, EqApplicationType } from "@/data/payload";
import { Option, none } from "fp-ts/lib/Option";
import { Eq as EqString } from "fp-ts/lib/string";
import { Eq } from "fp-ts/lib/Eq";
import * as O from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { DateTime } from "luxon";
import { ClientStatusResponse, EqUserId, UserId } from "@/data/client";
import { Deferred, NotStarted } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";

export enum SubmissionDateFilterOption {
  Last24Hours = "Last 24 hours",
  LastWeek = "Last week",
  Last2Weeks = "Last 2 weeks",
  LastMonth = "Last month",
}

export const EqSubmissionDateFilterOption: Eq<SubmissionDateFilterOption> =
  EqString;

export type Model = {
  applications: ApplicationListItem[];
  search: string;
  statusFilter: Option<Set<ApplicationStatusType>>;
  creditCheckAuthorizedFilter: Option<boolean>;
  mortgageTypeFilter: Option<Set<ApplicationType>>;
  submissionDateFilter: Option<SubmissionDateFilterOption>;
  assigneeFilter: Option<Set<UserId>>;
  exportStatus: Deferred<ApiResult<string>>;
  exportWarningShown: O.Option<[boolean, ApplicationId]>

  clientStatus: O.Option<ClientStatusResponse>,

};

export const init = (applications: ApplicationListItem[], clientStatus: O.Option<ClientStatusResponse>): Model => ({
  applications,
  search: "",
  statusFilter: none,
  creditCheckAuthorizedFilter: none,
  mortgageTypeFilter: none,
  submissionDateFilter: none,
  assigneeFilter: none,
  exportStatus: NotStarted(),
  exportWarningShown: O.none,
  clientStatus

});

export const applySearch =
  (search: string) => (applications: ApplicationListItem[]) =>
    search.length > 0
      ? pipe(
          applications,
          A.filter((app) =>
            app.applicantsName.toLowerCase().includes(search.toLowerCase()),
          ),
        )
      : applications;

export const applyStatusFilter =
  (statusFilter: Option<Set<ApplicationStatusType>>) =>
  (applications: ApplicationListItem[]) =>
    O.isSome(statusFilter)
      ? pipe(
          applications,
          A.filter((app) =>
            pipe(statusFilter.value, S.elem(EqApplicationStatus)(app.status)),
          ),
        )
      : applications;

export const applyCreditCheckAuthorizedFilter =
  (creditCheckAuthorizedFilter: Option<boolean>) =>
  (applications: ApplicationListItem[]) =>
    O.isSome(creditCheckAuthorizedFilter)
      ? pipe(
          applications,
          A.filter(
            (app) =>
              app.isCreditCheckAuthorized === creditCheckAuthorizedFilter.value,
          ),
        )
      : applications;

export const applyMortgageTypeFilter =
  (mortgageTypeFilter: Option<Set<ApplicationType>>) =>
  (applications: ApplicationListItem[]) =>
    O.isSome(mortgageTypeFilter)
      ? pipe(
          applications,
          A.filter((app) =>
            pipe(
              mortgageTypeFilter.value,
              S.elem(EqApplicationType)(app.mortgageType),
            ),
          ),
        )
      : applications;

export const applySubmissionDateFilter =
  (submissionDateFilter: Option<SubmissionDateFilterOption>) =>
  (applications: ApplicationListItem[]) => {
    if (O.isNone(submissionDateFilter)) {
      return applications;
    }

    switch (submissionDateFilter.value) {
      case SubmissionDateFilterOption.Last24Hours:
        return pipe(
          applications,
          A.filter(
            (app) => app.dateSubmitted >= DateTime.now().minus({ day: 1 }),
          ),
        );
      case SubmissionDateFilterOption.LastWeek:
        return pipe(
          applications,
          A.filter(
            (app) => app.dateSubmitted >= DateTime.now().minus({ week: 1 }),
          ),
        );
      case SubmissionDateFilterOption.Last2Weeks:
        return pipe(
          applications,
          A.filter(
            (app) => app.dateSubmitted >= DateTime.now().minus({ weeks: 2 }),
          ),
        );
      case SubmissionDateFilterOption.LastMonth:
        return pipe(
          applications,
          A.filter(
            (app) => app.dateSubmitted >= DateTime.now().minus({ month: 1 }),
          ),
        );
    }
  };

export const applyAssigneeFilter =
  (assigneeFilter: Option<Set<UserId>>) =>
  (applications: ApplicationListItem[]) =>
    O.isSome(assigneeFilter)
      ? pipe(
          applications,
          A.filter((app) =>
            pipe(
              app.assignees,
              A.map(({ userId }) => userId),
              S.fromArray(EqUserId),
              S.intersection(EqUserId)(assigneeFilter.value),
              (s) => S.size(s) > 0,
            ),
          ),
        )
      : applications;
