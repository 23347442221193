import { ChildProps } from "@/utils/reducerWithEffect";
import { Model, result } from "./model";
import { Action, BranchNameChanged } from "./action";
import { Button, Col, InputField, Row } from "@/components/basic";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { useMemo } from "react";
import { BranchPayload } from "@/data/payload";

export type Props = ChildProps<Model, Action> & {
  onSave: (payload: BranchPayload) => void;
  onCancel: () => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const saveBranch = useMemo(
    () =>
      pipe(
        result(props.model),
        O.fromEither,
        O.map((payload) => () => props.onSave(payload)),
      ),
    [props],
  );

  return (
    <Col padding="xs" gap="xs">
      <InputField
        label="Name"
        field={model.branchName}
        onChange={flow(BranchNameChanged, dispatch)}
      />

      <Row padding="xs" gap="xs" alignHorizontal="center">
        <Button type="primary" onClick={saveBranch}>
          Create
        </Button>
        <Button type="secondary" onClick={O.some(props.onCancel)}>
          Cancel
        </Button>
      </Row>
    </Col>
  );
}
