import { ChildProps } from "@/utils/reducerWithEffect";
import { Model, Page } from "./model";
import {
  Action,
  BranchSelected,
  GetCurrentClient,
  UserPayloadPrepared,
  TeamManagementAction,
  TeamSelected,
  UserSelected,
  DeleteUserConfirmed,
  BranchManagementAction,
  TeamPayloadPrepared,
  DeleteTeamConfirmed,
  ClientManagementAction,
  BranchPayloadPrepared,
  DeleteBranchConfirmed,
} from "./action";
import { Button, Col, Label, Row, Table } from "@/components/basic";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import { Started } from "@/utils/asyncOperationStatus";
import { ApiResult, showApiError } from "@/utils/request";
import {
  Branch,
  BranchId,
  Client,
  EqBranchId,
  EqTeamId,
  EqUserId,
  Team,
  TeamId,
  User,
  UserId,
} from "@/data/client";
import { useCallback, useEffect, useMemo } from "react";
import * as A from "fp-ts/Array";
import * as M from "fp-ts/Map";
import * as SG from "fp-ts/Semigroup";
import { ApplicationListItem } from "@/data/applicationsList";
import { Deferred } from "@/utils/deferred";
import { DateTime } from "luxon";
import { nesHead } from "@/utils/codecs";
import * as TeamManagement from "@/components/UserManagement/TeamManagement";
import * as BranchManagement from "@/components/UserManagement/BranchManagement";
import * as ClientManagement from "@/components/UserManagement/ClientManagement";
import { UserRoles } from "@/components/UserManagement/UserRoles";
import { Route, Switch, useLocation } from "wouter";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  switch (model.client.status) {
    case "NotStarted":
      return (
        <Button
          type="primary"
          onClick={O.some(flow(Started, GetCurrentClient, dispatch))}
        >
          Load
        </Button>
      );

    case "InProgress":
    case "Updating":
      return <div>Loading...</div>;

    case "Resolved":
      return pipe(
        model.client.value,
        E.fold(
          (error) => <div>Error: {showApiError(error)}</div>,
          (client) => (
            <PageView client={client} page={model.page} dispatch={dispatch} />
          ),
        ),
      );
  }
}

type PageViewProps = {
  client: Client;
  page: Page;
  dispatch: (action: Action) => void;
};

function PageView(props: PageViewProps): JSX.Element {
  const [_, setLocation] = useLocation();

  const { page } = props;

  useEffect(() => {
    switch (page.type) {
      case "Client":
        setLocation("/client");
        break;

      case "Branch":
        setLocation(`/branch/${page.model.branchId}`);
        break;

      case "Team":
        setLocation(`/team/${page.model.teamId}`);
        break;

      case "User":
        setLocation(`/user/${page.user.userId}`);
        break;
    }
  }, [page, setLocation]);

  const branches: Map<BranchId, Branch> = useMemo(
    () =>
      pipe(
        props.client.branches,
        A.map((branch): [BranchId, Branch] => [branch.branchId, branch]),
        M.fromFoldable(EqBranchId, SG.last<Branch>(), A.Foldable),
      ),
    [props.client.branches],
  );

  const teams: Map<TeamId, Team> = useMemo(
    () =>
      pipe(
        props.client.branches,
        A.chain(({ teams }) => teams),
        A.map((team): [TeamId, Team] => [team.teamId, team]),
        M.fromFoldable(EqTeamId, SG.last<Team>(), A.Foldable),
      ),
    [props.client.branches],
  );

  const users: Map<UserId, User> = useMemo(
    () =>
      pipe(
        props.client.branches,
        A.chain((branch) =>
          pipe(
            branch.teams,
            A.chain(({ users }) => users),
            A.concat(branch.users),
          ),
        ),
        A.concat(props.client.users),
        A.map((user): [UserId, User] => [user.userId, user]),
        M.fromFoldable(EqUserId, SG.last<User>(), A.Foldable),
      ),
    [props.client],
  );

  const branchName = useCallback(
    (branchId: BranchId) =>
      pipe(
        M.lookup(EqBranchId)(branchId, branches),
        O.map((branch) => branch.name),
      ),
    [branches],
  );

  const teamName = useMemo(
    () => (teamId: TeamId) =>
      pipe(
        M.lookup(EqTeamId)(teamId, teams),
        O.map((team) => team.name),
      ),
    [teams],
  );

  return (
    <Switch>
      {page.type == "Client" && (
        <Route path="/client">
          <ClientManagement.View
            client={props.client}
            model={page.model}
            dispatch={flow(ClientManagementAction, props.dispatch)}
            onSaveBranch={flow(
              BranchPayloadPrepared(props.client.clientId),
              props.dispatch,
            )}
            onDeleteBranch={flow(DeleteBranchConfirmed, props.dispatch)}
            onSelectBranch={flow(BranchSelected, props.dispatch)}
            onSelectTeam={flow(TeamSelected, props.dispatch)}
          />
        </Route>
      )}

      {page.type == "Branch" && (
        <Route path="/branch/:branchId">
          <BranchManagement.View
            branches={branches}
            model={page.model}
            dispatch={flow(BranchManagementAction, props.dispatch)}
            onSelectTeam={flow(TeamSelected, props.dispatch)}
            onSaveTeam={flow(TeamPayloadPrepared, props.dispatch)}
            onDeleteTeam={flow(DeleteTeamConfirmed, props.dispatch)}
          />
        </Route>
      )}
      {page.type == "Team" && (
        <Route path="/team/:teamId">
          <TeamManagement.View
            branches={branches}
            teams={teams}
            users={users}
            model={page.model}
            dispatch={flow(TeamManagementAction, props.dispatch)}
            onSelectUser={flow(UserSelected, props.dispatch)}
            onSaveUser={flow(UserPayloadPrepared, props.dispatch)}
            onDeleteUser={flow(DeleteUserConfirmed, props.dispatch)}
          />
        </Route>
      )}
      {page.type == "User" && (
        <Route path="/user/:userId">
          <UserView
            user={page.user}
            applications={page.applications}
            branchName={branchName}
            teamName={teamName}
            dispatch={props.dispatch}
          />
        </Route>
      )}
    </Switch>
  );
}

type UserViewProps = {
  user: User;
  applications: Deferred<ApiResult<ApplicationListItem[]>>;
  branchName: (branchId: BranchId) => Option<string>;
  teamName: (teamId: TeamId) => Option<string>;
  dispatch: (action: Action) => void;
};

function UserView(props: UserViewProps): JSX.Element {
  const branchName = useMemo(
    () => pipe(props.user.branchId, O.chain(props.branchName)),
    [props.branchName, props.user.branchId],
  );

  const teamName = useMemo(
    () => pipe(props.user.teamId, O.chain(props.teamName)),
    [props.teamName, props.user.teamId],
  );

  return (
    <Col padding="sm" grow={1} alignHorizontal="stretch">
      <Row padding="sm" alignVertical="center" alignHorizontal="space-between">
        <Label>
          <b>
            {pipe(
              O.of(
                (firstName: string) => (lastName: string) =>
                  `${firstName} ${lastName}`,
              ),
              O.ap(props.user.firstName),
              O.ap(props.user.lastName),
              O.getOrElseW(() => props.user.email),
            )}
          </b>
        </Label>
        <Row gap="sm">
          <Button type="primary" onClick={O.none}>
            Edit user
          </Button>
          <Button type="tertiary" onClick={O.none}>
            Delete user
          </Button>
        </Row>
      </Row>
      <Col padding="xs" className="container">
        <Table
          data={[props.user]}
          itemKey={(user) => user.userId}
          columns={[
            {
              columnKey: "branch",
              header: (
                <Row padding="sm">
                  <Label>Branch</Label>
                </Row>
              ),
              view: () => (
                <Row padding="sm">
                  <Label>
                    {pipe(branchName, O.fold(constant("_"), identity))}
                  </Label>
                </Row>
              ),
            },
            {
              columnKey: "team",
              header: (
                <Row padding="sm">
                  <Label>Team</Label>
                </Row>
              ),
              view: () => (
                <Row padding="sm">
                  <Label>
                    {pipe(teamName, O.fold(constant("_"), identity))}
                  </Label>
                </Row>
              ),
            },
            {
              columnKey: "email",
              header: (
                <Row padding="sm">
                  <Label>Email</Label>
                </Row>
              ),
              view: (user) => (
                <Row padding="sm">
                  <Label>{user.email}</Label>
                </Row>
              ),
            },
            {
              columnKey: "roles",
              header: (
                <Row padding="sm">
                  <Label>Roles</Label>
                </Row>
              ),
              view: (user) => (
                <Row padding="sm">
                  <UserRoles user={user} />
                </Row>
              ),
            },
            {
              columnKey: "userId",
              header: (
                <Row padding="sm">
                  <Label>ID</Label>
                </Row>
              ),
              view: (user) => (
                <Row padding="sm">
                  <Label>{user.userId}</Label>
                </Row>
              ),
            },
          ]}
        />
      </Col>
      <UserApplicationsTable
        applications={props.applications}
        dispatch={props.dispatch}
      />
    </Col>
  );
}

type UserApplicationsTableProps = {
  applications: Deferred<ApiResult<ApplicationListItem[]>>;
  dispatch: (action: Action) => void;
};

function UserApplicationsTable(props: UserApplicationsTableProps): JSX.Element {
  switch (props.applications.status) {
    case "NotStarted":
      return <></>;
    case "InProgress":
    case "Updating":
      return <Label>Loading...</Label>;
    case "Resolved":
      return pipe(
        props.applications.value,
        E.fold(
          (_) => <Label>Failed to load clients list</Label>,
          (applications) => (
            <Table
              className="data-table"
              data={applications}
              itemKey={(app) => app.applicationId}
              columns={[
                {
                  columnKey: "client",
                  header: (
                    <Row padding="sm">
                      <Label>Client</Label>
                    </Row>
                  ),
                  view: (app) => (
                    <Col
                      padding="sm"
                      gap="xl"
                      className="data-table-cell cell-first"
                    >
                      <Label>{app.applicantsName}</Label>
                    </Col>
                  ),
                },
                {
                  columnKey: "status",
                  header: (
                    <Row padding="sm">
                      <Label>Status</Label>
                    </Row>
                  ),
                  view: (app) => (
                    <Col padding="sm" gap="xl" className="data-table-cell">
                      <Label>{app.status}</Label>
                    </Col>
                  ),
                },
                {
                  columnKey: "creditCheck",
                  header: (
                    <Row padding="sm">
                      <Label>Credit check</Label>
                    </Row>
                  ),
                  view: (app) => (
                    <Col padding="sm" gap="xl" className="data-table-cell">
                      <Label>
                        {app.isCreditCheckAuthorized ? "Authorized" : "-"}
                      </Label>
                    </Col>
                  ),
                },
                {
                  columnKey: "mortgageType",
                  header: (
                    <Row padding="sm">
                      <Label>Mortgage type</Label>
                    </Row>
                  ),
                  view: (app) => (
                    <Col padding="sm" gap="xl" className="data-table-cell">
                      <Label>{app.mortgageType}</Label>
                    </Col>
                  ),
                },
                {
                  columnKey: "submitted",
                  header: (
                    <Row padding="sm">
                      <Label>Submitted</Label>
                    </Row>
                  ),
                  view: (app) => (
                    <Col padding="sm" gap="xl" className="data-table-cell">
                      <Label>
                        {app.dateSubmitted.toLocaleString(DateTime.DATE_MED)}
                      </Label>
                    </Col>
                  ),
                },
                {
                  columnKey: "assignees",
                  header: (
                    <Row padding="sm">
                      <Label>Assignees</Label>
                    </Row>
                  ),
                  view: (app) => (
                    <Col
                      padding="sm"
                      gap="xl"
                      className="data-table-cell cell-last"
                    >
                      <Label>
                        {app.assignees
                          .map(
                            (assignee) =>
                              `${nesHead(assignee.firstName)} ${
                                assignee.lastName
                              }`,
                          )
                          .join(", ")}
                      </Label>
                    </Col>
                  ),
                },
              ]}
            />
          ),
        ),
      );
  }
}
