import * as Signup from '@/components/SignUp';
import * as O from 'fp-ts/Option';
import { Action } from "./action";

export type Model = {
  title: string;
  welcomeMessage: string;
  signupModel: O.Option<Signup.Model>
};

export const initHomePageModel = (): Model => ({
  title: "Welcome to the AppCatcher",
  welcomeMessage: "Start managing your applications now",
  signupModel: O.none,

});

export type HomePageProps = {
  model: Model;
  dispatch: (action: Action) => void;
  onSignup: (email: string, phoneNo: string) => void
};
