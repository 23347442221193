import { CreateClientPayload } from "@/data/payload";
import { Api } from "@/utils/api";
import { AsyncOperationStatus, Finished } from "@/utils/asyncOperationStatus";
import { emailStringCodec, nonEmptyStringCodec } from "@/utils/codecs";
import { phoneNumberCodec } from "@/utils/codecs/phoneNumber";
import { InProgress, Resolved } from "@/utils/deferred";
import { updateFormField } from "@/utils/formField";
import {
  Effect,
  effectOfAsync,
  effectOfFunc_,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiError, ApiResult, ExceptionError } from "@/utils/request";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import * as T from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import { Model, PaymentSetupInfo } from "./model";

export const loadStripeTask: TE.TaskEither<ApiError, Stripe> = pipe(
  () => loadStripe(import.meta.env.VITE_STRIPE_TOKEN),
  T.map(E.fromNullable(ExceptionError("Failed to initialize Stripe"))),
);

export type Action =
  | {
      type: "FirstNameChanged";
      firstName: string;
    }
  | {
      type: "LastNameChanged";
      lastName: string;
    }
  | {
      type: "EmailChanged";
      email: string;
    }
  | {
      type: "PhoneChanged";
      phone: string;
    }
  | {
      type: "CompanyNameChanged";
      companyName: string;
    }
  | {
      type: "WebsiteUrlChanged";
      websiteUrl: string;
    }
  | {
      type: "CompanyTypeChanged";
      companyType: string;
    }
  | {
      type: "HeadCountChanged";
      headCount: string;
    }
  | {
      type: "SubdomainChanged";
      subdomain: string;
    }
  | {
      type: "CompanyNMLSChanged";
      companyNMLS: string;
    }
  | {
      type: "LoanOfficerNMLSChanged";
      loanOfficerNMLS: string;
    }
  | {
      type: "CreateClient";
      payload: CreateClientPayload;
      operation: AsyncOperationStatus<ApiResult<PaymentSetupInfo>>;
    }
 

export const FirstNameChanged = (firstName: string): Action => ({
  type: "FirstNameChanged",
  firstName,
});

export const LastNameChanged = (lastName: string): Action => ({
  type: "LastNameChanged",
  lastName,
});

export const EmailChanged = (email: string): Action => ({
  type: "EmailChanged",
  email,
});

export const PhoneChanged = (phone: string): Action => ({
  type: "PhoneChanged",
  phone,
});

export const CompanyNameChanged = (companyName: string): Action => ({
  type: "CompanyNameChanged",
  companyName,
});

export const WebsiteUrlChanged = (websiteUrl: string): Action => ({
  type: "WebsiteUrlChanged",
  websiteUrl,
});

export const CompanyTypeChanged = (companyType: string): Action => ({
  type: "CompanyTypeChanged",
  companyType,
});

export const HeadCountChanged = (headCount: string): Action => ({
  type: "HeadCountChanged",
  headCount,
});

export const SubdomainChanged = (subdomain: string): Action => ({
  type: "SubdomainChanged",
  subdomain,
});

export const CompanyNMLSChanged = (companyNMLS: string): Action => ({
  type: "CompanyNMLSChanged",
  companyNMLS,
});

export const LoanOfficerNMLSChanged = (loanOfficerNMLS: string): Action => ({
  type: "LoanOfficerNMLSChanged",
  loanOfficerNMLS,
});


export const CreateClient =
  (payload: CreateClientPayload) =>
  (operation: AsyncOperationStatus<ApiResult<PaymentSetupInfo>>): Action => ({
    type: "CreateClient",
    payload,
    operation,
  });

export const update =
  (api: Api) =>
  (action: Action) =>
  (model: Model): [Model, Effect<Action>] => {
    switch (action.type) {
      case "FirstNameChanged":
        return [
          {
            ...model,
            firstName: updateFormField(nonEmptyStringCodec().decode)(
              action.firstName,
            ),
          },
          noEffect,
        ];
      case "LastNameChanged":
        return [
          {
            ...model,
            lastName: updateFormField(nonEmptyStringCodec().decode)(
              action.lastName,
            ),
          },
          noEffect,
        ];
      case "EmailChanged":
        return [
          {
            ...model,
            email: updateFormField(emailStringCodec.decode)(action.email),
          },
          noEffect,
        ];
      case "PhoneChanged":
        return [
          {
            ...model,
            phone: updateFormField(phoneNumberCodec.decode)(action.phone),
          },
          noEffect,
        ];
      case "CompanyNameChanged":
        return [
          {
            ...model,
            companyName: updateFormField(nonEmptyStringCodec().decode)(
              action.companyName,
            ),
          },
          noEffect,
        ];
      case "WebsiteUrlChanged":
        return [
          {
            ...model,

            websiteUrl: updateFormField(nonEmptyStringCodec().decode)(
              action.websiteUrl,
            ),
          },
          noEffect,
        ];

      case "CompanyTypeChanged":
        return [
          {
            ...model,
            companyType: updateFormField(nonEmptyStringCodec().decode)(
              action.companyType,
            ),
          },
          noEffect,
        ];

      case "HeadCountChanged":
        return [
          {
            ...model,
            headCount: updateFormField(nonEmptyStringCodec().decode)(
              action.headCount,
            ),
          },
          noEffect,
        ];

      case "SubdomainChanged":
        return [
          {
            ...model,
            subdomain: updateFormField(nonEmptyStringCodec().decode)(
              action.subdomain,
            ),
          },
          noEffect,
        ];

      case "CompanyNMLSChanged":
        return [
          {
            ...model,
            companyNMLS: updateFormField(nonEmptyStringCodec().decode)(
              action.companyNMLS,
            ),
          },
          noEffect,
        ];

      case "LoanOfficerNMLSChanged":
        return [
          {
            ...model,
            loanOfficerNMLS: updateFormField(nonEmptyStringCodec().decode)(
              action.loanOfficerNMLS,
            ),
          },
          noEffect,
        ];
      case "CreateClient":
        switch (action.operation.status) {
          case "Started":
            return [
              { ...model, createClientResponse: InProgress() },
              effectOfAsync(
                pipe(
                  TE.of(PaymentSetupInfo),
                  TE.ap(
                    pipe(
                      api.createClient(action.payload),
                      TE.map(
                        ({ createPaymentCustomerSubscriptionResult }) =>
                          createPaymentCustomerSubscriptionResult.paymentMethodSetupClientSecret,
                      ),
                    ),
                  ),
                  TE.ap(loadStripeTask),
                ),
                flow(Finished, CreateClient(action.payload)),
              ),
            ];

          case "Finished":
            return [
              {
                ...model,
                createClientResponse: Resolved(action.operation.result),
              },
              pipe(
                action.operation.result,
                E.fold(
                  (err) =>
                    effectOfFunc_(() => {
                      console.error(err);
                      alert("failed to create client");
                    }, undefined),
                  (_) => noEffect,
                ),
              ),
            ];
        }
    }
  };
