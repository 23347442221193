import { CreateClientPayload } from "@/data/payload";
import {
  EmailString,
  emailStringCodec,
  nonEmptyStringCodec,
} from "@/utils/codecs";
import { FormField, initFormField } from "@/utils/formField";
import { NonEmptyString } from "io-ts-types";
import { Validation } from "io-ts";
import { sequenceS } from "fp-ts/lib/Apply";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { Deferred, NotStarted } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import { Stripe } from "@stripe/stripe-js";
import { PhoneNumber, phoneNumberCodec } from "@/utils/codecs/phoneNumber";
import { User } from "@/data/client";

export type PaymentSetupInfo = {
  stripe: Stripe;
  clientSecret: NonEmptyString;
};

export const PaymentSetupInfo =
  (clientSecret: NonEmptyString) => (stripe: Stripe) => ({
    stripe,
    clientSecret,
  });

export type Model = {
  firstName: FormField<NonEmptyString>;
  lastName: FormField<NonEmptyString>;
  email: FormField<EmailString>;
  phone: FormField<PhoneNumber>;
  companyName: FormField<NonEmptyString>;
  websiteUrl: FormField<NonEmptyString>;
  companyType: FormField<NonEmptyString>;
  headCount: FormField<NonEmptyString>;
  subdomain: FormField<NonEmptyString>;
  companyNMLS: FormField<NonEmptyString>;
  loanOfficerNMLS: FormField<NonEmptyString>;
  createClientResponse: Deferred<ApiResult<PaymentSetupInfo>>;

  user: O.Option<User>
};

export const init = (user: O.Option<User>): Model => ({
  firstName: initFormField(nonEmptyStringCodec().decode)(""),
  lastName: initFormField(nonEmptyStringCodec().decode)(""),
  email: initFormField(emailStringCodec.decode)(""),
  phone: initFormField(phoneNumberCodec.decode)(""),
  companyName: initFormField(nonEmptyStringCodec().decode)(""),
  websiteUrl: initFormField(nonEmptyStringCodec().decode)(""),
  companyType: initFormField(nonEmptyStringCodec().decode)(""),
  headCount: initFormField(nonEmptyStringCodec().decode)(""),
  subdomain: initFormField(nonEmptyStringCodec().decode)(""),
  companyNMLS: initFormField(nonEmptyStringCodec().decode)(""),
  loanOfficerNMLS: initFormField(nonEmptyStringCodec().decode)(""),
  createClientResponse: NotStarted(),

  user
});

export const result = (model: Model): Validation<CreateClientPayload> =>
  sequenceS(E.Apply)({
    firstName: model.firstName.val,
    lastName: model.lastName.val,
    email: model.email.val,
    phone: model.phone.val,
    companyName: model.companyName.val,
    websiteUrl: model.websiteUrl.val,
    companyType: model.companyType.val,
    headCount: model.headCount.val,
    subdomain: model.subdomain.val,
    companyNMLS: model.companyNMLS.val,
    loanOfficerNMLS: model.loanOfficerNMLS.val,
  });
