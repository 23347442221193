import {
  confirmSocialSecurityNumberCodec,
  isoDateCodec,
  socialSecurityNumberCodec,
  stringEnumCodec,
} from "@/utils/codecs";
import { trueCodec } from "@/utils/codecs/true";
import * as t from "io-ts";
import * as tt from "io-ts-types";

interface ConsentVersionIdBrand {
  readonly ConsentVersionId: unique symbol;
}

export const consentVersionIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, ConsentVersionIdBrand> => true,
  "ConsentVersionId",
);

export type ConsentVersionId = t.TypeOf<typeof consentVersionIdCodec>;

export enum ConsentMethods {
  None = "none",
  Email = "email",
  FaceToFace = "face-to-face",
  Fax = "fax",
  Mail = "mail",
  Telephone = "telephone",
}

export const consentMethodCodec = stringEnumCodec<typeof ConsentMethods>(
  ConsentMethods,
  "ConsentMethods",
);

export const someConsentMethodCodec = new t.Type<
  ConsentMethods,
  string,
  string
>(
  "SomeConsentMethod",
  consentMethodCodec.is,
  (u, c) => {
    if (u == ConsentMethods.None) {
      return t.failure(u, c, "Please choose a valid value");
    }

    return consentMethodCodec.is(u) ? t.success(u) : t.failure(u, c, "Invalid");
  },
  showConsentMethods,
);

export const consentMethodOptions: ConsentMethods[] = [
  ConsentMethods.None,
  ConsentMethods.Email,
  ConsentMethods.FaceToFace,
  ConsentMethods.Fax,
  ConsentMethods.Mail,
  ConsentMethods.Telephone,
];

export function showConsentMethods(consentMethods: ConsentMethods): string {
  return consentMethods.toString().replace(/(.)(.*)/, function (_, h, t) {
    return h.toUpperCase() + t;
  });
}

export const consentCodec = t.type({
  consentVersionId: consentVersionIdCodec,
  title: tt.NonEmptyString,
  content: tt.NonEmptyString,
  requiredForBorrower: t.boolean,
});

export type ConsentItem = t.TypeOf<typeof consentCodec>;

export const surveyConsentCodec = t.type({
  consentVersionId: consentVersionIdCodec,
  method: t.union([t.null, t.string.pipe(consentMethodCodec)]),
  consentProvided: t.boolean,
});

export const surveyConsentFormCodec = t.type({
  consentVersionId: consentVersionIdCodec,
  method: t.union([t.string.pipe(consentMethodCodec), t.null]),
  consentProvided: trueCodec,
  requiredForBorrower: t.boolean,
});

export const surveyConsentItemsArrayCodec = t.array(surveyConsentCodec);
export const surveyConsentFormItemsArrayCodec = t.array(surveyConsentFormCodec);

export type SurveyConsentItem = t.TypeOf<typeof surveyConsentCodec>;
export type SurveyConsentFormItem = t.TypeOf<typeof surveyConsentFormCodec>;

export const getVerifyConsentCodec = t.type({
  applicationIsVerified: t.boolean,
  coApplicantSsnAndDobIsRequired: t.boolean,
  loanOfficer: t.type({
    userId: t.number,
    fullName: t.string,
    email: t.string,
    phone: t.string,
    nmls: t.string,
  }),
});

export type GetVerifyConsentPayload = t.TypeOf<typeof getVerifyConsentCodec>;

export const saveConsentsFormCodec = t.type({
  ssnLast4: tt.optionFromNullable(
    t.string.pipe(confirmSocialSecurityNumberCodec),
  ),
  dateOfBirth: t.string.pipe(isoDateCodec),
  coApplicantSSN: tt.optionFromNullable(
    t.string.pipe(socialSecurityNumberCodec),
  ),
  coApplicantDateOfBirth: tt.optionFromNullable(t.string.pipe(isoDateCodec)),
  consents: surveyConsentFormItemsArrayCodec,
});

export const saveConsentsCodec = t.type({
  ssnLast4: tt.optionFromNullable(
    t.string.pipe(confirmSocialSecurityNumberCodec),
  ),
  dateOfBirth: t.string.pipe(isoDateCodec),
  coApplicantSSN: tt.optionFromNullable(
    t.string.pipe(socialSecurityNumberCodec),
  ),
  coApplicantDateOfBirth: tt.optionFromNullable(t.string.pipe(isoDateCodec)),
  consents: surveyConsentItemsArrayCodec,
});

export type SaveConsentsPayload = t.TypeOf<typeof saveConsentsCodec>;
