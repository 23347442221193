import { User } from "@/data/client";
import {
  ConsentItem,
  ConsentVersionId,
  GetVerifyConsentPayload,
  saveConsentsCodec,
  SaveConsentsPayload,
} from "@/data/consents";
import { SurveyTip } from "@/data/surveyTips";
import { PersonalDetailsForm } from "@/utils/consent-form-types";
import { Deferred } from "@/utils/deferred";
import { CheckboxFormField } from "@/utils/formField";
import { ApiResult } from "@/utils/request";
import * as E from "fp-ts/Either";
import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import { Option } from "fp-ts/lib/Option";
import * as t from "io-ts";
import { Separated } from "fp-ts/lib/Separated";

export interface SurveyConsentFormItem extends CheckboxFormField {
  consentVersionId: ConsentVersionId;
  requiredForBorrower: boolean;
}

export type ConsentForm = {
  consentItems: SurveyConsentFormItem[];
  primaryApplicant: PersonalDetailsForm;
  coApplicant: Option<PersonalDetailsForm>;
};

export type Model = {
  verificationStatus: GetVerifyConsentPayload;
  consents: Deferred<ApiResult<ConsentItem[]>>;
  tips: Deferred<ApiResult<SurveyTip[]>>;
  form: ConsentForm;
  ssnConsentVerificationFailed: boolean;
  user: Option<User>;
};

export const result = (
  model: Model,
): [
  t.Validation<SaveConsentsPayload>,
  Separated<ConsentVersionId[], boolean[]>,
] => {
  if (!model.form?.consentItems) {
    return [
      saveConsentsCodec.decode({}),
      A.separate([E.of(false)] as E.Either<ConsentVersionId, boolean>[]),
    ];
  }
  const state = {
    ssnLast4: model.form.primaryApplicant.socialSecurityNumber.raw,
    dateOfBirth: model.form.primaryApplicant.dateOfBirth.raw,

    ...pipe(
      model.form.coApplicant,
      O.map((v) => {
        return {
          coApplicantSSN: v.socialSecurityNumber.raw,
          coApplicantDateOfBirth: v.dateOfBirth.raw,
        };
      }),
      O.fold(() => null, identity),
    ),

    consents: model.form.consentItems
      .filter((v) => v.requiredForBorrower)
      .map((v) => ({
        consentVersionId: v.consentVersionId,
        consentProvided: v.raw,
        method: null,
        requiredForBorrower: v.requiredForBorrower,
      })),
  };

  const formState = saveConsentsCodec.decode(state);

  const validConsents = pipe(
    O.fromNullable(model.form),
    O.map(({ consentItems }) =>
      consentItems.map(({ raw, requiredForBorrower, consentVersionId }) =>
        pipe(
          E.fromPredicate(
            () => {
              return requiredForBorrower ? raw : true;
            },
            () => consentVersionId,
          )(false),
          //No validations for consent method as we pass in null here
        ),
      ),
    ),
    O.getOrElse((): E.Either<ConsentVersionId, boolean>[] => [E.of(false)]),
  );

  return [formState, A.separate(validConsents)];
};
