import { ChildProps } from "@/utils/reducerWithEffect";
import { DialogModel, Model } from "./model";
import {
  Action,
  DeleteBranchInitiated,
  DialogClosed,
  EditBranchAction,
  EditBranchInitiated,
  NewBranchInitiated,
  SearchChanged,
} from "./action";
import { Button, Col, Input, Label, Modal, Row } from "@/components/basic";
import { constVoid, constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { BranchId, Client, TeamId } from "@/data/client";
import { Option } from "fp-ts/lib/Option";
import * as EditBranch from "@/components/UserManagement/EditBranch";
import { BranchPayload } from "@/data/payload";
import { useMemo } from "react";
import * as A from "fp-ts/lib/Array";
import { BranchesTable } from "../BranchesTable";
import { TeamsTable } from "../TeamsTable";
import { UsersTable } from "../UsersTable";

export type Props = ChildProps<Model, Action> & {
  client: Client;
  onSelectBranch: (branchId: BranchId) => void;
  onSaveBranch: (branchId: Option<BranchId>, payload: BranchPayload) => void;
  onDeleteBranch: (branchId: BranchId) => void;
  onSelectTeam: (teamId: TeamId) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const trimmedSearch = useMemo(
    () => model.search.trim().toLowerCase(),
    [model.search],
  );

  const allTeams = useMemo(
    () =>
      pipe(
        props.client.branches,
        A.chain((branch) => branch.teams),
      ),
    [props.client.branches],
  );

  const allUsers = useMemo(
    () =>
      pipe(
        props.client.branches,
        A.chain((branch) => branch.users),
        A.concat(
          pipe(
            allTeams,
            A.chain((team) => team.users),
          ),
        ),
      ),
    [allTeams, props.client.branches],
  );

  const foundBranches = useMemo(
    () =>
      trimmedSearch.length > 0
        ? pipe(
            props.client.branches,
            A.filter((branch) =>
              branch.name.toLowerCase().includes(trimmedSearch),
            ),
          )
        : [],
    [trimmedSearch, props.client.branches],
  );

  const foundTeams = useMemo(
    () =>
      trimmedSearch.length > 0
        ? pipe(
            allTeams,
            A.filter((team) => team.name.toLowerCase().includes(trimmedSearch)),
          )
        : [],
    [trimmedSearch, allTeams],
  );

  const foundUsers = useMemo(
    () =>
      trimmedSearch.length > 0
        ? pipe(
            allUsers,
            A.filter((user) =>
              `${user.firstName} ${user.lastName}`
                .toLowerCase()
                .includes(trimmedSearch),
            ),
          )
        : [],
    [trimmedSearch, allUsers],
  );

  return (
    <Col padding="sm" grow={1}>
      <Row padding="sm" alignVertical="center" alignHorizontal="space-between">
        <Label>
          <b>{props.client.name}</b>
        </Label>
        <Button
          type="secondary"
          onClick={O.some(flow(NewBranchInitiated, dispatch))}
        >
          Create a new branch
        </Button>
      </Row>
      <Input
        placeholder="Search"
        onChange={flow(SearchChanged, dispatch)}
        value={model.search}
      />
      <BranchesTable
        branches={
          foundBranches.length > 0 ? foundBranches : props.client.branches
        }
        onSelectBranch={props.onSelectBranch}
        onEditBranch={flow(EditBranchInitiated, dispatch)}
        onDeleteBranch={flow(DeleteBranchInitiated, dispatch)}
      />

      {foundTeams.length > 0 && (
        <TeamsTable
          teams={foundTeams}
          onSelectTeam={props.onSelectTeam}
          onEditTeam={O.none}
          onDeleteTeam={O.none}
        />
      )}

      {foundUsers.length > 0 && (
        <UsersTable
          users={foundUsers}
          onSelectUser={constant}
          onDeleteUserInitiated={constVoid}
          onEditUserInitiated={constVoid}
        />
      )}

      {O.isSome(model.dialog) && (
        <Modal onClose={O.none} title={O.none}>
          <DialogView
            dialog={model.dialog.value}
            dispatch={dispatch}
            onSaveBranch={(branchId) => (payload) =>
              props.onSaveBranch(branchId, payload)
            }
            onDeleteBranch={props.onDeleteBranch}
          />
        </Modal>
      )}
    </Col>
  );
}

type DialogViewProps = {
  dialog: DialogModel;
  dispatch: (action: Action) => void;
  onSaveBranch: (
    branchId: Option<BranchId>,
  ) => (payload: BranchPayload) => void;
  onDeleteBranch: (branchId: BranchId) => void;
};

function DialogView(props: DialogViewProps): JSX.Element {
  switch (props.dialog.type) {
    case "NewBranchDialog":
      return (
        <EditBranch.View
          model={props.dialog.dialogModel}
          dispatch={flow(EditBranchAction, props.dispatch)}
          onSave={props.onSaveBranch(O.none)}
          onCancel={flow(DialogClosed, props.dispatch)}
        />
      );

    case "EditBranchDialog":
      return (
        <EditBranch.View
          model={props.dialog.dialogModel}
          dispatch={flow(EditBranchAction, props.dispatch)}
          onSave={props.onSaveBranch(O.some(props.dialog.branchId))}
          onCancel={flow(DialogClosed, props.dispatch)}
        />
      );

    case "DeleteBranchDialog":
      return (
        <Col>
          <Label>Are you sure you want to delete this branch?</Label>
          <Row padding="xs" gap="xs">
            <Button
              type="secondary"
              onClick={O.some(flow(DialogClosed, props.dispatch))}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={O.some(
                flow(constant(props.dialog.branchId), props.onDeleteBranch),
              )}
            >
              Delete
            </Button>
          </Row>
        </Col>
      );
  }
}
