import * as Documents from "@/components/Documents";
import * as EmployerResolver from "@/components/EmployerResolver";
import * as FinancialInformation from "@/components/FinancialInformation";
import * as ManualDocClassifier from "@/components/ManualDocClassifier";
import * as MortgageInformation from "@/components/MortgageInformation";
import * as PersonalInformation from "@/components/PersonalInformation";
import * as SurveyInitiation from "@/components/SurveyInitiation";
import {
  Application,
  ApplicationId,
  EmployersPayload,
  SavedSurveyPayload,
} from "@/data/payload";
import { sequenceS } from "fp-ts/lib/Apply";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";

import { ClientStatus, ClientStatusResponse } from "@/data/client";
import { Deferred, NotStarted } from "@/utils/deferred";
import { Effect, mapEffect } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { constFalse, pipe } from "fp-ts/lib/function";
import { Action, DocumentsAction } from "./action";
import { SurveyConsentItem } from "@/data/consents";

export enum ApplicationViewMode {
  Readonly= "Readonly",
  Edit= "Edit"
}

export type Model = {
  application: Application;
  applicationId: ApplicationId;
  surveyInitiation: SurveyInitiation.Model;
  primaryApplicant: {
    personalInformation: PersonalInformation.Model;
    financialInformation: FinancialInformation.Model;
    consents: SurveyConsentItem[];
  };
  mortgageInformation: MortgageInformation.Model;
  documents: Documents.Model;
  employerResolverModel: O.Option<EmployerResolver.Model>;
  employers: O.Option<EmployersPayload[]>;

  manualClassification: ManualDocClassifier.Model;

  exportStatus: Deferred<ApiResult<string>>;

  clientStatus: O.Option<ClientStatusResponse>;
  //Tristate boolean: None if warning is not needed, and false if not shown, and true if accepted
  exportWarningShown: O.Option<boolean>;

  mode: ApplicationViewMode;
  // missingEmployer: O.Option<EmployersPayload>;
};

export const init = (
  application: Application,
  clientStatus: O.Option<ClientStatusResponse>,
): [Model, Effect<Action>] => {
  const [documentsModel, documentsEffect] = Documents.init(
    application.applicationId,
    pipe(
      clientStatus,
      O.fold(constFalse, (v) => v.status != ClientStatus.MissingPayment),
    ),
  );
  return [
    {
      application,
      applicationId: application.applicationId,
      surveyInitiation: SurveyInitiation.init(E.right(application.survey)),
      primaryApplicant: {
        personalInformation: PersonalInformation.init(
          O.some(application.survey.primaryApplicant),
        ),
        financialInformation: FinancialInformation.init(
          O.some(application.survey.primaryApplicant),
        ),
        consents: application.survey.primaryApplicant.consents,
      },
      mortgageInformation: MortgageInformation.init(O.some(application.survey)),
      documents: documentsModel,
      employers: O.none,
      employerResolverModel: O.none,
      manualClassification: ManualDocClassifier.init(),
      exportStatus: NotStarted(),
      exportWarningShown: O.none,
      clientStatus,
      mode: ApplicationViewMode.Readonly,
    },
    mapEffect(DocumentsAction)(documentsEffect),
  ];
};

export function result(model: Model): t.Validation<SavedSurveyPayload> {
  return pipe(
    sequenceS(E.Apply)({
      surveyTypeInfo: SurveyInitiation.result(model.surveyInitiation),
      applicantPersonalInformation: PersonalInformation.result(
        model.primaryApplicant.personalInformation,
      ),
      applicantFinancialInformation: FinancialInformation.result(
        model.primaryApplicant.financialInformation,
      ),
      applicationInfo: MortgageInformation.result(model.mortgageInformation),
    }),
    E.map(
      ({
        surveyTypeInfo,
        applicantPersonalInformation,
        applicantFinancialInformation,
        applicationInfo,
      }): SavedSurveyPayload => ({
        ...surveyTypeInfo,
        primaryApplicant: {
          ...applicantFinancialInformation,
          ...applicantPersonalInformation,
          consents: [],
        },
        ...applicationInfo,
        applicationId: model.application.applicationId,
        surveyId: model.application.survey.surveyId,
      }),
    ),
  );
}
