import { Col, IconType, Label, Row, Table } from "@/components/basic";
import { User, UserId } from "@/data/client";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { OptionMenu } from "../basic/Menu/OptionMenu";
import { UserRoles } from "./UserRoles";

export type UsersTableProps = {
  users: User[];
  onSelectUser: (user: UserId) => void;
  onDeleteUserInitiated: (user: UserId) => void;
  onEditUserInitiated: (user: User) => void;
};

export function UsersTable(props: UsersTableProps): JSX.Element {
  return (
    <Table
      className="data-table"
      itemKey={(user) => user.userId}
      data={props.users}
      columns={[
        {
          columnKey: "name",
          header: (
            <Row padding="sm">
              <Label>User</Label>
            </Row>
          ),
          view: (user) => (
            <Row
              padding="sm"
              className="data-table-cell cell-first"
              onClick={flow(constant(user.userId), props.onSelectUser)}
            >
              <Label>
                {pipe(
                  O.of(
                    (firstName: string) => (lastName: string) =>
                      `${firstName} ${lastName}`,
                  ),
                  O.ap(user.firstName),
                  O.ap(user.lastName),
                  O.getOrElseW(() => user.email),
                )}
              </Label>
            </Row>
          ),
        },
        {
          columnKey: "roles",
          header: (
            <Row padding="sm">
              <Label>Roles</Label>
            </Row>
          ),
          view: (user) => (
            <Row padding="sm" className="data-table-cell">
              <UserRoles user={user} />
            </Row>
          ),
        },
        {
          columnKey: "userId",
          header: (
            <Row padding="sm">
              <Label>ID</Label>
            </Row>
          ),
          view: (user) => (
            <Row padding="sm" className="data-table-cell">
              <Label>{user.userId}</Label>
            </Row>
          ),
        },
        {
          columnKey: "actions",
          header: (
            <Row>
              <></>
            </Row>
          ),
          view: (user) => (
            <Col className="data-table-cell cell-last" alignVertical="center">
              <OptionMenu
                menuItems={[
                  {
                    icon: "pen-to-square" as IconType,
                    label: "Edit",
                    onClick: O.some(
                      flow(constant(user), props.onEditUserInitiated),
                    ),
                  },
                  {
                    icon: "trash-can" as IconType,
                    label: "Delete",
                    onClick: O.some(
                      flow(constant(user.userId), props.onDeleteUserInitiated),
                    ),
                  },
                ]}
              ></OptionMenu>
            </Col>
          ),
        },
      ]}
    />
  );
}
