import {
  AlignHorizontal,
  AlignVertical,
  Color,
  getWidth,
  palette,
  Spacing,
  spacing,
  WidthTypes,
} from "@/components/basic/constants";

function getAlignVertical(align: AlignVertical): string {
  switch (align) {
    case "center":
      return "center";
    case "top":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "space-between":
      return "space-between";
    case "space-around":
      return "space-around";
    case "stretch":
      return "stretch";
    case "baseline":
      return "baseline";
  }
}

function getAlignHorizontal(align: AlignHorizontal): string {
  switch (align) {
    case "center":
      return "center";
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    case "space-between":
      return "space-between";
    case "space-around":
      return "space-around";
    case "stretch":
      return "stretch";
  }
}

export type ColProps = {
  padding?: Spacing | "fallback";
  gap?: Spacing | "fallback";
  alignVertical?: AlignVertical;
  alignHorizontal?: AlignHorizontal;
  className?: string;
  id?: string;
  grow?: number;
  width?: WidthTypes;
  background?: Color;
  foreground?: Color;
  children: React.ReactNode;
  basis?: string;
  wrap?: boolean;
  onClick?: () => void;
};

export function Col(props: ColProps): JSX.Element {
  return (
    <div
      id={props.id}
      className={props.className}
      style={{
        ...(props.grow ? { flexGrow: props.grow } : {}),
        ...(props.basis ? { flexBasis: props.basis } : {}),
        ...(props.alignVertical
          ? { justifyContent: getAlignVertical(props.alignVertical) }
          : {}),
        ...(props.alignHorizontal
          ? { alignItems: getAlignHorizontal(props.alignHorizontal) }
          : {}),
        ...(props.wrap ? { flexWrap: "wrap" } : {}),
        ...(props.background
          ? { backgroundColor: palette[props.background] }
          : {}),
        ...(props.foreground ? { color: palette[props.foreground] } : {}),
        ...(props.width ? { width: getWidth(props.width) } : {}),
        display: "flex",
        flexDirection: "column",

        gap:
          props.gap && props.gap !== "fallback"
            ? spacing[props.gap]
            : props.gap === "fallback"
              ? "revert-layer"
              : "0",
        padding:
          props.padding && props.padding !== "fallback"
            ? spacing[props.padding]
            : props.padding === "fallback"
              ? "revert-layer"
              : "0",
      }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
