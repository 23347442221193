import { Client } from "@/data/client";
import { ClientSettingsFormPayload } from "@/data/payload";
import { nonEmptyStringCodec } from "@/utils/codecs";
import { PhoneNumber, phoneNumberCodec } from "@/utils/codecs/phoneNumber";
import { FormField, initFormField } from "@/utils/formField";
import { sequenceS } from "fp-ts/lib/Apply";
import * as E from "fp-ts/lib/Either";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { constant, constFalse, pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types";

export type Model = {
  companyName: FormField<NonEmptyString>;
  phone: FormField<PhoneNumber>;
  nmlsNumber: FormField<NonEmptyString>;
  website: FormField<NonEmptyString>;
  subdomain: FormField<NonEmptyString>;
  logo: O.Option<File>;

  client: Client;
};

export const defaultLogo = (model: Model) =>
  pipe(
    model.client.logos,
    NEA.fromArray,
    O.chain((logos) =>
      pipe(
        logos,
        NEA.filter((v) => v.logoType == "Default"),
        O.chain((v) => O.some(pipe(v, NEA.head))),
      ),
    ),

    O.fold(constant(""), (v) => `${v.url}?${new Date().getTime()}`),
  );

export const init = (client: Client): Model => ({
  companyName: pipe(client.name, initFormField(nonEmptyStringCodec().decode)),
  phone: pipe(client.phone, initFormField(phoneNumberCodec.decode)),
  nmlsNumber: pipe(
    client.nmlsNumber,
    initFormField(nonEmptyStringCodec().decode),
  ),
  website: pipe(client.websiteUrl, initFormField(nonEmptyStringCodec().decode)),
  subdomain: pipe(
    client.subdomain,
    initFormField(nonEmptyStringCodec().decode),
  ),
  logo: O.none,
  client,
});

export const result = (
  model: Model,
): [t.Validation<ClientSettingsFormPayload>, boolean] => [
  sequenceS(E.Apply)({
    companyName: model.companyName.val,
    phone: model.phone.val,
    nmlsNumber: model.nmlsNumber.val,
    websiteUrl: model.website.val,
  }),
  pipe(
    [
      model.companyName.dirty,
      model.phone.dirty,
      model.nmlsNumber.dirty,
      model.website.dirty,
    ],
    NEA.fromArray,
    O.fold(constFalse, (items): boolean =>
      pipe(
        items,
        NEA.reduce(false, (anyTrue, item) => (anyTrue ? anyTrue : item)),
      ),
    ),
  ),
];
