import * as BranchManagement from "@/components/UserManagement/BranchManagement";
import * as ClientManagement from "@/components/UserManagement/ClientManagement";
import * as TeamManagement from "@/components/UserManagement/TeamManagement";
import { ApplicationListItem } from "@/data/applicationsList";
import { Client, User } from "@/data/client";
import { Started } from "@/utils/asyncOperationStatus";
import { Deferred, NotStarted } from "@/utils/deferred";
import { Effect, effectOfAction } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { flow } from "fp-ts/lib/function";
import { Action, GetCurrentClient } from "./action";

export type Page =
  | {
      type: "Client";
      model: ClientManagement.Model;
    }
  | {
      type: "Branch";
      model: BranchManagement.Model;
    }
  | {
      type: "Team";
      model: TeamManagement.Model;
    }
  | {
      type: "User";
      user: User;
      applications: Deferred<ApiResult<ApplicationListItem[]>>;
    };

export const reinitPage = (page: Page): Page => {
  switch (page.type) {
    case "Client":
      return { type: "Client", model: ClientManagement.init() };
    case "Branch":
      return {
        type: "Branch",
        model: BranchManagement.init(page.model.branchId),
      };
    case "Team":
      return { type: "Team", model: TeamManagement.init(page.model.teamId) };
    case "User":
      return page;
  }
};

export type Model = {
  client: Deferred<ApiResult<Client>>;
  page: Page;
  pendingEffects?: () => Effect<Action>;
};

export const init = (): [Model, Effect<Action>] => {
  return [
    {
      client: NotStarted(),
      page: { type: "Client", model: ClientManagement.init() },
    },
    effectOfAction(flow(Started, GetCurrentClient)()),
  ];
};
