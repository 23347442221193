import {
  ApplicationType,
  PropertyType,
  showApplicationType,
  showPropertyType,
} from "@/data/payload";
import { ChildProps } from "@/utils/reducerWithEffect";
import { Action, ApplicationTypeChanged, PropertyTypeChanged } from "./action";
import { Model } from "./model";
import { flow } from "fp-ts/function";
import { Eq as EqString } from "fp-ts/string";
import * as O from "fp-ts/Option";

import { Col, Label, Select } from "@/components/basic";

const applicationTypeOptions: ApplicationType[] = [
  ApplicationType.Purchase,
  ApplicationType.Refinance,
  ApplicationType.Equity,
];

const propertyTypeOptions: PropertyType[] = [
  PropertyType.Primary,
  PropertyType.SecondHome,
  PropertyType.Investment,
];

export type Props = ChildProps<Model, Action>;

export function View({ model, dispatch }: Props): JSX.Element {
  return (
    <div className="grid-2 gap-xs">
      <Col gap="sm" className="grid-item-1">
        <Label>What do you want to do?</Label>

        <Select
          options={applicationTypeOptions}
          selected={O.fromEither(model.applicationType.val)}
          valueEq={EqString}
          renderLabel={showApplicationType}
          onChange={flow(ApplicationTypeChanged, dispatch)}
        />
      </Col>

      <Col gap="sm" className="grid-item-1">
        <Label>What type of property are you looking to purchase?</Label>

        <Select
          options={propertyTypeOptions}
          selected={O.fromEither(model.propertyType.val)}
          valueEq={EqString}
          renderLabel={showPropertyType}
          onChange={flow(PropertyTypeChanged, dispatch)}
        />
      </Col>
    </div>
  );
}
