import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { Row } from "../Row";

export type TableColumn<T> = {
  columnKey: string;
  header: JSX.Element;
  sortable?: boolean ;
  view: (data: T) => JSX.Element;
};

export type SortDirection = "asc" | "desc";

export type TableProps<T> = {
  className?: string;
  data: T[];
  itemKey: (data: T) => string | number;
  columns: TableColumn<T>[];
  onSortedChanged?:     (column: TableColumn<T>["columnKey"], sd: SortDirection) => void

};

export function Table<T>(props: TableProps<T>): JSX.Element {
  const [sortedColumn, setSortedColumn] = useState<
    O.Option<[TableColumn<T>, boolean]>
  >(O.none);

  useEffect(() => {
    pipe(
      sortedColumn,
      O.map(([sortedColumn, isAsc]) => {
        pipe(
          props.onSortedChanged,
          O.fromNullable, 
          O.map((_onSortChanged) =>
            _onSortChanged(sortedColumn.columnKey, isAsc ? "asc" : "desc"),
          ),
        );

        return [sortedColumn, isAsc];
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);
  
  const setStateOnSortChanged = useCallback(
    (column: TableColumn<T>) => 
      O.some(() => {
        setSortedColumn((v) =>
          O.some(
            pipe(
              v,
              O.map((v) => v[1]),
              O.fold(
                () => [column, true],
                (v) => [column, !v],
              ),
            ),
          ),
        );
      }),
    [],
  );

  return (
    <div
      className={props.className}
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${props.columns.length}, auto)`,
      }}
    >
      {props.columns.map((column) => {
        const { columnKey, header } = column;
        const sortedIcon = pipe(
          sortedColumn,
          O.map((sorted) =>
            columnKey == sorted[0].columnKey ? (
              <Icon type={sorted[1] ? "chevron-up" : "chevron-down"}></Icon>
            ) : (
              <></>
            ),
          ),
          O.getOrElse(() => column.sortable ? <Icon type="chevron-down"></Icon> : <></>),
        );
        const onSortToggled= pipe(
          column.sortable,
          O.fromNullable,
          O.chain(v => v ? O.some(true): O.none),
          O.chain(() => setStateOnSortChanged(column)),
        )
        return (
          <Fragment key={`header-${columnKey}`}>
            <Button
              onClick={onSortToggled}
              type="flat"
            >
              <Row alignVertical="center" alignHorizontal="center">
                {header} <span className="text-xs">{sortedIcon}</span>
              </Row>
            </Button>
          </Fragment>
        );
      })}
      {props.data.map((row) =>
        props.columns.map((col) => (
          <Fragment key={`item-${col.columnKey}-${props.itemKey(row)}`}>
            {col.view(row)}
          </Fragment>
        )),
      )}
    </div>
  );
}
