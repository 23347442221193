import { Col, IconType, Label, Row, Table } from "@/components/basic";
import { Branch, BranchId } from "@/data/client";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import { concatAll } from "fp-ts/Monoid";
import { MonoidSum } from "fp-ts/lib/number";
import * as O from "fp-ts/lib/Option";
import { OptionMenu } from "../basic/Menu/OptionMenu";

export type BranchesTableProps = {
  branches: Branch[];
  onSelectBranch: (branchId: BranchId) => void;
  onEditBranch: (branch: Branch) => void;
  onDeleteBranch: (branchId: BranchId) => void;
};

const countUsers = (branch: Branch): number =>
  pipe(
    branch.teams,
    A.map((team) => team.users.length),
    concatAll(MonoidSum),
  ) + branch.users.length;

export function BranchesTable(props: BranchesTableProps): JSX.Element {
  return (
    <Table
      className="data-table"
      itemKey={(branch) => branch.branchId}
      data={props.branches}
      columns={[
        {
          columnKey: "name",
          header: (
            <Row padding="sm">
              <Label>Branch</Label>
            </Row>
          ),
          view: (branch) => (
            <Row
              padding="sm"
              className="data-table-cell cell-first"
              onClick={flow(constant(branch.branchId), props.onSelectBranch)}
            >
              <Label>{branch.name}</Label>
            </Row>
          ),
        },
        {
          columnKey: "users",
          header: (
            <Row padding="sm">
              <Label>Users</Label>
            </Row>
          ),
          view: (branch) => (
            <Row padding="sm" className="data-table-cell">
              <Label>{countUsers(branch)}</Label>
            </Row>
          ),
        },
        {
          columnKey: "actions",
          header: (
            <Row>
              <></>
            </Row>
          ),
          view: (branch) => (
            <Col className="data-table-cell cell-last" alignVertical="center">
            <OptionMenu
              menuItems={[
                {
                  icon: "pen-to-square" as IconType,
                  label: "Edit",
                  onClick: O.some(flow(constant(branch), props.onEditBranch)),
                },
                {
                  icon: "trash-can" as IconType,
                  label: "Delete",
                  onClick: O.some(
                    flow(constant(branch.branchId), props.onDeleteBranch),
                  ),
                },
              ]}
            ></OptionMenu>
            </Col>
          ),
        },
      ]}
    />
  );
}
