import {
  ApplicationDocument,
  DocumentStatus,
  EqRequirementId,
  RequirementId
} from "@/data/applicationDocument";
import { ApplicationId } from "@/data/payload";
import { Deferred, NotStarted } from "@/utils/deferred";
import { Effect, noEffect } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/lib/Array";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { Option, none } from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import { flow, pipe } from "fp-ts/lib/function";
import { Action } from "./action";

export type Model = {
  applicationId: ApplicationId;
  requiredDocuments: Deferred<ApiResult<ApplicationDocument[]>>;
  selectedDocuments: Option<NonEmptyArray<File>>;
  documentsSubmission: Deferred<ApiResult<void>>;
  skippedDocuments: Set<RequirementId>;
  canDownloadDocs: boolean
  imageUploadStatus:  Deferred<ApiResult<void>>;

};

export const init = (applicationId: ApplicationId,canDownloadDocs : boolean ): [Model, Effect<Action>] => {
  return [
    {
      requiredDocuments: NotStarted(),
      applicationId,
      selectedDocuments: none,
      documentsSubmission: NotStarted(),
      skippedDocuments: new Set<RequirementId>(),
      canDownloadDocs,
      imageUploadStatus: NotStarted()
    },
    noEffect,
  ];
};

export function allFilesUploaded(model: Model): boolean {
  switch (model.requiredDocuments.status) {
    case "Resolved":
      return pipe(
        model.requiredDocuments.value,
        O.fromEither,
        O.map(
          flow(
            A.filter(
              (doc) =>
                doc.status === DocumentStatus.UploadRequired ||
                doc.status === DocumentStatus.Processing,
            ),
            A.filter(
              (doc) =>
                doc.isHardRequirement ||
                !S.elem(EqRequirementId)(doc.requirementId)(
                  model.skippedDocuments,
                ),
            ),
            A.isEmpty,
          ),
        ),
        O.getOrElse(() => false),
      );

    case "InProgress":
    case "Updating":
    case "NotStarted":
      return false;
  }
}
