import * as AccountSettings from "@/components/AccountSettings";
import * as ApplicationsList from "@/components/ApplicationsList";
import * as Dashboard from "@/components/Dashboard";
import * as HomePage from "@/components/HomePage";
import * as InactiveBorrower from "@/components/InactiveBorrower";
import * as Invite from "@/components/Invite";
import * as LoInvite from "@/components/LoInvite";
import * as SignUp from "@/components/SignUp";
import * as Summary from "@/components/Summary";
import * as UserManagement from "@/components/UserManagement";
import * as Wizard from "@/components/Wizard";
import { ApplicationListItem } from "@/data/applicationsList";
import { ClientStatus, ClientStatusResponse, User } from "@/data/client";
import {
  Application,
  ApplicationId,
  saveSurveyToLocalStorage,
  SurveyPayload,
} from "@/data/payload";
import {
  loadFromLocalStorage,
  SurveyFlowPayload,
} from "@/data/surveyFlowPayload";
import { initPageTask } from "@/routing/navigation";
import { Api } from "@/utils/api";
import { Finished, Started } from "@/utils/asyncOperationStatus";
import { dateTimeNow } from "@/utils/codecs";
import { LocalStoragetKeys } from "@/utils/localstorage";
import { PageTypes } from "@/utils/page-types";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  effectOfFunc,
  effectOfFunc_,
  effectsBatch,
  mapEffect,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiError, ApiResult, AuthenticationError } from "@/utils/request";
import * as E from "fp-ts/Either";
import { constFalse, pipe } from "fp-ts/lib/function";
import { Option } from "fp-ts/lib/Option";
import { Task } from "fp-ts/lib/Task";
import * as O from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import * as Tup from "fp-ts/Tuple";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { Model } from "./model";
export type Action =
  | { type: "Initialized"; model: Model; effect: Effect<Action> }
  | { type: "InitializationFailed"; error: ApiError }
  | { type: "LoginInitiated" }
  | { type: "LoanOfficerSignup"; emailAddress: string; phoneNo: string }
  | { type: "UserLoginRedirected" }
  | { type: "LogoutInitiated"; logoutTask: Task<void> }
  | { type: "LogoutCompleted" }
  | { type: "HomepageRequested" }
  | { type: "SurveyInitialized"; payload: SurveyFlowPayload }
  | { type: "WizardAction"; action: Wizard.Action }
  | { type: "InviteAction"; action: Invite.Action }
  | { type: "LoInviteAction"; action: LoInvite.Action }
  | { type: "SummaryAction"; action: Summary.Action }
  | { type: "ApplicationsListAction"; action: ApplicationsList.Action }
  | { type: "DashboardAction"; action: Dashboard.Action }
  | { type: "UserManagementAction"; action: UserManagement.Action }
  | { type: "AccountSettingsAction"; action: AccountSettings.Action }
  | {
      type: "SaveSurveyRequested";
      payload: SurveyPayload;
    }
  | {
      type: "SaveSurveyCompleted";
      application: ApiResult<Application>;
    }
  | { type: "SubmitApplicationRequested"; applicationId: ApplicationId }
  | { type: "SubmitApplicationCompleted"; result: ApiResult<Application> }
  | { type: "NewApplicationSelected" }
  | { type: "ApplicationEditClicked"; payload: Application }
  | { type: "ApplicationSelected"; applicationId: ApplicationId }
  | { type: "ApplicationLoaded"; application: Application }
  | { type: "ApplicationLoadFailed"; error: ApiError }
  | { type: "HomebuyerLandingSelected"; application: Option<Application> }
  | { type: "DashboardSelected"; date: DateTime }
  | {
      type: "UserManagementSelected";
      pendingEffects?: () => Effect<UserManagement.Action>;
    }
  | { type: "ApplicationsListSelected" }
  | { type: "AccountSettingsSelected"; user: User }
  | { type: "AccountCompanySettingsSelected"; user: User }
  | { type: "BillingSettingsSelected"; user: User }
  | { type: "ApplicationsListLoaded"; applications: ApplicationListItem[] }
  | { type: "ApplicationsListLoadFailed"; error: ApiError }
  | { type: "SignUpAction"; action: SignUp.Action }
  | { type: "HomePageAction"; action: HomePage.Action }
  | { type: "InactiveBorrrowerAction"; action: InactiveBorrower.Action };

export type GlobalAction = Action;
export const Initialized =
  (effect: Effect<Action>) =>
  (model: Model): Action => ({ type: "Initialized", model, effect });

export const InitializationFailed = (error: ApiError): Action => ({
  type: "InitializationFailed",
  error,
});

export const LoginInitiated = (): Action => ({ type: "LoginInitiated" });
export const UserLoginRedirected = (): Action => ({
  type: "UserLoginRedirected",
});

export const LogoutInitiated = (logoutTask: Task<void>): Action => ({
  type: "LogoutInitiated",
  logoutTask,
});

export const LogoutCompleted = (): Action => ({ type: "LogoutCompleted" });
export const HomepageRequested = (): Action => ({ type: "HomepageRequested" });
export const LoanOfficerSignup =
  (emailAddress: string, phoneNo: string) => (): Action => ({
    type: "LoanOfficerSignup",
    emailAddress,
    phoneNo,
  });

export const SurveyInitialized = (payload: SurveyFlowPayload): Action => ({
  type: "SurveyInitialized",
  payload,
});

export const SummaryAction = (action: Summary.Action): Action => ({
  type: "SummaryAction",
  action,
});

export const WizardAction = (action: Wizard.Action): Action => ({
  type: "WizardAction",
  action,
});

export const ApplicationsListAction = (
  action: ApplicationsList.Action,
): Action => ({
  type: "ApplicationsListAction",
  action,
});

export const InviteAction = (action: Invite.Action): Action => ({
  type: "InviteAction",
  action,
});

export const LoInviteAction = (action: LoInvite.Action): Action => ({
  type: "LoInviteAction",
  action,
});

export const DashboardAction = (action: Dashboard.Action): Action => ({
  type: "DashboardAction",
  action,
});

export const UserManagementAction = (
  action: UserManagement.Action,
): Action => ({
  type: "UserManagementAction",
  action,
});

export const AccountSettingsAction = (
  action: AccountSettings.Action,
): Action => ({
  type: "AccountSettingsAction",
  action,
});

export const SaveSurveyRequested = (payload: SurveyPayload): Action => ({
  type: "SaveSurveyRequested",
  payload,
});

export const SaveSurveyCompleted = (
  application: ApiResult<Application>,
): Action => ({
  type: "SaveSurveyCompleted",
  application,
});

export const SubmitApplicationRequested = (
  applicationId: ApplicationId,
): Action => ({
  type: "SubmitApplicationRequested",
  applicationId,
});

export const SubmitApplicationCompleted = (
  result: ApiResult<Application>,
): Action => ({
  type: "SubmitApplicationCompleted",
  result,
});

export const HomebuyerLandingSelected = (
  application: Option<Application>,
): Action => ({
  type: "HomebuyerLandingSelected",
  application,
});

export const NewApplicationSelected = (): Action => ({
  type: "NewApplicationSelected",
});

export const ApplicationEditClicked = (payload: Application): Action => ({
  type: "ApplicationEditClicked",
  payload,
});

export const ApplicationSelected = (applicationId: ApplicationId): Action => ({
  type: "ApplicationSelected",
  applicationId,
});

export const AccountSettingsSelected = (user: User): Action => ({
  type: "AccountSettingsSelected",
  user,
});

export const AccountCompanySettingsSelected = (user: User): Action => ({
  type: "AccountCompanySettingsSelected",
  user,
});

export const BillingSettingsSelected = (user: User): Action => ({
  type: "BillingSettingsSelected",
  user,  
});

export const ApplicationLoaded = (application: Application): Action => ({
  type: "ApplicationLoaded",
  application,
});

export const ApplicationLoadFailed = (error: ApiError): Action => ({
  type: "ApplicationLoadFailed",
  error,
});

export const DashboardSelected = (date: DateTime): Action => ({
  type: "DashboardSelected",
  date,
});

export const UserManagementSelected = (
  pendingEffects?: () => Effect<UserManagement.Action>,
): Action => ({
  type: "UserManagementSelected",
  pendingEffects,
});

export const ApplicationsListSelected = (): Action => ({
  type: "ApplicationsListSelected",
});

export const ApplicationsListLoaded = (
  applications: ApplicationListItem[],
): Action => ({
  type: "ApplicationsListLoaded",
  applications,
});

export const ApplicationsListLoadFailed = (error: ApiError): Action => ({
  type: "ApplicationsListLoadFailed",
  error,
});

export const SignUpAction = (action: SignUp.Action): Action => ({
  type: "SignUpAction",
  action,
});

export const RouteToHomeBuyer = (api: Api, user: User) => {
  return pipe(
    api.getCurrentApplication,
    TE.map((application): [Model, Effect<Action>] => {
      return [
        {
          page: {
            type: "HomebuyerLanding",
            application,
          },
          user: O.some(user),
          dialog: O.none,
          clientStatus: O.none,
        },
        noEffect,
      ];
    }),
  );
};

export const HomePageAction = (action: HomePage.Action): Action => ({
  type: "HomePageAction",
  action,
});

export const InactiveBorrrowerAction = (
  action: InactiveBorrower.Action,
): Action => ({
  type: "InactiveBorrrowerAction",
  action,
});

const loginTask = (
  api: Api,
): TE.TaskEither<ApiError, [Model, Effect<Action>]> =>
  pipe(
    api.getUser,
    TE.chain((u) =>
      initPageTask(api)(u, {
        status: ClientStatus.Inactive,
      } as ClientStatusResponse),
    ),
  );

export const update =
  (
    api: Api,
    performUserFlow: (
      token: string,
      state: string,
      loginHint: string,
    ) => TE.TaskEither<Error, void>,
  ) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    console.log(`App handling event`, action.type);

    switch (action.type) {
      case "Initialized":
        return [action.model, action.effect || noEffect];

      case "InitializationFailed":
        return [
          { ...model, page: { type: PageTypes.Login } },
          effectOfFunc_(
            () => console.log(`Failed to initialize: ${action.error}`),
            undefined,
          ),
        ];

      case "UserLoginRedirected":
        return [model, noEffect];

      case "LoginInitiated":
        return [
          { ...model, page: { type: "Initializing" } },
          effectOfAsync(
            loginTask(api),
            E.fold(InitializationFailed, ([m, e]: [Model, Effect<Action>]) =>
              Initialized(e)(m),
            ),
          ),
        ];

      case "LoanOfficerSignup":
        {
          const nonce = uuidv4();
          return [
            model,
            effectsBatch([
              effectOfFunc_(
                () =>
                  localStorage.setItem(
                    LocalStoragetKeys.BackOfficeNonce,
                    nonce,
                  ),
                undefined,
              ),
              effectOfAsync(
                performUserFlow(
                  import.meta.env.VITE_LO_SIGNUP_AUTHORITY,
                  JSON.stringify({ nonce }),
                  JSON.stringify({
                    email: action.emailAddress,
                    phoneNo: action.phoneNo,
                  }),
                ),
                LogoutCompleted,
              ),
            ]),
          ];
        }
        break;
      case "LogoutInitiated":
        return [model, effectOfAsync(action.logoutTask, LogoutCompleted)];

      case "LogoutCompleted":
        return [
          { ...model, page: { type: PageTypes.Login }, user: O.none },
          noEffect,
        ];

      case "SurveyInitialized": {
        return pipe(
          Wizard.init(dateTimeNow())(action.payload, model.user),
          Tup.bimap(
            mapEffect(WizardAction),
            (wizardModel): Model => ({
              ...model,
              page: { type: PageTypes.ApplyNow, wizardModel },
            }),
          ),
        );
      }

      case "InviteAction": {
        if (model.page.type !== "Invite") {
          return [model, noEffect];
        }

        return pipe(
          Invite.update(api)(model.page.inviteModel, action.action),
          Tup.bimap(mapEffect(InviteAction), (inviteModel) => ({
            ...model,
            page: { ...model.page, inviteModel },
          })),
        );
      }

      case "LoInviteAction": {
        if (model.page.type !== "LoInvite") {
          return [model, noEffect];
        }

        return pipe(
          LoInvite.update(api)(model.page.loInviteModel, action.action),
          Tup.bimap(mapEffect(LoInviteAction), (loInviteModel) => ({
            ...model,
            page: { ...model.page, loInviteModel },
          })),
        );
      }

      case "WizardAction": {
        if (model.page.type !== PageTypes.ApplyNow) {
          return [model, noEffect];
        }

        return pipe(
          Wizard.update(api)(model.page.wizardModel, action.action),
          Tup.bimap(mapEffect(WizardAction), (wizardModel) => ({
            ...model,
            page: { ...model.page, wizardModel },
          })),
        );
      }

      case "SummaryAction": {
        if (model.page.type !== "Summary") {
          return [model, noEffect];
        }

        const [summaryModel, summaryEffect] = Summary.update(api)(
          model.page.summaryModel,
          action.action,
        );

        return [
          { ...model, page: { ...model.page, summaryModel } },
          mapEffect(SummaryAction)(summaryEffect),
        ];
      }

      case "ApplicationsListAction": {
        if (model.page.type !== "ApplicationsList") {
          return [model, noEffect];
        }

        const [applicationsListModel, applicationsListEffect] =
          ApplicationsList.update(api)(
            model.page.applicationsListModel,
            action.action,
          );

        return [
          { ...model, page: { ...model.page, applicationsListModel } },
          mapEffect(ApplicationsListAction)(applicationsListEffect),
        ];
      }

      case "DashboardAction": {
        if (model.page.type !== "Dashboard") {
          return [model, noEffect];
        }

        const dashboardPage = model.page;
        return pipe(
          Dashboard.update(api)(model.page.dashboardModel, action.action),
          Tup.bimap(
            mapEffect(DashboardAction),
            (dashboardModel): Model => ({
              ...model,
              page: { ...dashboardPage, dashboardModel },
            }),
          ),
        );
      }

      case "UserManagementAction": {
        if (model.page.type !== "Branches") {
          return [model, noEffect];
        }

        return pipe(
          UserManagement.update(api)(model.page.branchesModel, action.action),
          Tup.bimap(
            mapEffect(UserManagementAction),
            (branchesModel): Model => ({
              ...model,
              page: { type: "Branches", branchesModel },
            }),
          ),
        );
      }

      case "AccountSettingsAction": {
        if (model.page.type !== "AccountSettings") {
          return [model, noEffect];
        }

        return pipe(
          AccountSettings.update(api)(action.action)(
            model.page.accountSettingsModel,
          ),
          Tup.bimap(
            mapEffect(AccountSettingsAction),
            (accountSettingsModel): Model => ({
              ...model,
              page: { type: "AccountSettings", accountSettingsModel },
            }),
          ),
        );
      }
      case "SaveSurveyRequested":
        return [
          { ...model, page: { type: "Initializing" } },
          effectOfAsync(
            pipe(
              () => api.tryGetUser(),
              TE.mapError((user) => {
                saveSurveyToLocalStorage(action.payload);
                return user;
              }),
              TE.chain((user) => {
                if (O.isNone(user)) {
                  saveSurveyToLocalStorage(action.payload);
                  return TE.left(AuthenticationError("USER_NONE"));
                }
                return TE.right(user);
              }),
              TE.chain(() => api.saveSurvey(action.payload)),
              TE.chain(api.getApplication),
            ),
            SaveSurveyCompleted,
          ),
        ];

      case "SaveSurveyCompleted":
        return pipe(
          action.application,
          E.fold(
            (e): [Model, Effect<Action>] => {
              if (e.type == "AuthenticationError" && e.error == "USER_NONE") {
                const sid = uuidv4();
                return [
                  model,
                  effectsBatch([
                    effectOfFunc_(
                      () => localStorage.setItem(LocalStoragetKeys.BorrowerNonce, sid),
                      undefined,
                    ),
                    effectOfAsync(
                      performUserFlow(
                        import.meta.env.VITE_BO_SIGNUP_AUTHORITY,
                        sid,
                        pipe(
                          loadFromLocalStorage().primaryApplicantPayload
                            .contactInformation,
                          O.fold(
                            () => "",
                            (v) =>
                              JSON.stringify({
                                email: v.email,
                                phoneNo: v.phoneNumber,
                              }),
                          ),
                        ),
                      ),
                      UserLoginRedirected,
                    ),
                  ]),
                ];
              }
              return [
                model,
                effectOfFunc_(() => {
                  console.log("Failed to save survey");
                  alert("Failed to save survey");
                }, undefined),
              ];
            },
            (application): [Model, Effect<Action>] => {
              const [summaryModel, summaryEffect] = Summary.init(
                application,
                model.clientStatus,
              );

              return [
                {
                  ...model,
                  page: { type: "Summary", summaryModel },
                },
                mapEffect(SummaryAction)(summaryEffect),
              ];
            },
          ),
        );

      case "SubmitApplicationRequested":
        return [
          { ...model, dialog: O.some({ operation: Started() }) },
          effectOfAsync(
            pipe(
              api.submitApplication(action.applicationId),
              TE.chain(() => api.getApplication(action.applicationId)),
            ),
            SubmitApplicationCompleted,
          ),
        ];

      case "SubmitApplicationCompleted":
        return [
          { ...model, dialog: O.some({ operation: Finished(action.result) }) },
          noEffect,
        ];

      case "NewApplicationSelected": {
        return [
          { ...model, page: { type: "Initializing" } },
          effectOfFunc(loadFromLocalStorage, undefined, SurveyInitialized),
        ];
      }

      case "ApplicationEditClicked": {
        if (model.page.type !== "Application") {
          return [model, noEffect];
        }
        const [summaryModel, summaryEffect] = Summary.init(
          action.payload,
          model.clientStatus,
        );
        return [
          {
            ...model,
            page: {
              type: "Summary",
              summaryModel,
            },
          },
          mapEffect(SummaryAction)(summaryEffect),
        ];
      }

      case "ApplicationSelected":
        return [
          model,
          effectOfAsync(
            api.getApplication(action.applicationId),
            E.fold(ApplicationLoadFailed, ApplicationLoaded),
          ),
        ];

      case "ApplicationLoaded":
        return pipe(
          Summary.init(action.application, model.clientStatus),
          Tup.bimap(mapEffect(SummaryAction), (summaryModel) => ({
            ...model,
            page: { type: "Summary", summaryModel },
          })),
        );

      case "ApplicationLoadFailed":
        return [
          model,
          effectOfFunc_((e) => {
            console.error(e);
            alert(`Failed to load application`);
          }, action.error),
        ];

      case "HomebuyerLandingSelected":
        return [
          {
            ...model,
            page: { type: "HomebuyerLanding", application: action.application },
          },
          noEffect,
        ];

      case "DashboardSelected":
        {
          const location = new URL(window.location.href);
          const isFirstLook = pipe(
            location.searchParams.get("first_look"),
            O.fromNullable,
            O.map((v) => v == "true"),
            O.getOrElse(constFalse),
          );

          return pipe(
            Dashboard.init(action.date, isFirstLook),
            Tup.bimap(mapEffect(DashboardAction), (dashboardModel) => ({
              ...model,
              page: { type: "Dashboard", dashboardModel },
            })),
          );
        }
        break;

      case "UserManagementSelected":
        return pipe(
          UserManagement.init(),
          Tup.bimap(mapEffect(UserManagementAction), (branchesModelValue) => {
            const branchesModel: UserManagement.Model = branchesModelValue;
            if (action.pendingEffects) {
              branchesModel.pendingEffects = action.pendingEffects;
            }

            return {
              ...model,
              page: { type: "Branches", branchesModel },
            };
          }),
        );

      case "ApplicationsListSelected":
        return [
          model,
          effectOfAsync(
            api.getApplicationsList,
            E.fold(ApplicationsListLoadFailed, ApplicationsListLoaded),
          ),
        ];

      case "AccountSettingsSelected":
        return [
          {
            ...model,
            page: {
              type: "AccountSettings",
              accountSettingsModel: AccountSettings.init(action.user),
            },
          },
          noEffect
        ];

        case "AccountCompanySettingsSelected":
          return [
            {
              ...model,
              page: {
                type: "AccountSettings",
                accountSettingsModel: AccountSettings.init(action.user),
              },
            },
            effectOfAction(
              AccountSettingsAction(AccountSettings.CompanySettingsSelected()),
            ),
          ];
  
      case "BillingSettingsSelected":
        return [
          {
            ...model,
            page: {
              type: "AccountSettings",
              accountSettingsModel: AccountSettings.init(action.user),
            },
          },
          effectOfAction(
            AccountSettingsAction(AccountSettings.BillingSelected()),
          ),
        ];

      case "ApplicationsListLoaded":
        return [
          {
            ...model,
            page: {
              type: "ApplicationsList",
              applicationsListModel: ApplicationsList.init(
                action.applications,
                model.clientStatus,
              ),
            },
          },
          noEffect,
        ];

      case "HomepageRequested":
        return [
          {
            ...model,
            page: {
              type: PageTypes.HomePage,
              homepageModel: HomePage.initHomePageModel(),
            },
          },
          noEffect,
        ];

      case "ApplicationsListLoadFailed":
        return [
          model,
          effectOfFunc_((e) => {
            console.error(e);
            alert(`Failed to load applications list`);
          }, action.error),
        ];

      case "SignUpAction":
        if (model.page.type !== PageTypes.Signup) {
          return [model, noEffect];
        }

        return pipe(
          SignUp.update(api)(action.action)(model.page.signUpModel),
          Tup.bimap(
            mapEffect(SignUpAction),
            (signUpModel): Model => ({
              ...model,
              page: { type: PageTypes.Signup, signUpModel },
            }),
          ),
        );

      case "HomePageAction":
        if (model.page.type !== PageTypes.HomePage) {
          return [model, noEffect];
        }

        return pipe(
          HomePage.update(api)(
            model.page.homepageModel,
            action.action,
            model.user,
          ),
          Tup.bimap(
            mapEffect(HomePageAction),
            (homepageModel): Model => ({
              ...model,
              page: { type: PageTypes.HomePage, homepageModel },
            }),
          ),
        );
        break;
      case "InactiveBorrrowerAction":
        return [model, noEffect];
    }
  };
