import * as O from "fp-ts/lib/Option";
import { Team, TeamId } from "@/data/client";
import { Col, IconType, Label, Row, Table } from "@/components/basic";
import { constant, flow, pipe } from "fp-ts/lib/function";
import { Option } from "fp-ts/lib/Option";
import { OptionMenu } from "../basic/Menu/OptionMenu";

export type TeamsTableProps = {
  teams: Team[];
  onSelectTeam: (team: TeamId) => void;
  onEditTeam: Option<(team: Team) => void>;
  onDeleteTeam: Option<(team: TeamId) => void>;
};

export function TeamsTable(props: TeamsTableProps): JSX.Element {
  return (
    <Table
      className="data-table"
      itemKey={(team) => team.teamId}
      data={props.teams}
      columns={[
        {
          columnKey: "name",
          header: (
            <Row padding="sm">
              <Label>Team</Label>
            </Row>
          ),
          view: (team) => (
            <Row
              padding="sm"
              className="data-table-cell cell-first"
              onClick={flow(constant(team.teamId), props.onSelectTeam)}
            >
              <Label>{team.name}</Label>
            </Row>
          ),
        },
        {
          columnKey: "users",
          header: (
            <Row padding="sm">
              <Label>Users</Label>
            </Row>
          ),
          view: (team) => (
            <Row padding="sm" className="data-table-cell">
              <Label>{team.users.length}</Label>
            </Row>
          ),
        },
        {
          columnKey: "actions",
          header: (
            <Row>
              <></>
            </Row>
          ),
          view: (team) => (
            <Col className="data-table-cell cell-last" alignVertical="center">
              <OptionMenu
                menuItems={[
                  {
                    icon: "pen-to-square" as IconType,
                    label: "Edit",
                    onClick: pipe(
                      props.onEditTeam,
                      O.map((f) => () => f(team)),
                    ),
                  },
                  {
                    icon: "trash-can" as IconType,
                    label: "Delete",
                    onClick: pipe(
                      props.onDeleteTeam,
                      O.map((f) => () => f(team.teamId)),
                    ),
                  },
                ]}
              ></OptionMenu>
            </Col>
          ),
        },
      ]}
    />
  );
}
