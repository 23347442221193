import { ChildProps } from "@/utils/reducerWithEffect";
import { DialogModel, Model } from "./model";
import {
  Action,
  DeleteUserInitiated,
  DialogClosed,
  EditUserAction,
  EditUserInitiated,
  NewUserInitiated,
  ReassignToChanged,
} from "./action";
import { Button, Col, Label, Modal, Row, Select } from "@/components/basic";
import * as O from "fp-ts/lib/Option";
import { flow, constant, pipe } from "fp-ts/lib/function";
import {
  Branch,
  BranchId,
  ClientsReassignment,
  EqTeamId,
  EqUserId,
  OrdUserId,
  Team,
  TeamId,
  User,
  UserId,
} from "@/data/client";
import * as EditUser from "@/components/UserManagement/EditUser";
import { UserPayload } from "@/data/payload";
import { Option } from "fp-ts/lib/Option";
import { useCallback, useMemo } from "react";
import * as M from "fp-ts/lib/Map";
import { UsersTable } from "../UsersTable";

export type Props = ChildProps<Model, Action> & {
  onSelectUser: (userId: UserId) => void;
  branches: Map<BranchId, Branch>;
  teams: Map<TeamId, Team>;
  users: Map<UserId, User>;
  onSaveUser: (userId: Option<UserId>, payload: UserPayload) => void;
  onDeleteUser: (userId: UserId, reassignment: ClientsReassignment) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;
  const selectedTeam = useMemo(
    () => M.lookup(EqTeamId)(model.teamId, props.teams),
    [model.teamId, props.teams],
  );

  const addNewUserAction = useMemo(
    () =>
      NewUserInitiated(
        pipe(
          selectedTeam,
          O.map((team) => team.branchId),
        ),
        pipe(
          selectedTeam,
          O.map((team) => team.teamId),
        ),
      ),
    [selectedTeam],
  );

  return pipe(
    selectedTeam,
    O.fold(
      () => <Label>Team not found</Label>,
      (team) => (
        <Col padding="sm" grow={1}>
          <Row
            padding="sm"
            alignVertical="center"
            alignHorizontal="space-between"
          >
            <Label>
              <b>{team.name}</b>
            </Label>
            <Button
              type="secondary"
              onClick={O.some(flow(constant(addNewUserAction), dispatch))}
            >
              Add new user
            </Button>
          </Row>

          <UsersTable
            users={team.users}
            onSelectUser={props.onSelectUser}
            onDeleteUserInitiated={flow(DeleteUserInitiated, props.dispatch)}
            onEditUserInitiated={flow(EditUserInitiated, props.dispatch)}
          />

          {O.isSome(model.dialog) && (
            <Modal onClose={O.none} title={O.none}>
              <DialogView
                dialog={model.dialog.value}
                branches={props.branches}
                teams={props.teams}
                users={props.users}
                dispatch={dispatch}
                onSaveUser={(userId) => (payload) =>
                  props.onSaveUser(userId, payload)
                }
                onDeleteUser={props.onDeleteUser}
              />
            </Modal>
          )}
        </Col>
      ),
    ),
  );
}

type DialogViewProps = {
  dialog: DialogModel;
  branches: Map<BranchId, Branch>;
  teams: Map<TeamId, Team>;
  users: Map<UserId, User>;
  dispatch: (action: Action) => void;
  onSaveUser: (userId: Option<UserId>) => (payload: UserPayload) => void;
  onDeleteUser: (userId: UserId, reassignment: ClientsReassignment) => void;
};

function DialogView(props: DialogViewProps): JSX.Element {
  const userOptions = useMemo(
    () => pipe(props.users, M.keys(OrdUserId)),
    [props.users],
  );

  const lookupUserName = useCallback(
    (userId: UserId) =>
      pipe(
        props.users,
        M.lookup(OrdUserId)(userId),
        O.fold(
          () => "",
          ({ firstName, lastName }) => `${firstName} ${lastName}`,
        ),
      ),
    [props.users],
  );

  switch (props.dialog.type) {
    case "NewUserDialog":
      return (
        <EditUser.View
          model={props.dialog.dialogModel}
          branches={props.branches}
          teams={props.teams}
          dispatch={flow(EditUserAction, props.dispatch)}
          onSave={props.onSaveUser(O.none)}
          onCancel={flow(DialogClosed, props.dispatch)}
        />
      );
    case "EditUserDialog":
      return (
        <EditUser.View
          model={props.dialog.dialogModel}
          branches={props.branches}
          teams={props.teams}
          dispatch={flow(EditUserAction, props.dispatch)}
          onSave={props.onSaveUser(O.some(props.dialog.userId))}
          onCancel={flow(DialogClosed, props.dispatch)}
        />
      );
    case "DeleteUserDialog":
      return (
        <Col>
          <Label>Which user would you like to reassigne the clients to?</Label>
          <Select
            options={userOptions}
            selected={props.dialog.reassignTo}
            valueEq={EqUserId}
            renderLabel={lookupUserName}
            onChange={flow(ReassignToChanged, props.dispatch)}
          />
          <Row>
            <Button
              type="secondary"
              onClick={O.some(flow(DialogClosed, props.dispatch))}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={pipe(
                O.of(
                  (uid: UserId) => (reassign: ClientsReassignment) => () =>
                    props.onDeleteUser(uid, reassign),
                ),
                O.ap(O.some(props.dialog.userId)),
                O.ap(pipe(props.dialog.reassignTo, O.map(ClientsReassignment))),
              )}
            >
              Delete
            </Button>
          </Row>
        </Col>
      );
  }
}
