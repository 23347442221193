import { Client, PaymentProviderMeta, User } from "@/data/client";
import {
  AccountSettingsPayload,
  AccountUserPayload,
  ClientSettingsPayload,
} from "@/data/payload";
import { Api } from "@/utils/api";
import {
  AsyncOperationStatus,
  Finished,
  Started,
} from "@/utils/asyncOperationStatus";
import {
  InProgress,
  NotStarted,
  Resolved,
  mapDeferred,
  updatingDeferred,
} from "@/utils/deferred";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  effectOfFunc_,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as E from "fp-ts/Either";
import * as TE from "fp-ts/TaskEither";
import { flow, pipe } from "fp-ts/lib/function";
import * as EditCompany from "./EditCompany";
import * as EditUser from "./EditUser";
import { Model } from "./model";

export type Action =
  | {
      type: "GeneralSelected";
    }
  | {
      type: "CompanySettingsSelected";
    }
  | {
      type: "GetClientDetails";
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "BillingSelected";
    }
  | {
      type: "GetPaymentProviderUrl";
      operation: AsyncOperationStatus<ApiResult<PaymentProviderMeta>>;
    }
  | {
      type: "NotificationsSelected";
    }
  | {
      type: "EditUserAction";
      action: EditUser.Action;
    }
  | {
      type: "EditCompanyAction";
      action: EditCompany.Action;
    }
  | {
      type: "UserPayloadPrepared";
      payload: AccountUserPayload;
    }
  | {
      type: "CientSettingsPayloadPrepared";
      payload: ClientSettingsPayload;
    }
  | {
      type: "AccountUserSaved";
      result: ApiResult<User>;
    }
  | {
      type: "ClientSettingsSaved";
      result: ApiResult<Client>;
    }
  | {
      type: "SmsNotificationsToggled";
    }
  | {
      type: "SettingsPayloadPrepared";
      payload: AccountSettingsPayload;
    };

export const GeneralSelected = (): Action => ({
  type: "GeneralSelected",
});

export const CompanySettingsSelected = (): Action => ({
  type: "CompanySettingsSelected",
});

export const GetClientDetails = (
  operation: AsyncOperationStatus<ApiResult<Client>>,
): Action => ({
  type: "GetClientDetails",
  operation,
});

export const BillingSelected = (): Action => ({
  type: "BillingSelected",
});
export const GetPaymentProviderUrl = (
  operation: AsyncOperationStatus<ApiResult<PaymentProviderMeta>>,
): Action => ({
  type: "GetPaymentProviderUrl",
  operation,
});

export const NotificationsSelected = (): Action => ({
  type: "NotificationsSelected",
});

export const EditUserAction = (action: EditUser.Action): Action => ({
  type: "EditUserAction",
  action,
});

export const EditCompanyAction = (action: EditCompany.Action): Action => ({
  type: "EditCompanyAction",
  action,
});

export const UserPayloadPrepared = (payload: AccountUserPayload): Action => ({
  type: "UserPayloadPrepared",
  payload,
});

export const CientSettingsPayloadPrepared = (
  payload: ClientSettingsPayload,
): Action => ({
  type: "CientSettingsPayloadPrepared",
  payload,
});

export const AccountUserSaved = (result: ApiResult<User>): Action => ({
  type: "AccountUserSaved",
  result,
});

export const ClientSettingsSaved = (result: ApiResult<Client>): Action => ({
  type: "ClientSettingsSaved",
  result,
});

export const SmsNotificationsToggled = (): Action => ({
  type: "SmsNotificationsToggled",
});

export const SettingsPayloadPrepared = (
  payload: AccountSettingsPayload,
): Action => ({
  type: "SettingsPayloadPrepared",
  payload,
});

export const update =
  (api: Api) =>
  (action: Action) =>
  (model: Model): [Model, Effect<Action>] => {
    switch (action.type) {
      case "GeneralSelected":
        return [
          {
            ...model,
            editUserModel: pipe(model.user, EditUser.init, E.right, Resolved),
            page: { type: "General" },
          },
          noEffect,
        ];

      case "CompanySettingsSelected":
        return [
          {
            ...model,
            page: { type: "Company" },
          },
          effectOfAction(flow(Started, GetClientDetails)()),
        ];

      case "BillingSelected":
        return [
          {
            ...model,
            page: { type: "Billing" },
          },
          effectOfAction(flow(Started, GetPaymentProviderUrl)()),
        ];
      case "GetClientDetails":
        switch (action.operation.status) {
          case "Started":
            {
              return [
                {
                  ...model,
                  client: InProgress(),
                },

                effectOfAsync(
                  api.getCurrentClient,
                  flow(Finished, GetClientDetails),
                ),
              ];
            }
            break;

          case "Finished":
            return [
              {
                ...model,
                client: Resolved(action.operation.result),
                editCompanyModel: pipe(
                  action.operation.result,
                  E.map(EditCompany.init),
                  Resolved,
                ),
              },
              noEffect,
            ];
        }
        break;

      case "GetPaymentProviderUrl":
        switch (action.operation.status) {
          case "Started":
            {
              return [
                {
                  ...model,
                  paymentProviderMeta: InProgress(),
                },

                effectOfAsync(
                  api.getPaymentProviderUrl,
                  flow(Finished, GetPaymentProviderUrl),
                ),
              ];
            }
            break;

          case "Finished":
            return [
              {
                ...model,
                paymentProviderMeta: Resolved(action.operation.result),
              },
              noEffect,
            ];
        }
        break;

      case "NotificationsSelected":
        return [
          {
            ...model,
            page: { type: "Notifications" },
          },
          noEffect,
        ];

      case "EditUserAction":
        return [
          {
            ...model,
            editUserModel: pipe(
              model.editUserModel,
              mapDeferred(E.map(EditUser.update(action.action))),
            ),
          },
          noEffect,
        ];

      case "EditCompanyAction":
        return [
          {
            ...model,
            editCompanyModel: pipe(
              model.editCompanyModel,
              mapDeferred(E.map(EditCompany.update(action.action))),
            ),
          },
          noEffect,
        ];

      case "UserPayloadPrepared":
        return [
          {
            ...model,
            editUserModel: updatingDeferred(model.editUserModel),
          },
          effectOfAsync(
            pipe(
              api.saveAccountUser(action.payload),
              TE.chain(() => api.getUser),
            ),
            AccountUserSaved,
          ),
        ];

      case "CientSettingsPayloadPrepared":
        return [
          {
            ...model,
            editUserModel: updatingDeferred(model.editUserModel),
          },
          effectOfAsync(
            pipe(
              api.updateClientSettings(action.payload),
              TE.chain(() => api.getCurrentClient),
            ),
            ClientSettingsSaved,
          ),
        ];

      case "ClientSettingsSaved":
        return [
          {
            ...model,
            editCompanyModel: pipe(
              action.result,
              E.map(EditCompany.init),
              Resolved,
            ),
          },
          noEffect,
        ];
      case "SettingsPayloadPrepared":
        return [
          {
            ...model,
            accountSettings: updatingDeferred(model.accountSettings),
          },
          effectOfAsync(
            pipe(
              api.saveAccountSettings(action.payload),
              TE.chain(() => api.getUser),
            ),
            AccountUserSaved,
          ),
        ];

      case "AccountUserSaved":
        return [
          {
            ...model,
            editUserModel: NotStarted(),
            accountSettings: pipe(
              action.result,
              E.map(({ userId, smsNotificationsDisabled }) => ({
                userId,
                smsNotificationsDisabled,
              })),
              Resolved,
            ),
          },
          pipe(
            action.result,
            E.fold(
              (err) => effectOfFunc_(() => console.error(err), undefined),
              () => noEffect,
            ),
          ),
        ];

      case "SmsNotificationsToggled":
        return [
          {
            ...model,
            accountSettings: pipe(
              model.accountSettings,
              mapDeferred(
                E.map((settings) => ({
                  ...settings,
                  smsNotificationsDisabled: !settings.smsNotificationsDisabled,
                })),
              ),
            ),
          },
          noEffect,
        ];
    }
  };
