import * as t from "io-ts";
import { Eq as EqNumber, Ord as OrdNumber } from "fp-ts/lib/number";
import { Eq } from "fp-ts/lib/Eq";
import { Ord } from "fp-ts/lib/Ord";
import {
  emailStringCodec,
  nonEmptyStringCodec,
  stringEnumCodec,
} from "@/utils/codecs";
import { Eq as EqString, Ord as OrdString } from "fp-ts/lib/string";
import * as tt from "io-ts-types";
import { stateCodec } from "./states";

interface UserIdBrand {
  readonly UserId: unique symbol;
}

export const userIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, UserIdBrand> => true,
  "UserId",
);

export type UserId = t.TypeOf<typeof userIdCodec>;

export const EqUserId: Eq<UserId> = EqNumber;
export const OrdUserId: Ord<UserId> = OrdNumber;

export type ClientsReassignment = {
  reassignTo: UserId;
};

export const ClientsReassignment = (userId: UserId): ClientsReassignment => ({
  reassignTo: userId,
});

// SuperUser,LoanOfficer,Support
export enum ExtensionRole {
  SuperUser = "SuperUser",
  LoanOfficer = "LoanOfficer",
  Support = "Support",
  SuperAdmin = "SuperAdmin",
  ReceiveAppCreatedNotification = "ReceiveAppCreatedNotification",
  ReceiveAppSubmittedNotification = "ReceiveAppSubmittedNotification",
}

export const OrdExtensionRole: Ord<ExtensionRole> = OrdString;
export const EqExtensionRole: Eq<ExtensionRole> = EqString;

export const extensionRoleCodec = stringEnumCodec<typeof ExtensionRole>(
  ExtensionRole,
  "ExtensionRole",
);

export enum ClientStatus {
  Current = "Current",
  Restricted = "Restricted",
  Inactive = "Inactive",
  Active = "Active",
  Deprecated = "Deprecated",
  MissingPayment = "MissingPayment",
}

export const OrdClientStatus: Ord<ClientStatus> = OrdString;
export const EqClientStatus: Eq<ClientStatus> = EqString;

export const clientStatusCodec = stringEnumCodec<typeof ClientStatus>(
  ClientStatus,
  "ClientStatus",
);

export const clientStatusResponseCodec = t.type({
  status: t.string.pipe(clientStatusCodec),
});

export type ClientStatusResponse = t.TypeOf<typeof clientStatusResponseCodec>;

export const showExtensionRole = (role: ExtensionRole): string => {
  switch (role) {
    case ExtensionRole.SuperUser:
      return "Super User";
    case ExtensionRole.LoanOfficer:
      return "Loan Officer";
    case ExtensionRole.Support:
      return "Support";
    case ExtensionRole.SuperAdmin:
      return "Super Admin";
    case ExtensionRole.ReceiveAppCreatedNotification:
      return "Receive App Created Notification";
    case ExtensionRole.ReceiveAppSubmittedNotification:
      return "Receive App Submitted Notification";
  }
};
// const commaSeparatedListCodec = new t.Type<string[], string, string>(
//   "CommaSeparatedList",
//   (u): u is string[] =>
//     Array.isArray(u) && u.every((item) => typeof item === "string"),
//   (u, _) => {
//     const r = pipe(
//       u.split(","),
//       A.map((role) => role.trim()),
//     );
//     return t.success(r);
//   },
//   (a) => a.join(","),
// );

// export const extensionRolesSetCodec = t.string
//   .pipe(commaSeparatedListCodec)
//   .pipe(tt.setFromArray(t.string.pipe(extensionRoleCodec), OrdExtensionRole));

interface ClientIdBrand {
  readonly ClientId: unique symbol;
}

export const clientIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, ClientIdBrand> => true,
  "ClientId",
);

export type ClientId = t.TypeOf<typeof clientIdCodec>;

export const EqClientId: Eq<ClientId> = EqNumber;
export const OrdClientId: Ord<ClientId> = OrdNumber;

interface BranchIdBrand {
  readonly BranchId: unique symbol;
}

export const branchIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, BranchIdBrand> => true,
  "BranchId",
);

export type BranchId = t.TypeOf<typeof branchIdCodec>;

export const EqBranchId: Eq<BranchId> = EqNumber;
export const OrdBranchId: Ord<BranchId> = OrdNumber;

interface TeamIdBrand {
  readonly TeamId: unique symbol;
}

export const teamIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, TeamIdBrand> => true,
  "TeamId",
);

export type TeamId = t.TypeOf<typeof teamIdCodec>;

export const EqTeamId: Eq<TeamId> = EqNumber;
export const OrdTeamId: Ord<TeamId> = OrdNumber;

export const userCodec = t.type({
  userId: userIdCodec,
  firstName: tt.optionFromNullable(t.string),
  lastName: tt.optionFromNullable(t.string),
  email: t.string.pipe(emailStringCodec),
  mobilePhone: tt.optionFromNullable(t.string),
  roles: tt.setFromArray(t.string.pipe(extensionRoleCodec), OrdExtensionRole),
  branchId: tt.optionFromNullable(branchIdCodec),
  teamId: tt.optionFromNullable(teamIdCodec),
  nmlsNumber: tt.optionFromNullable(t.string.pipe(nonEmptyStringCodec())),
  address: tt.optionFromNullable(t.string.pipe(nonEmptyStringCodec())),
  address2: tt.optionFromNullable(t.string),
  city: tt.optionFromNullable(t.string.pipe(nonEmptyStringCodec())),
  state: tt.optionFromNullable(stateCodec),
  zipCode: tt.optionFromNullable(t.string.pipe(nonEmptyStringCodec())),
  smsNotificationsDisabled: t.boolean,
});

export type User = t.TypeOf<typeof userCodec>;

export const teamCodec = t.type({
  teamId: teamIdCodec,
  branchId: branchIdCodec,
  name: t.string,
  users: t.array(userCodec),
});

export const branchCodec = t.type({
  branchId: branchIdCodec,
  clientId: clientIdCodec,
  name: t.string,
  teams: t.array(teamCodec),
  users: t.array(userCodec),
});

export const logoCodec = t.type({
  logoType: t.string, 
  url: t.string
})

export const clientCodec = t.type({
  clientId: clientIdCodec,
  name: t.string,
  branches: t.array(branchCodec),
  users: t.array(userCodec),
  subdomain: t.string,
  websiteUrl: t.string,
  phone: t.string,
  nmlsNumber: t.string,
  status: t.string.pipe(clientStatusCodec),
  logos: t.array(logoCodec)
});

export const paymentProviderMetaCodec = t.type({
  status: t.string,
  message: t.string,
  paymentProviderCustomerPortalUrl: t.string,
});

export const paymentProviderRequestCodec = t.type({
  returnToPath: t.string,
});

export type Team = t.TypeOf<typeof teamCodec>;
export type Branch = t.TypeOf<typeof branchCodec>;
export type Client = t.TypeOf<typeof clientCodec>;
export type PaymentProviderMeta = t.TypeOf<typeof paymentProviderMetaCodec>;
export type PaymentProviderRequest = t.TypeOf<
  typeof paymentProviderRequestCodec
>;
