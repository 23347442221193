import apartment from "@/assets/images/apartment.jpg";
import dots from "@/assets/images/dots.png";
import greenStripes from "@/assets/images/green-stripes.jpg";
import homeChecking from "@/assets/images/home-checking.jpg";
import "@/assets/styles/InactiveBorrower/css/styles.css";
import { ChildProps } from "@/utils/reducerWithEffect";
import { isBackOfficeUser } from "@/utils/user";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import { Col, Icon, Row } from "../basic";
import { Action } from "./action";
import { Model } from "./model";

export type Props = ChildProps<Model, Action>;
 
export function View(props: Props): JSX.Element {
  const isLoanOfficer = pipe(
    props.model.user,
    O.fold(
      () => false,
      isBackOfficeUser,
    ),
  );
  const ctaImage = isLoanOfficer ? apartment : homeChecking;

  return (
    <div className="grid-3 gap-xs">
      <Col>
        <div style={{ aspectRatio: "3/2", width: "10rem" }}>
          <img src={dots} />
        </div>
      </Col>
      <Col>
        <img src={ctaImage} />
        <Col>
          <p className="text-bold">There seems to be an issue.</p>
          <p>
            {isLoanOfficer ? (
              <>
                Seems like [MortgageCompany] is having issues accessing
                AppCatcher. We recommend you contact our support team for more
                information.
                <Row>
                  <Icon type="eye"></Icon>
                  support@appcatcher.com
                  <Icon type="eye"></Icon>
                </Row>
              </>
            ) : (
              <>
                Hi, welcome back to AppCatcher. [MortgageCompany] is no longer
                available in AppCatcher. We recommend you contact your loan
                officer for further information{" "}
              </>
            )}
          </p>
        </Col>
      </Col>
      <Col alignVertical="space-between" alignHorizontal="space-between">
        <div style={{ aspectRatio: "3/2", width: "10rem" }}>
          <img src={dots} />
          <img src={greenStripes} />
        </div>
      </Col>
    </div>
  );
}
