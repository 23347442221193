import { ChildProps } from "@/utils/reducerWithEffect";
import { Model, result } from "./model";
import {
  Action,
  EmailChanged,
  FirstNameChanged,
  LastNameChanged,
  PhoneChanged,
  NmlsNumberChanged,
  AddressChanged,
  Address2Changed,
  StateChanged,
  CityChanged,
  ZipChanged,
} from "./action";
import { AccountUserPayload } from "@/data/payload";
import {
  Button,
  Col,
  Input,
  InputField,
  Label,
  PhoneInputField,
  Row,
  Select,
} from "@/components/basic";
import { flow, pipe } from "fp-ts/lib/function";

import * as O from "fp-ts/lib/Option";
import { EqUSState, allUsStates, stateNames } from "@/data/states";

export type Props = ChildProps<Model, Action> & {
  onSave: (payload: AccountUserPayload) => void;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const save = pipe(
    result(model),
    O.fromEither,
    O.map((payload) => () => props.onSave(payload)),
  );

  return (
    <div className="grid-2 gap-xs">
      <InputField
        label="First name"
        field={model.firstName}
        onChange={flow(FirstNameChanged, dispatch)}
      />
      <InputField
        label="Last name"
        field={model.lastName}
        onChange={flow(LastNameChanged, dispatch)}
      />
      <InputField
        type="email"
        label="Email"
        field={model.email}
        onChange={flow(EmailChanged, dispatch)}
      />

      <PhoneInputField
        label="Phone"
        field={model.phone}
        onChange={flow(PhoneChanged, dispatch)}
      />

      <InputField
        label="NMLS number"
        field={model.nmlsNumber}
        onChange={flow(NmlsNumberChanged, dispatch)}
      />

      <InputField
        label="Street Address"
        field={model.address}
        onChange={flow(AddressChanged, dispatch)}
      />

      <Col gap="xs">
        <Label>Apt, Suite, etc.</Label>

        <Input
          value={model.address2}
          onChange={flow(Address2Changed, dispatch)}
        />
      </Col>

      <InputField
        label="City"
        field={model.city}
        onChange={flow(CityChanged, dispatch)}
      />

      <Col gap="xs">
        <Label>State</Label>
        <Select
          options={allUsStates}
          selected={O.fromEither(model.state.val)}
          onChange={flow(StateChanged, dispatch)}
          valueEq={EqUSState}
          renderLabel={(state) => stateNames[state]}
        />
      </Col>

      <InputField
        label="Zip"
        field={model.zip}
        onChange={flow(ZipChanged, dispatch)}
      />

      <Row padding="xs" gap="xs">
        <Button type="primary" onClick={save}>
          Save
        </Button>
      </Row>
    </div>
  );
}
