import { ConsentItem, SaveConsentsPayload } from "@/data/consents";
import { ApplicationId } from "@/data/payload";
import { ApplicantType } from "@/data/surveyFlowPayload";
import { SurveyTip } from "@/data/surveyTips";
import { Api } from "@/utils/api";
import {
  AsyncOperationStatus,
  Finished,
  Started,
} from "@/utils/asyncOperationStatus";
import {
  confirmSocialSecurityNumberCodec,
  socialSecurityNumberCodec,
  validDateTimeCodecS,
} from "@/utils/codecs";
import {
  consentFormFields,
  PersonalDetailsForm,
  personalDetailsForm,
} from "@/utils/consent-form-types";
import { Resolved, updatingDeferred } from "@/utils/deferred";
import {
  Effect,
  effectOfAction,
  effectOfAsync,
  noEffect,
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { flow, identity, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Lens } from "monocle-ts";
import * as L from "monocle-ts/Lens";
import * as _ from "monocle-ts/Optional";
import {
  updateCheckboxFormField,
  updateFormField,
} from "./../../utils/formField";
import { Model } from "./model";

export type Action =
  | {
      type: "ConsentSubmitted";
      surveyPayload: SaveConsentsPayload;
      operation: AsyncOperationStatus<ApiResult<unknown>>;
      onSuccessAction: (applicationId: ApplicationId) => void;
    }
  | {
      type: "ConsentsInit";
    }
  | {
      type: "ConsentsDone";
    }
  | {
      type: "LoadConsentMeta";
      operation: AsyncOperationStatus<ApiResult<ConsentItem[]>>;
    }
  | {
      type: "SocialSecurityNumberChanged";
      applicantType: ApplicantType;
      socialSecurityNumber: string;
    }
  | {
      type: "DateOfBirthChanged";
      applicantType: ApplicantType;
      dateOfBirth: string;
    }
  | {
      type: "ConsentItemChanged";
      eConsent: boolean;
      itemIndex: number;
    }
  | {
      type: "SoftCreditCheckConsentChanged";
      softCreditCheckConsent: boolean;
    }
  | {
      type: "LoadTips";
      operation: AsyncOperationStatus<ApiResult<SurveyTip[]>>;
    };

export const ConsentSubmitted =
  (
    surveyPayload: SaveConsentsPayload,
    onSuccessAction: (applicationId: ApplicationId) => void,
  ) =>
  (operation: AsyncOperationStatus<ApiResult<unknown>>): Action => ({
    type: "ConsentSubmitted",
    surveyPayload,
    operation,
    onSuccessAction,
  });

export const SocialSecurityNumberChanged =
  (applicantType: ApplicantType) =>
  (socialSecurityNumber: string): Action => ({
    type: "SocialSecurityNumberChanged",
    applicantType,
    socialSecurityNumber,
  });

export const DateOfBirthChanged =
  (applicantType: ApplicantType) =>
  (dateOfBirth: string): Action => ({
    type: "DateOfBirthChanged",
    applicantType,
    dateOfBirth,
  });

export const ConsentItemChanged =
  (itemIndex: number) =>
  (eConsent: boolean): Action => ({
    type: "ConsentItemChanged",
    eConsent,
    itemIndex,
  });

export const ConsentsInit = (): Action => ({
  type: "ConsentsInit",
});

export const ConsentsDone = (): Action => ({
  type: "ConsentsDone",
});

export const LoadConsentMeta = (
  operation: AsyncOperationStatus<ApiResult<ConsentItem[]>>,
): Action => ({
  type: "LoadConsentMeta",
  operation,
});

export const SoftCreditCheckConsentChanged = (
  softCreditCheckConsent: boolean,
): Action => ({
  type: "SoftCreditCheckConsentChanged",
  softCreditCheckConsent,
});

export const LoadTips = (
  operation: AsyncOperationStatus<ApiResult<SurveyTip[]>>,
): Action => ({
  type: "LoadTips",
  operation,
});

const ab: _.Optional<PersonalDetailsForm, PersonalDetailsForm> = _.optional(
  (v) => O.some(v),
  (n) => (_) => n,
);
const sa = pipe(L.id<Model>(), L.prop("form"), L.prop("coApplicant"), L.some);

const sb = pipe(sa, _.composeOptional(ab));

const existingModel = flow(
  sb.getOption,
  O.getOrElse(() => ({}) as unknown as PersonalDetailsForm),
);

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "ConsentsInit":
        {
          return [model, effectOfAction(flow(Started, LoadConsentMeta)())];
        }
        break;

      case "ConsentsDone":
        {
          return [model, noEffect];
        }
        break;
      case "LoadConsentMeta":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                { ...model, consents: updatingDeferred(model.consents) },
                effectOfAsync(api.getConsents, flow(Finished, LoadConsentMeta)),
              ];

            case "Finished":
              return consentLoadedAction(model, action);
          }
        }
        break;
      case "LoadTips":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                { ...model, tips: updatingDeferred(model.tips) },
                effectOfAsync(api.surveyTips, flow(Finished, LoadTips)),
              ];

            case "Finished":
              return [
                { ...model, tips: Resolved(action.operation.result) },
                noEffect,
              ];
          }
        }
        break;
      case "ConsentSubmitted":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                model,
                effectOfAsync(
                  api.putVerificationStatus(action.surveyPayload),
                  flow(
                    Finished,
                    ConsentSubmitted(
                      action.surveyPayload,
                      action.onSuccessAction,
                    ),
                  ),
                ),
              ];

            case "Finished":
              return pipe(
                action.operation.result,
                E.fold(
                  () => [
                    {
                      ...model,
                      ssnConsentVerificationFailed: true,
                    },
                    noEffect,
                  ],
                  () => [
                    model,
                    effectOfAsync(
                      api.getBorrowerApplication,
                      pipe(
                        E.fold(
                          () => ConsentsInit(),
                          (v) => {
                            action.onSuccessAction(v.applicationId);
                            return ConsentsInit();
                          },
                        ),
                      ),
                    ),
                  ],
                ),
              );
          }
        }
        break;
      case "ConsentItemChanged":
        {
          const lens = Lens.fromPath<Model>()(["form", "consentItems"]);

          return [
            lens.modify((model) =>
              pipe(
                model,
                A.mapWithIndex((_, checkboxFormField) => {
                  if (checkboxFormField.consentVersionId == action.itemIndex) {
                    return {
                      ...updateCheckboxFormField(
                        checkboxFormField,
                        action.eConsent,
                      ),
                      consentVersionId: checkboxFormField.consentVersionId,
                      requiredForBorrower: checkboxFormField.requiredForBorrower
                    };
                  }
                  return checkboxFormField;
                }),
              ),
            )(model),
            noEffect,
          ];
        }
        break;
      case "SocialSecurityNumberChanged":
        {
          switch (action.applicantType) {
            case "PrimaryApplicant":
              {
                const lens = Lens.fromPath<Model>()([
                  "form",
                  "primaryApplicant",
                ]);

                return [
                  lens.modify((model) => {
                    return {
                      ...model,
                      socialSecurityNumber: updateFormField(
                        confirmSocialSecurityNumberCodec.decode,
                      )(action.socialSecurityNumber),
                    };
                  })(model),
                  noEffect,
                ];
              }
              break;
            case "CoApplicant":
              {
                return [
                  sb.set({
                    ...existingModel(model),
                    socialSecurityNumber: updateFormField(
                      socialSecurityNumberCodec.decode,
                    )(action.socialSecurityNumber),
                  })(model),
                  noEffect,
                ];
              }
              break;
          }
        }
        break;
      case "DateOfBirthChanged":
        {
          switch (action.applicantType) {
            case "PrimaryApplicant":
              {
                const lens = Lens.fromPath<Model>()([
                  "form",
                  "primaryApplicant",
                ]);

                return [
                  lens.modify((model) => {
                    return {
                      ...model,
                      dateOfBirth: updateFormField(validDateTimeCodecS.decode)(
                        action.dateOfBirth,
                      ),
                    };
                  })(model),
                  noEffect,
                ];
              }
              break;
            case "CoApplicant":
              {
                const newModel = sb.set({
                  ...existingModel(model),

                  dateOfBirth: updateFormField(validDateTimeCodecS.decode)(
                    action.dateOfBirth,
                  ),
                })(model);
                return [{ ...newModel }, noEffect];
              }
              break;
          }
        }
        break;
    }

    return [model, noEffect];
  };

function consentLoadedAction(
  model: Model,
  action: {
    type: "LoadConsentMeta";
    operation: AsyncOperationStatus<ApiResult<ConsentItem[]>>;
  },
): [Model, Effect<Action>] {
  if (action.operation.status == "Started") {
    return [model, noEffect];
  }
  const result = action.operation.result;
  return pipe(
    result,
    E.map((consent) => {
      return consent.map((v) => v.consentVersionId);
    }),
    E.map((): [Model, Effect<Action>] => {
      return [
        {
          ...model,
          consents: Resolved(result),

          form: {
            primaryApplicant: personalDetailsForm(model.user, {
              applicantType: "PrimaryApplicant",
            }),
            coApplicant: pipe(
              model.verificationStatus.coApplicantSsnAndDobIsRequired,
              O.fromPredicate(identity),
              O.map(() =>
                personalDetailsForm(model.user, {
                  applicantType: "CoApplicant",
                }),
              ),
            ),
            consentItems: pipe(
              result,
              E.map((consentMeta) => {
                const formFields = consentFormFields(consentMeta);
                return formFields;
              }),
              E.fold(() => [], identity),
            ),
          },
        },
        noEffect,
      ];
    }),
    E.getOrElse((): [Model, Effect<Action>] => [model, noEffect]),
  );
}
