import * as FinancialInformation from "@/components/FinancialInformation";
import * as PersonalInformation from "@/components/PersonalInformation";
import {
  employerPatchPayloadCodec,
  EmployersPatchPayload,
  EmployersPayload,
} from "@/data/payload";
import { ValidDateTime, validDateTimeCodecS } from "@/utils/codecs";
import { FormField, initFormField } from "@/utils/formField";
import { Effect, noEffect } from "@/utils/reducerWithEffect";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";
import { DateTime } from "luxon";
import { Model as SummaryModel } from "../Summary";
import { Action } from "./action";

export type Model = {
  isCurrentlyEmployedByThisEmployer: boolean;
  startDate: FormField<ValidDateTime>;
  endDate: O.Option<FormField<ValidDateTime>>;
  receivedCommissionFromEmployer: boolean;
  missingEmployer: EmployersPayload;
  primaryApplicant: {
    personalInformation: PersonalInformation.Model;
    financialInformation: FinancialInformation.Model;
  };
  employers: O.Option<EmployersPayload[]>;
  isErrorWhileSavingEmployer: boolean;

  mappedEmployer: O.Option<EmmployerSelectOption>;
};

export type EmmployerSelectOption = {
  name: string;
  employerId: number;
};

const defaultDateField = initFormField(validDateTimeCodecS.decode)("");

export const init = (
  { employers, primaryApplicant }: SummaryModel,
  missingEmployer: EmployersPayload,
): [Model, Effect<Action>] => {
  return [
    {
      isCurrentlyEmployedByThisEmployer: false,
      startDate: defaultDateField,
      endDate: O.some(defaultDateField),
      receivedCommissionFromEmployer: false,
      missingEmployer,
      employers,
      primaryApplicant,
      isErrorWhileSavingEmployer: false,
      mappedEmployer: O.none,
    },
    noEffect,
  ];
};

export const result = (
  model: Model,
): [t.Validation<EmployersPatchPayload>, O.Option<string>] => {
  const employerId = model.missingEmployer.employerId;

  const employmentInfo = {
    employerId,
    applicantId: model.missingEmployer.applicantId,
    name: model.missingEmployer.name,
    employerInfo: {
      employerId,
      startDate: model.startDate.raw,
      endDate: pipe(
        model.endDate,
        O.map((v) =>
          DateTime.fromISO(v.raw).isValid
            ? v.raw
            : model.isCurrentlyEmployedByThisEmployer
              ? (null as unknown as string)  //Consider as valid by decoder
              : "" //Force error,
        ),
        O.getOrElse(() => null as unknown as string),
      ),
      isStillEmployed: model.isCurrentlyEmployedByThisEmployer,
      receivedOvertimeBonusCommission: model.receivedCommissionFromEmployer,
    },
  };
  const otherValidations = pipe(
    model.endDate,
    O.map(
      (endDate) =>
        DateTime.fromISO(model.startDate.raw) > DateTime.fromISO(endDate.raw),
    ),
    O.chain((v) =>
      v ? O.some("Start Date is Greater than the End Date") : O.none,
    ),
  );

  return [employerPatchPayloadCodec.decode(employmentInfo), otherValidations];
};
