import { Option, none } from "fp-ts/lib/Option";
import * as EditUser from "@/components/UserManagement/EditUser";
import { TeamId, UserId } from "@/data/client";

export type DialogModel =
  | {
      type: "NewUserDialog";
      dialogModel: EditUser.Model;
    }
  | {
      type: "EditUserDialog";
      userId: UserId;
      dialogModel: EditUser.Model;
    }
  | {
      type: "DeleteUserDialog";
      userId: UserId;
      reassignTo: Option<UserId>;
    };

export const NewUserDialog = (dialogModel: EditUser.Model): DialogModel => ({
  type: "NewUserDialog",
  dialogModel,
});

export const EditUserDialog =
  (userId: UserId) =>
  (dialogModel: EditUser.Model): DialogModel => ({
    type: "EditUserDialog",
    userId,
    dialogModel,
  });

export const DeleteUserDialog = (
  userId: UserId,
  reassignTo: Option<UserId>,
): DialogModel => ({
  type: "DeleteUserDialog",
  userId,
  reassignTo,
});

export type Model = {
  teamId: TeamId;
  dialog: Option<DialogModel>;
};

export const init = (teamId: TeamId): Model => ({
  teamId,
  dialog: none,
});

export const withDialogClosed = (model: Model): Model => ({
  ...model,
  dialog: none,
});
