import * as BranchManagement from "@/components/UserManagement/BranchManagement";
import * as ClientManagement from "@/components/UserManagement/ClientManagement";
import * as TeamManagement from "@/components/UserManagement/TeamManagement";
import { ApplicationListItem } from "@/data/applicationsList";
import {
  Branch,
  BranchId,
  Client,
  ClientId,
  ClientsReassignment,
  Team,
  TeamId,
  User,
  UserId,
} from "@/data/client";
import { BranchPayload, TeamPayload, UserPayload } from "@/data/payload";
import { Api } from "@/utils/api";
import { AsyncOperationStatus, Finished } from "@/utils/asyncOperationStatus";
import {
  deferredToOption,
  InProgress,
  Resolved,
  updatingDeferred,
} from "@/utils/deferred";
import {
  Effect,
  effectOfAsync,
  effectOfFunc_,
  noEffect
} from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import { Model, reinitPage } from "./model";
export type Action =
  | {
      type: "GetCurrentClient";
      operation: AsyncOperationStatus<ApiResult<Client>>;
    }
  | {
      type: "BranchSelected";
      branchId: BranchId;
    }
  | {
      type: "TeamSelected";
      teamId: TeamId;
    }
  | {
      type: "UserSelected";
      userId: UserId;
    }
  | {
      type: "UserApplicationsLoaded";
      userId: UserId;
      applications: ApiResult<ApplicationListItem[]>;
    }
  | {
      type: "BranchPayloadPrepared";
      clientId: ClientId;
      branchId: Option<BranchId>;
      payload: BranchPayload;
    }
  | {
      type: "TeamPayloadPrepared";
      teamId: Option<TeamId>;
      payload: TeamPayload;
    }
  | {
      type: "UserPayloadPrepared";
      userId: Option<UserId>;
      payload: UserPayload;
    }
  | {
      type: "DeleteTeamConfirmed";
      teamId: TeamId;
    }
  | {
      type: "DeleteUserConfirmed";
      userId: UserId;
      reassignment: ClientsReassignment;
    }
  | {
      type: "DeleteBranchConfirmed";
      branchId: BranchId;
    }
  | {
      type: "BranchManagementAction";
      action: BranchManagement.Action;
    }
  | {
      type: "TeamManagementAction";
      action: TeamManagement.Action;
    }
  | {
      type: "ClientManagementAction";
      action: ClientManagement.Action;
    };

export const GetCurrentClient = (
  operation: AsyncOperationStatus<ApiResult<Client>>,
): Action => ({
  type: "GetCurrentClient",
  operation,
});

export const BranchSelected = (branchId: BranchId): Action => ({
  type: "BranchSelected",
  branchId,
});

export const TeamSelected = (teamId: TeamId): Action => ({
  type: "TeamSelected",
  teamId,
});

export const UserSelected = (userId: UserId): Action => ({
  type: "UserSelected",
  userId,
});

export const UserApplicationsLoaded =
  (userId: UserId) =>
  (applications: ApiResult<ApplicationListItem[]>): Action => ({
    type: "UserApplicationsLoaded",
    userId,
    applications,
  });

export const BranchPayloadPrepared =
  (clientId: ClientId) =>
  (branchId: Option<BranchId>, payload: BranchPayload): Action => ({
    type: "BranchPayloadPrepared",
    branchId,
    clientId,
    payload,
  });

export const TeamPayloadPrepared = (
  teamId: Option<TeamId>,
  payload: TeamPayload,
): Action => ({
  type: "TeamPayloadPrepared",
  teamId,
  payload,
});

export const UserPayloadPrepared = (
  userId: Option<UserId>,
  payload: UserPayload,
): Action => ({
  type: "UserPayloadPrepared",
  userId,
  payload,
});

export const DeleteTeamConfirmed = (teamId: TeamId): Action => ({
  type: "DeleteTeamConfirmed",
  teamId,
});

export const DeleteUserConfirmed = (
  userId: UserId,
  reassignment: ClientsReassignment,
): Action => ({
  type: "DeleteUserConfirmed",
  userId,
  reassignment,
});

export const DeleteBranchConfirmed = (branchId: BranchId): Action => ({
  type: "DeleteBranchConfirmed",
  branchId,
});

export const BranchManagementAction = (
  action: BranchManagement.Action,
): Action => ({
  type: "BranchManagementAction",
  action,
});

export const TeamManagementAction = (
  action: TeamManagement.Action,
): Action => ({
  type: "TeamManagementAction",
  action,
});

export const ClientManagementAction = (
  action: ClientManagement.Action,
): Action => ({
  type: "ClientManagementAction",
  action,
});

const findBranch =
  (branchId: BranchId) =>
  (client: Client): Option<Branch> =>
    pipe(
      client.branches,
      A.findFirst((branch) => branch.branchId === branchId),
    );

const findTeam =
  (teamId: TeamId) =>
  (branches: Branch[]): Option<Team> =>
    pipe(
      branches,
      A.findFirstMap((branch) =>
        pipe(
          branch.teams,
          A.findFirst((team) => team.teamId === teamId),
        ),
      ),
    );

const allBranchUsers = (branch: Branch): User[] =>
  pipe(
    branch.teams,
    A.chain((team) => team.users),
    A.concat(branch.users),
  );

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "GetCurrentClient":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                { ...model, client: updatingDeferred(model.client) },
                effectOfAsync(
                  api.getCurrentClient,
                  flow(Finished, GetCurrentClient),
                ),
              ];

            case "Finished":
              return [
                {
                  ...model,
                  client: Resolved(action.operation.result),
                  page: reinitPage(model.page),
                },
                pipe(
                  action.operation.result,
                  E.fold(
                    (err) => effectOfFunc_(() => console.log(err), undefined),
                    (_) => {
                      if (model.pendingEffects) {
                        return model.pendingEffects();
                      } else {
                        return noEffect;
                      }
                    },
                  ),
                ),
              ];
          }
        }
        break;

      case "BranchSelected": {
        const branch: Option<Branch> = pipe(
          model.client,
          deferredToOption,
          O.chain(O.fromEither),
          O.chain(findBranch(action.branchId)),
        );

        return [
          pipe(
            branch,
            O.fold(
              () => model,
              ({ branchId }) => ({
                ...model,
                page: {
                  type: "Branch",
                  model: BranchManagement.init(branchId),
                },
              }),
            ),
          ),
          noEffect,
        ];
      }

      case "TeamSelected": {
        const team: Option<Team> = pipe(
          model.client,
          deferredToOption,
          O.chain(O.fromEither),
          O.chain(({ branches }) => findTeam(action.teamId)(branches)),
        );

        return [
          pipe(
            team,
            O.fold(
              () => model,
              ({ teamId }): Model => ({
                ...model,
                page: { type: "Team", model: TeamManagement.init(teamId) },
              }),
            ),
          ),
          noEffect,
        ];
      }

      case "UserSelected": {
        const user: Option<User> = pipe(
          model.client,
          deferredToOption,
          O.chain(O.fromEither),
          O.chain((client) =>
            pipe(
              client.branches,
              A.chain(allBranchUsers),
              A.concat(client.users),
              A.findFirst((user) => user.userId === action.userId),
            ),
          ),
        );
        return [
          pipe(
            user,
            O.fold(
              () => model,
              (u) => ({
                ...model,
                page: { type: "User", user: u, applications: InProgress() },
              }),
            ),
          ),
          effectOfAsync(
            api.getApplicationsList,
            UserApplicationsLoaded(action.userId),
          ),
        ];
      }

      case "UserApplicationsLoaded": {
        if (
          model.page.type !== "User" ||
          model.page.user.userId !== action.userId
        ) {
          return [model, noEffect];
        }

        const applications = pipe(
          action.applications,
          E.map(
            A.filter((app) =>
              app.assignees.some((a) => a.userId === action.userId),
            ),
          ),
        );

        return [
          {
            ...model,
            page: { ...model.page, applications: Resolved(applications) },
          },
          noEffect,
        ];
      }

      case "BranchPayloadPrepared": {
        return [
          {
            ...model,
            client: updatingDeferred(model.client),
          },
          effectOfAsync(
            pipe(
              action.branchId,
              O.fold(
                () => api.createNewBranch(action.payload),
                (branchId) =>
                  api.updateBranch(action.clientId, branchId)(action.payload),
              ),
              TE.chain((_) => api.getCurrentClient),
            ),
            flow(Finished, GetCurrentClient),
          ),
        ];
      }

      case "TeamPayloadPrepared":
        return [
          {
            ...model,
            client: updatingDeferred(model.client),
          },
          effectOfAsync(
            pipe(
              action.teamId,
              O.fold(
                () => api.createNewTeam(action.payload),
                (teamId) => api.updateTeam(teamId)(action.payload),
              ),
              TE.chain((_) => api.getCurrentClient),
            ),
            flow(Finished, GetCurrentClient),
          ),
        ];

      case "UserPayloadPrepared":
        return [
          {
            ...model,
            client: updatingDeferred(model.client),
          },
          effectOfAsync(
            pipe(
              action.userId,
              O.fold(
                () => api.createNewUser(action.payload),
                (userId) => api.updateUser(userId)(action.payload),
              ),
              TE.chain((_) => api.getCurrentClient),
            ),
            flow(Finished, GetCurrentClient),
          ),
        ];

      case "DeleteTeamConfirmed":
        return [
          {
            ...model,
            client: updatingDeferred(model.client),
          },
          effectOfAsync(
            pipe(
              api.deleteTeam(action.teamId),
              TE.chain((_) => api.getCurrentClient),
            ),
            flow(Finished, GetCurrentClient),
          ),
        ];

      case "DeleteUserConfirmed":
        return [
          {
            ...model,
            client: updatingDeferred(model.client),
          },
          effectOfAsync(
            pipe(
              api.deleteUser(action.userId, action.reassignment),
              TE.chain((_) => api.getCurrentClient),
            ),
            flow(Finished, GetCurrentClient),
          ),
        ];

      case "DeleteBranchConfirmed":
        return [
          {
            ...model,
            client: updatingDeferred(model.client),
          },
          effectOfAsync(
            pipe(
              api.deleteBranch(action.branchId),
              TE.chain((_) => api.getCurrentClient),
            ),
            flow(Finished, GetCurrentClient),
          ),
        ];

      case "BranchManagementAction":
        if (model.page.type !== "Branch") {
          return [model, noEffect];
        }

        return [
          {
            ...model,
            page: {
              ...model.page,
              model: BranchManagement.update(model.page.model, action.action),
            },
          },
          noEffect,
        ];

      case "TeamManagementAction":
        if (model.page.type !== "Team") {
          return [model, noEffect];
        }

        return [
          {
            ...model,
            page: {
              ...model.page,
              model: TeamManagement.update(model.page.model, action.action),
            },
          },
          noEffect,
        ];

      case "ClientManagementAction":
        if (model.page.type !== "Client") {
          return [model, noEffect];
        }

        return [
          {
            ...model,
            page: {
              ...model.page,
              model: ClientManagement.update(model.page.model, action.action),
            },
          },
          noEffect,
        ];
    }
  };
