import { sequenceS } from "fp-ts/lib/Apply";
import {
  ApplicantFinancialInfo,
  EmployersPayload,
  IncomeSourcePayload,
  RetirementAccount,
} from "../../data/payload";
import * as BankruptcyStatus from "../BankruptcyStatus/model";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as IncomeInformation from "@/components/IncomeInformation";
export type Model = {
  bankruptcyStatus: BankruptcyStatus.Model;
  incomeInformationModel: IncomeInformation.Model;
  foreclosureOrShortSale: boolean;
  alimonyOrChildSupport: boolean;
  retirementAccounts: Set<RetirementAccount>;
  employers: EmployersPayload[];
};

export const init = (
  applicantFinancialInfo: Option<ApplicantFinancialInfo>,
): Model => ({
  bankruptcyStatus: pipe(
    applicantFinancialInfo,
    O.fold(
      () => BankruptcyStatus.init("None"),
      ({ bankruptcyStatus }) => bankruptcyStatus,
    ),
  ),
  employers: pipe(
    applicantFinancialInfo,
    O.fold(
      () => [],
      ({ employers }) => employers,
    ),
  ),
  incomeInformationModel: IncomeInformation.init(
    pipe(
      applicantFinancialInfo,
      O.map(({ incomeSources }) => incomeSources),
    ),
  ),
  foreclosureOrShortSale: pipe(
    applicantFinancialInfo,
    O.fold(
      () => false,
      ({ foreclosureOrShortSale }) => foreclosureOrShortSale,
    ),
  ),
  alimonyOrChildSupport: pipe(
    applicantFinancialInfo,
    O.fold(
      () => false,
      ({ hasAlimonyOrChildSupportObligation }) =>
        hasAlimonyOrChildSupportObligation,
    ),
  ),
  retirementAccounts: pipe(
    applicantFinancialInfo,
    O.fold(
      () => new Set(),
      ({ retirementAccounts }) => retirementAccounts,
    ),
  ),
});

export const initIncomeSource = (
  incomeSourceType: IncomeSourcePayload["type"],
): IncomeSourcePayload => {
  switch (incomeSourceType) {
    case "SelfEmployed":
      return {
        type: incomeSourceType,
        selfEmployment: { type: "ScheduleC_or_1099" },
      };

    case "W2Wages":
      return {
        type: incomeSourceType,
        workSchedule: { type: "FullTime" },
        receivesBonusOvertimeCommission: false,
      };

    case "Other":
      return {
        type: incomeSourceType,
        description: "",
      };

    default:
      return {
        type: incomeSourceType,
      };
  }
};

export const result = (model: Model): t.Validation<ApplicantFinancialInfo> =>
  sequenceS(E.Apply)({
    incomeSources: pipe(
      model.incomeInformationModel,
      IncomeInformation.result,
      E.right,
    ),
    retirementAccounts: E.right(model.retirementAccounts),
    foreclosureOrShortSale: E.right(model.foreclosureOrShortSale),
    hasAlimonyOrChildSupportObligation: E.right(model.alimonyOrChildSupport),
    bankruptcyStatus: E.right(model.bankruptcyStatus),
    consents: E.right([]),
    employers: E.right(model.employers),
  });
