import * as FinancialInformation from "@/components/FinancialInformation";
import * as PersonalInformation from "@/components/PersonalInformation";
import * as SurveyInitiation from "@/components/SurveyInitiation";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { Button, Col, Icon, Label, Row, TogglePanel } from "../basic";
import {
  ExportDocuments,
  FinancialInformationAction,
  PersonalInformationAction,
  ShowExportWarning,
  SurveyInitiationAction,
} from "./action";
import { result } from "./model";
import { Props, SubPageProps } from "./view";

function scrollIntoView(id: string) {
  const targetElement = document.getElementById(id);
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}

import * as Documents from "@/components/Documents";
import { ClientStatus } from "@/data/client";
import { ApplicationStatusType } from "@/data/payload";
import { Started } from "@/utils/asyncOperationStatus";
import { flow, pipe } from "fp-ts/lib/function";
import { useMemo } from "react";
import { SaveSurvey } from "./action";

export function EditApplication(props: Props & SubPageProps): JSX.Element {
  const { model, dispatch } = props;

  const surveyValidationResult = useMemo(() => result(model), [model]);

  const status = model.application.status;
  const canSaveApplication = (aStatus: ApplicationStatusType) =>
    ![
      ApplicationStatusType.Complete,
      ApplicationStatusType.Exported,
      ApplicationStatusType.Archived,
    ].includes(aStatus);

  const submitSurvey = useMemo(
    () =>
      pipe(
        O.fromPredicate(() => canSaveApplication(status)),
        () => surveyValidationResult,
        O.fromEither,
        O.map((payload) => flow(Started, SaveSurvey(payload), dispatch)),
      ),
    [surveyValidationResult, dispatch, status],
  );

  const completeApplication = useMemo(
    () =>
      pipe(
        surveyValidationResult,
        O.fromEither,
        O.filter(() => Documents.allFilesUploaded(model.documents)),
        O.map(() => () => props.completeApplication(model.applicationId)),
      ),
    [surveyValidationResult, model.documents, model.applicationId, props],
  );

  const exportApplication = useMemo(
    () =>
      pipe(
        props.model.clientStatus,
        O.chain((clientStatus) =>
          clientStatus.status == ClientStatus.Restricted
            ? O.none
            : O.some(true),
        ),
        O.chain(() => {
          if (
            ![
              ApplicationStatusType.Complete,
              ApplicationStatusType.Exported,
            ].includes(model.application.status)
          ) {
            return O.some(flow(ShowExportWarning, dispatch));
          } else {
            return O.some(
              flow(Started, ExportDocuments(model.applicationId), dispatch),
            );
          }
        }),
      ),
    [
      dispatch,
      props.model.clientStatus,
      model.application.status,
      model.applicationId,
    ],
  );

  const editActions = (
    <Row padding="sm" gap="xs" alignHorizontal="right">
      <Row className="text-md " alignVertical="baseline">
        <div>
          In order to submit your application, fill in all of your information
          and{" "}
        </div>
        <Button
          type="flat"
          className="link"
          onClick={props.onScrollToDocuments}
        >
          upload all relevant documents
        </Button>
      </Row>
      <Row gap="xs" alignHorizontal="right">
        <Button type="secondary" onClick={exportApplication} disabledTooltip="Restricted mode">
          Export
        </Button>
        {pipe(
          O.fromPredicate(() => canSaveApplication(model.application.status))(
            null,
          ),
          O.fold(
            () => <></>,
            () => (
              <Button type="secondary" onClick={submitSurvey}>
                Save
              </Button>
            ),
          ),
        )}

        <Button type="primary" onClick={completeApplication}>
          Submit
        </Button>
      </Row>
    </Row>
  );

  return (
    <Row gap="xs" padding="md" className="app-content" background="grey10">
      <Col padding="sm" className="summary-navigation">
        <Col padding="xs" gap="xs">
          <ApplicantSummaryItem
            personalInformation={model.primaryApplicant.personalInformation}
            financialInformation={model.primaryApplicant.financialInformation}
          />

          {model.mortgageInformation.coApplicants.map((coApplicant, index) => (
            <ApplicantSummaryItem
              key={index}
              coApplicantIndex={index}
              personalInformation={coApplicant.personalInformation}
              financialInformation={coApplicant.financialInformation}
            />
          ))}

          <hr />

          <Row
            gap="xs"
            className="text-md pointer"
            onClick={() => scrollIntoView("documents-section")}
          >
            <Icon type="file-lines" />
            <span className="flex-grow-1">Upload Documentation</span>
            <Icon
              type={
                Documents.allFilesUploaded(model.documents)
                  ? "circle-check"
                  : "circle"
              }
            />
          </Row>
        </Col>
      </Col>
      <Col gap="md" grow={1} className="summary-content">
        <Row gap="xs" alignHorizontal="space-between" alignVertical="center">
          {editActions}
        </Row>

        <SurveyInitiation.View
          model={model.surveyInitiation}
          dispatch={flow(SurveyInitiationAction, dispatch)}
        />

        <TogglePanel
          title={model.primaryApplicant.personalInformation.fullName.raw}
        >
          <Col gap="md">
            <Col id="primary-applicant-personal-info">
              <PersonalInformation.View
                model={model.primaryApplicant.personalInformation}
                dispatch={flow(
                  PersonalInformationAction({ type: "Primary" }),
                  dispatch,
                )}
              />
            </Col>
            <Col id="primary-applicant-financial-info">
              <FinancialInformation.View
                model={model.primaryApplicant.financialInformation}
                dispatch={flow(
                  FinancialInformationAction({ type: "Primary" }),
                  dispatch,
                )}
              />
            </Col>
          </Col>
        </TogglePanel>

        {model.mortgageInformation.coApplicants.map((coApplicant, index) => (
          <TogglePanel
            title={coApplicant.personalInformation.fullName.raw}
            key={index}
          >
            <Col gap="md">
              <Col id={`co-applicant-${index}-personal-info`}>
                <PersonalInformation.View
                  model={coApplicant.personalInformation}
                  dispatch={flow(
                    PersonalInformationAction({ type: "CoApplicant", index }),
                    dispatch,
                  )}
                />
              </Col>
              <Col id={`co-applicant-${index}-financial-info`}>
                <FinancialInformation.View
                  model={coApplicant.financialInformation}
                  dispatch={flow(
                    FinancialInformationAction({
                      type: "CoApplicant",
                      index,
                    }),
                    dispatch,
                  )}
                />
              </Col>
            </Col>
          </TogglePanel>
        ))}

        {props.children}

        {editActions}
      </Col>
    </Row>
  );
}

type ApplicantSummaryItemProps = {
  personalInformation: PersonalInformation.Model;
  financialInformation: FinancialInformation.Model;
  coApplicantIndex?: number;
};

function ApplicantSummaryItem(props: ApplicantSummaryItemProps): JSX.Element {
  const personalInformationComplete = useMemo(
    () =>
      pipe(props.financialInformation, FinancialInformation.result, E.isRight),
    [props.financialInformation],
  );

  const financialInformationComplete = useMemo(
    () =>
      pipe(props.personalInformation, PersonalInformation.result, E.isRight),
    [props.personalInformation],
  );

  return (
    <Col gap="xs">
      <Label className="text-md">
        <b>
          {props.personalInformation.fullName.raw} {props.coApplicantIndex}
        </b>
      </Label>

      <Row
        gap="xs"
        className="text-md pointer"
        onClick={() => {
          const id =
            props.coApplicantIndex !== undefined &&
            props.coApplicantIndex !== null
              ? `co-applicant-${props.coApplicantIndex}-personal-info`
              : "primary-applicant-personal-info";
          scrollIntoView(id);
        }}
      >
        <Icon type="user" />
        <span className="flex-grow-1">Personal Information</span>
        <Icon type={personalInformationComplete ? "circle-check" : "circle"} />
      </Row>
      <Row
        gap="xs"
        className="text-md pointer"
        onClick={() => {
          const id =
            props.coApplicantIndex !== undefined &&
            props.coApplicantIndex !== null
              ? `co-applicant-${props.coApplicantIndex}-financial-info`
              : "primary-applicant-financial-info";
          scrollIntoView(id);
        }}
      >
        <Icon type="piggy-bank" />
        <span className="flex-grow-1">Financial Information</span>
        <Icon type={financialInformationComplete ? "circle-check" : "circle"} />
      </Row>
    </Col>
  );
}
