import * as UserManagement from "@/components/UserManagement";
import * as S from "fp-ts/Set";

import {
  AccountSettingsAction,
  AccountSettingsSelected,
  Action,
  DashboardSelected,
  InviteAction,
  NewApplicationSelected,
  RouteToHomeBuyer,
  SummaryAction,
  UserManagementSelected,
} from "@/app/action";
import { init, Model } from "@/app/model";
import * as AccountSettings from "@/components/AccountSettings";
import * as Invite from "@/components/Invite";

import * as ApplicationsList from "@/components/ApplicationsList";
import { loadStripeTask, PaymentSetupInfo } from "@/components/SignUp";
import * as Summary from "@/components/Summary";
import {
  BranchId,
  ClientStatusResponse,
  EqExtensionRole,
  ExtensionRole,
  TeamId,
  User,
  UserId,
} from "@/data/client";
import {
  Application,
  ApplicationId,
  CreateClientPayload,
  createClientPayloadCodec,
  SurveyPayload,
  surveyPayloadCodec,
} from "@/data/payload";
import { Api } from "@/utils/api";
import { Started } from "@/utils/asyncOperationStatus";
import { NotStarted } from "@/utils/deferred";
import {
  getFromLocalStorage,
  LocalStoragetKeys,
  removeFromLocalStorage,
} from "@/utils/localstorage";
import { PageTypes } from "@/utils/page-types";
import {
  Effect,
  effectOfAction,
  effectsBatch,
  mapEffect,
  noEffect,
} from "@/utils/reducerWithEffect";
import {
  ApiError,
  BackOfficeSignupRedirectException,
  BorrowerSignupRedirectException,
  ExceptionError,
} from "@/utils/request";
import { AuthenticationResult } from "@azure/msal-common";
import * as E from "fp-ts/Either";
import { sequenceT } from "fp-ts/lib/Apply";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import { get } from "lodash-es";
import { DateTime } from "luxon";
import { NavigationWithApplication, RouteMatch, RouteMeta } from "./routes";

export type NavigationMeta = {
  matches: RouteMatch;
  api: Api;
  userHasRole: boolean;
  routeMeta: RouteMeta;
  user: User;
  clientStatus: ClientStatusResponse;
};

export function toSummaryPage({
  matches,
  api,
  userHasRole,
  routeMeta,
  user,
  clientStatus,
}: NavigationMeta): TE.TaskEither<ApiError, [Model, Effect<Action>]> {
  return pipe(
    Number(matches["applicationId"]) as ApplicationId,
    (v) => api.getApplication(v),
    TE.chain((application) => {
      return !userHasRole &&
        routeMeta.accessPredicate &&
        !routeMeta.accessPredicate({
          user,
          application,
        } as NavigationWithApplication)
        ? TE.left(ExceptionError("No Access"))
        : TE.right(application);
    }),
    TE.map((application: Application) => {
      return Summary.init(application, O.some(clientStatus));
    }),
    TE.map((summaryModel): [Model, Effect<Action>] => {
      return [
        {
          page: {
            type: "Summary",
            summaryModel: summaryModel[0],
          },
          user: O.some(user),
          dialog: O.none,
          clientStatus: O.some(clientStatus),
        },
        mapEffect(SummaryAction)(summaryModel[1]),
      ];
    }),
  );
}

export function toInvitePage({
  api,
  user,
  clientStatus,
}: NavigationMeta): TE.TaskEither<ApiError, [Model, Effect<Action>]> {
  return pipe(
    () => api.getVerificationStatus(),
    TE.chain((getVerifyConsentPayload) => {
      if (
        getVerifyConsentPayload.applicationIsVerified &&
        !getVerifyConsentPayload.coApplicantSsnAndDobIsRequired
      ) {
        return RouteToHomeBuyer(api, user);
      } else {
        return TE.of([
          {
            page: {
              type: "Invite",
              inviteModel: {
                verificationStatus: getVerifyConsentPayload,
                consents: NotStarted(),
                tips: NotStarted(),
                form: null as unknown as Invite.ConsentForm,
                ssnConsentVerificationFailed: false,
                user: O.some(user),
              },
            },
            user: O.some(user),
            dialog: O.none,
            clientStatus: O.some(clientStatus),
          },
          effectsBatch([
            effectOfAction(InviteAction(Invite.ConsentsInit())),
            effectOfAction(InviteAction(flow(Started, Invite.LoadTips)())),
          ]),
        ]);
      }
    }),
  );
}

export function toDashboardPage({
  user,
}: NavigationMeta): TE.TaskEither<ApiError, [Model, Effect<Action>]> {
  return TE.of([
    {
      ...init(),
      user: O.some(user),
    },
    effectsBatch([effectOfAction(pipe(DateTime.now(), DashboardSelected))]),
  ]);
}

export function toApplyNowPage({
  api,
  user,
}: NavigationMeta): TE.TaskEither<ApiError, [Model, Effect<Action>]> {
  return pipe(
    () => api.getBorrowerApplication(),
    TE.chain(() => {
      return RouteToHomeBuyer(api, user);
    }),
    TE.orElse(() => {
      return TE.of([
        {
          ...init(),
          user: O.some(user),
        },
        effectsBatch([effectOfAction(pipe(NewApplicationSelected()))]),
      ]);
    }),
  );
}

export function toInactiveBorrower({
  user,
  clientStatus,
}: NavigationMeta): TE.TaskEither<ApiError, [Model, Effect<Action>]> {
  return TE.of([
    {
      page: {
        type: PageTypes.InactiveBorrower,
        inactiveBorrowerModel: { user: O.some(user) },
      },
      user: O.some(user),
      dialog: O.none,
      clientStatus: O.some(clientStatus),
    },
    noEffect,
  ]);
}

export function toAccountSettingsPage({
  matches,
  user,
  routeMeta: { accessPredicate },
}: NavigationMeta): TE.TaskEither<ApiError, [Model, Effect<Action>]> {
  if (
    accessPredicate &&
    !accessPredicate({ user, matches } as NavigationWithApplication)
  ) {
    return TE.of([
      {
        ...init(),
        user: O.some(user),
      },
      effectsBatch([
        effectOfAction(pipe(AccountSettingsSelected(user))),
        effectOfAction(
          AccountSettingsAction(AccountSettings.GeneralSelected()),
        ),
      ]),
    ]);
  }
  return TE.of([
    {
      ...init(),
      user: O.some(user),
    },
    effectsBatch([
      effectOfAction(pipe(AccountSettingsSelected(user))),
      effectOfAction(
        AccountSettingsAction(
          pipe(matches["subPage"], (page) => {
            switch (page) {
              case "billing":
                return AccountSettings.BillingSelected();
              case "general":
                return AccountSettings.GeneralSelected();
              case "notifications":
                return AccountSettings.NotificationsSelected();
              default:
                return AccountSettings.GeneralSelected();
            }
          }),
        ),
      ),
    ]),
  ] as [Model, Effect<Action>]);
}
export function toBranchesPage({
  matches,

  user,
}: NavigationMeta): TE.TaskEither<ApiError, [Model, Effect<Action>]> {
  const childAction = pipe(matches["subPage"], (page) => {
    const modelId = Number(matches["modelId"]);
    switch (page) {
      case "branch":
        return UserManagement.BranchSelected(modelId as BranchId);
      case "team":
        return UserManagement.TeamSelected(modelId as TeamId);
      case "user":
        return UserManagement.UserSelected(modelId as UserId);
      case "client":
      default:
        console.log("Got empty subpage for user-management");

        // return
        return null;
    }
  });

  let newActions = [];

  if (childAction == null) {
    newActions = [effectOfAction(pipe(UserManagementSelected()))];
  } else {
    newActions = [
      effectOfAction(
        pipe(UserManagementSelected(() => effectOfAction(childAction))),
      ),
    ];
  }

  return TE.of([
    {
      ...init(),
      user: O.some(user),
    },
    effectsBatch(newActions),
  ] as [Model, Effect<Action>]);
}

export function tryCompleteSurvey(
  result: AuthenticationResult | null,
  api: Api,
): TE.TaskEither<ApiError, O.Option<[User, ClientStatusResponse]>> {
  return pipe(
    O.fromNullable(result),
    O.chain((authResult) => {
      const state = authResult.state as string;

      return pipe(
        getFromLocalStorage(LocalStoragetKeys.BorrowerNonce),
        O.chain((v) => {
          if (v == state) {
            return getFromLocalStorage(LocalStoragetKeys.BorrowerSignup);
          }
          return O.none;
        }),
        O.chain((v) => {
          const value = JSON.parse(v);
          const surveyPayload = surveyPayloadCodec.decode(value);
          let returnValue: O.Option<SurveyPayload> = O.none;

          //TODO: To check for roles claims absense.
          if (E.isRight(surveyPayload)) {
            if (
              surveyPayload.right.primaryApplicant.email ===
              get(authResult.idTokenClaims, "emails.[0]")
            ) {
              returnValue = O.some(surveyPayload.right);
            }
          }

          removeFromLocalStorage(LocalStoragetKeys.BorrowerNonce);
          removeFromLocalStorage(LocalStoragetKeys.BorrowerSignup);

          return returnValue;
        }),
      );
    }),
    TE.fromOption(() => ExceptionError("")),
    TE.chain((payload) => api.saveSurvey(payload)),
    TE.chain(() => userInfoAndClientStatus(api)),
    TE.chain((payload) => {
      return TE.left(BorrowerSignupRedirectException(payload));
    }),
  );
}

export function tryCompleteBackOfficeSignin(
  result: AuthenticationResult | null,
  api: Api,
): TE.TaskEither<ApiError, O.Option<[User, ClientStatusResponse]>> {
  return pipe(
    O.fromNullable(result),
    O.chain((authResult) => {
      const state = O.tryCatch(
        () => JSON.parse(authResult.state as string).nonce,
      );

      return pipe(
        getFromLocalStorage(LocalStoragetKeys.BackOfficeNonce),
        O.chain((savedNonce) => {
          if (O.isSome(state) && savedNonce == state.value) {
            return getFromLocalStorage(LocalStoragetKeys.BackOfficeSignup);
          }
          return O.none;
        }),
        O.chain((v) => {
          const value = JSON.parse(v);
          const createClientPayload = createClientPayloadCodec.decode(value);
          let returnValue: O.Option<CreateClientPayload> = O.none;

          //TODO: To check for roles claims absense.
          if (E.isRight(createClientPayload)) {
            if (
              createClientPayload.right.email ===
              get(authResult.idTokenClaims, "emails.[0]")
            ) {
              returnValue = O.some(createClientPayload.right);
            }
          }

          removeFromLocalStorage(LocalStoragetKeys.BackOfficeNonce);
          removeFromLocalStorage(LocalStoragetKeys.BackOfficeSignup);

          return returnValue;
        }),
      );
    }),
    TE.fromOption(() => ExceptionError("")),
    TE.chain((payload) =>
      pipe(
        TE.of(PaymentSetupInfo),
        TE.ap(
          pipe(
            api.createClient(payload),
            TE.map(
              ({ createPaymentCustomerSubscriptionResult }) =>
                createPaymentCustomerSubscriptionResult.paymentMethodSetupClientSecret,
            ),
          ),
        ),
        TE.ap(loadStripeTask),
      ),
    ),
    TE.chain((v) => {
      return TE.left(BackOfficeSignupRedirectException(v));
    }),
  );
}
export function toApplicationListPage({
  api,
  user,
  clientStatus,
}: NavigationMeta): TE.TaskEither<ApiError, [Model, Effect<Action>]> {
  return pipe(
    api.getApplicationsList,
    TE.map((applications): [Model, Effect<Action>] => [
      {
        page: {
          type: "ApplicationsList",
          applicationsListModel: ApplicationsList.init(
            applications,
            O.some(clientStatus),
          ),
        },
        user: O.some(user),
        dialog: O.none,
        clientStatus: O.some(clientStatus),
      },
      noEffect,
    ]),
  );
}

// make a chain of api calls
// and based on the returned data
// initialize the appropriate page model
// and effects (which may be produced by the initialization of some pages)
export const initPageTask =
  (api: Api) =>
  (
    user: User,
    clientStatus: ClientStatusResponse,
  ): TE.TaskEither<ApiError, [Model, Effect<Action>]> => {
    return S.elem(EqExtensionRole)(ExtensionRole.LoanOfficer)(user.roles) ||
      S.elem(EqExtensionRole)(ExtensionRole.SuperUser)(user.roles)
      ? toDashboardPage({ user, clientStatus } as NavigationMeta)
      : RouteToHomeBuyer(api, user);
  };

export const userInfoAndClientStatus = (
  api: Api,
): TE.TaskEither<ApiError, O.Option<[User, ClientStatusResponse]>> =>
  pipe(
    sequenceT(TE.ApplyPar)(api.tryGetUser, api.getClientStatus()),
    TE.chain(
      ([user, clientStatus]): TE.TaskEither<
        ApiError,
        O.Option<[User, ClientStatusResponse]>
      > => {
        return pipe(user, (user) =>
          O.isNone(user)
            ? TE.of(O.none)
            : TE.of<ApiError, O.Option<[User, ClientStatusResponse]>>(
                O.some([user.value, clientStatus]),
              ),
        );
      },
    ),
  );
