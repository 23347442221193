import { User } from "@/data/client";
import * as S from "fp-ts/Set";
import { EqExtensionRole, ExtensionRole } from "@/data/client";

export const isSuperUser = (user: User) =>
  S.elem(EqExtensionRole)(ExtensionRole.SuperUser)(user.roles) ? true : false;

export const isLoanOfficer = (user: User) =>
  S.elem(EqExtensionRole)(ExtensionRole.LoanOfficer)(user.roles) ? true : false;


export const hasNoRoles = (user: User) =>
    S.size(user.roles) == 0;
  
export const isBackOfficeUser = (user: User) =>
  isSuperUser(user) || isLoanOfficer(user);
