import { stringEnumCodec } from "@/utils/codecs";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import * as Ord from "fp-ts/lib/Ord";
import * as O from "fp-ts/lib/Option";
import { Eq } from "fp-ts/lib/Eq";
import { Eq as EqString } from "fp-ts/lib/string";
import { Ord as OrdNumber } from "fp-ts/lib/number";
import { IconAlign, TagTypes } from "@/components/basic/Tag";
import { IconType } from "@/components/basic";
import { Option } from "fp-ts/lib/Option";

interface ApplicantIdBrand {
  readonly ApplicantId: unique symbol;
}

export const applicantIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, ApplicantIdBrand> => true,
  "ApplicantId",
);

export type ApplicantId = t.TypeOf<typeof applicantIdCodec>;

interface RequirementIdBrand {
  readonly RequirementId: unique symbol;
}

export const requirementIdCodec = t.brand(
  tt.NonEmptyString,
  (_): _ is t.Branded<tt.NonEmptyString, RequirementIdBrand> => true,
  "RequirementId",
);

export type RequirementId = t.TypeOf<typeof requirementIdCodec>;

export const EqRequirementId: Eq<RequirementId> = EqString;

interface DocumentIdBrand {
  readonly DocumentId: unique symbol;
}

export const documentIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, DocumentIdBrand> => true,
  "DocumentId",
);

export type DocumentId = t.TypeOf<typeof documentIdCodec>;


interface DocumentTypeIdBrand {
  readonly DocumentTypeId: unique symbol;
}

export const documentTypeIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, DocumentTypeIdBrand> => true,
  "DocumentTypeId",
);

export type DocumentTypeId = t.TypeOf<typeof documentTypeIdCodec>;


export enum DocumentStatus {
  UploadRequired = "UploadRequired",
  Error = "Error",
  Processing = "Processing",
  Validated = "Validated",
  Extra = "Extra",
  Submitted = "Submitted"
}

const documentStatusOrder = (status: DocumentStatus): number => {
  switch (status) {
    case DocumentStatus.UploadRequired:
      return 0;
    case DocumentStatus.Error:
      return 1;
    case DocumentStatus.Processing:
      return 2;
    case DocumentStatus.Validated:
      return 3;
    case DocumentStatus.Extra:
      return 4;
    case DocumentStatus.Submitted:
        return 5;
  }
};

export const OrdDocumentStatus = Ord.contramap(documentStatusOrder)(OrdNumber);

export const documentStatusCodec = stringEnumCodec<typeof DocumentStatus>(
  DocumentStatus,
  "DocumentStatus",
);

export function showDocumentStatus(status: DocumentStatus): string {
  switch (status) {
    case DocumentStatus.UploadRequired:
      return "Upload Required";
    case DocumentStatus.Processing:
      return "Processing";
    case DocumentStatus.Validated:
      return "Validated";
    case DocumentStatus.Error:
      return "Error";
    case DocumentStatus.Extra:
      return "Extra";
    case DocumentStatus.Submitted:
        return "Submitted";
  }
}

export function getTagType(status: DocumentStatus): TagTypes {
  switch (status) {
    case DocumentStatus.UploadRequired:
      return "neutral";
    case DocumentStatus.Processing:
      return "info";
    case DocumentStatus.Validated:
    case DocumentStatus.Submitted:
      return "success";
    case DocumentStatus.Error:
      return "error";
    case DocumentStatus.Extra:
      return "accent";
  }
}

export function getIconType(status: DocumentStatus): Option<IconType> {
  switch (status) {
    case DocumentStatus.UploadRequired:
    case DocumentStatus.Processing:
      return O.none;

    case DocumentStatus.Validated:
    case DocumentStatus.Submitted:
      return O.some("circle-check");
    case DocumentStatus.Error:
      return O.some("info");
    case DocumentStatus.Extra:
      return O.some("circle");
  }
}

export function getIconAlign(status: DocumentStatus): IconAlign {
  switch (status) {
    case DocumentStatus.UploadRequired:
    case DocumentStatus.Processing:
      return "none";

    case DocumentStatus.Validated:
    case DocumentStatus.Submitted:
      return "left";
    case DocumentStatus.Error:
    case DocumentStatus.Extra:
      return "right";
  }
}

documentTypeIdCodec

export const applicationDocumentCodec = t.type({
  requirementId: requirementIdCodec,
  applicantId: applicantIdCodec,
  applicantName: tt.NonEmptyString,
  canTakePhoto: t.boolean,
  documentId: tt.optionFromNullable(documentIdCodec),
  documentName: tt.NonEmptyString,
  documentSubCaption: tt.optionFromNullable(t.string),
  documentTip: tt.optionFromNullable(t.string),
  errorMessage: tt.optionFromNullable(t.string),
  isHardRequirement: t.boolean,
  originalFilename: tt.optionFromNullable(tt.NonEmptyString),
  status: t.string.pipe(documentStatusCodec),
  documentTypeId:  documentTypeIdCodec
});

export type ApplicationDocument = t.TypeOf<typeof applicationDocumentCodec>;
