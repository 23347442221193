import "@/assets/styles/basic/Button/button.css";
import { pipe } from "fp-ts/lib/function";
import { Option, isNone, isSome } from "fp-ts/lib/Option";
import { ReactNode } from "react";
import * as O from "fp-ts/lib/Option";
import { TooltipWrapper } from "../Tooltip/TooltipWrapper";

export type ButtonProps = {
  type: "primary" | "secondary" | "tertiary" | "flat" | "inline-inverted";
  children: ReactNode;
  className?: string;
  onClick: Option<() => void>;
  disabledTooltip?: string;
};

export function Button(props: ButtonProps): JSX.Element {
  const button = (
    <button
      className={`button button-${props.type} text-md ${props.className ? props.className : ""}`}
      disabled={isNone(props.onClick)}
      onClick={() =>
        isSome(props.onClick) ? props.onClick.value() : undefined
      }
    >
      {props.children}
    </button>
  );

  const parent = pipe(
    props.disabledTooltip,
    O.fromNullable,
    O.chain(v => O.isNone(props.onClick) ? O.some(v): O.none),
    O.fold(
      () => button,
      (disabledMessage) => {
        return (
       
          <TooltipWrapper
            tooltipChildren={O.some(<span>{disabledMessage}</span>)}
            purpose="tooltip"
          >
            {button}
          </TooltipWrapper>
        );
      },
    ),
  );
  return <>{parent}</>;
}
