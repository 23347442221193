import { ReactNode } from "react";
import "@/assets/styles/basic/Label/label.css";
export type LabelProps = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  htmlFor?: string
};

export function Label(props: LabelProps): JSX.Element {
  return (
    <label
      className={`label text-md ${props.className || ""} ${props.htmlFor ? 'cp' : ''}`}
      onClick={props.onClick}
      htmlFor={props.htmlFor}
    >
      {props.children}
    </label>
  );
}
