import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Icon,
  IconType,
  Input,
  Label,
  Modal,
  Row,
  Table,
  TableColumn,
} from "@/components/basic";
import { TooltipWrapper } from "@/components/basic/Tooltip/TooltipWrapper";
import {
  ApplicationListItem,
  EqApplicationStatus,
} from "@/data/applicationsList";
import { EqUserId, UserId } from "@/data/client";
import {
  ApplicationId,
  ApplicationStatusType,
  ApplicationType,
  EqApplicationType,
} from "@/data/payload";
import { Started } from "@/utils/asyncOperationStatus";
import { nesHead } from "@/utils/codecs";
import { ChildProps } from "@/utils/reducerWithEffect";
import { showApiError } from "@/utils/request";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as Ord from "fp-ts/lib/Ord";
import * as S from "fp-ts/lib/Set";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApplicationProgress } from "../ApplicationProgressBar";
import { Tag } from "../basic/Tag";
import {
  AcceptExportWarning,
  Action,
  AssigneeFilterAllSelected,
  AssigneeFilterToggled,
  CreditCheckAuthorizedFilterAllSelected,
  CreditCheckAuthorizedFilterToggled,
  ExportDocuments,
  MortgageTypeFilterAllSelected,
  MortgageTypeFilterToggled,
  ResetExportStatus,
  SearchChanged,
  ShowExportWarning,
  SortChanged,
  StatusFilterAllSelected,
  StatusFilterToggled,
  SubmissionDateFilterAllSelected,
  SubmissionDateFilterSelected,
} from "./action";
import {
  EqSubmissionDateFilterOption,
  Model,
  SubmissionDateFilterOption,
  applyAssigneeFilter,
  applyCreditCheckAuthorizedFilter,
  applyMortgageTypeFilter,
  applySearch,
  applyStatusFilter,
  applySubmissionDateFilter,
} from "./model";
import { GetDeviceInfo } from "@/utils/viewResolver";
import { Ord as OrdString } from "fp-ts/string";
import { Ord as OrdNumber } from "fp-ts/lib/number";
import { OptionMenu } from "../basic/Menu/OptionMenu";

export type Props = ChildProps<Model, Action> & {
  onApplicationSelected: (applicationId: ApplicationId) => void;
};

const OrdStatusFilter: Ord.Ord<ApplicationStatusType> = OrdString;
const OrdApplicationTypeFilter: Ord.Ord<ApplicationType> = OrdString;
const OrdAssigneFilter: Ord.Ord<UserId> = OrdNumber;

export function View(props: Props): JSX.Element {
  const { dispatch, model } = props;

  const deviceInfo = GetDeviceInfo();
  console.log(deviceInfo);

  const assignees = useMemo(
    () =>
      pipe(
        model.applications,
        A.chain((app): [UserId, string][] =>
          app.assignees.map(({ userId, firstName, lastName }) => [
            userId,
            `${nesHead(firstName)} ${lastName}`,
          ]),
        ),
        A.uniq(
          Eq.contramap(([userId, _name]: [UserId, string]) => userId)(EqUserId),
        ),
        A.sort(
          Ord.contramap(([_userId, name]: [UserId, string]) => name)(OrdString),
        ),
      ),
    [model.applications],
  );

  const isStatusSelected = useCallback(
    (status: ApplicationStatusType) =>
      pipe(
        model.statusFilter,
        O.filter(S.elem(EqApplicationStatus)(status)),
        O.isSome,
      ),
    [model.statusFilter],
  );

  const statusCheckbox = useCallback(
    (status: ApplicationStatusType) => (
      <Checkbox
        label={status}
        checked={isStatusSelected(status)}
        onChange={flow(constant(status), StatusFilterToggled, dispatch)}
      />
    ),
    [isStatusSelected, dispatch],
  );

  const isAuthorizationSelected = useCallback(
    (isAuthorized: boolean) =>
      pipe(
        model.creditCheckAuthorizedFilter,
        O.filter((b) => b === isAuthorized),
        O.isSome,
      ),
    [model.creditCheckAuthorizedFilter],
  );

  const creditCheckAuthorizedCheckbox = useCallback(
    (isAuthorized: boolean) => (
      <Checkbox
        label={isAuthorized ? "Authorized" : "Not authorized"}
        checked={isAuthorizationSelected(isAuthorized)}
        onChange={flow(
          constant(isAuthorized),
          CreditCheckAuthorizedFilterToggled,
          dispatch,
        )}
      />
    ),
    [isAuthorizationSelected, dispatch],
  );

  const isMortgageTypeSelected = useCallback(
    (mortgageType: ApplicationType) =>
      pipe(
        model.mortgageTypeFilter,
        O.filter(S.elem(EqApplicationType)(mortgageType)),
        O.isSome,
      ),
    [model.mortgageTypeFilter],
  );

  const mortgageTypeCheckbox = useCallback(
    (mortgageType: ApplicationType) => (
      <Checkbox
        label={mortgageType}
        checked={isMortgageTypeSelected(mortgageType)}
        onChange={flow(
          constant(mortgageType),
          MortgageTypeFilterToggled,
          dispatch,
        )}
      />
    ),
    [isMortgageTypeSelected, dispatch],
  );

  const isSubmissionDateOptionSelected = useCallback(
    (opt: SubmissionDateFilterOption) =>
      pipe(
        model.submissionDateFilter,
        O.filter((o) => EqSubmissionDateFilterOption.equals(o, opt)),
        O.isSome,
      ),
    [model.submissionDateFilter],
  );

  const submissionDateOptionCheckbox = useCallback(
    (opt: SubmissionDateFilterOption) => (
      <Checkbox
        label={opt}
        checked={isSubmissionDateOptionSelected(opt)}
        onChange={flow(constant(opt), SubmissionDateFilterSelected, dispatch)}
      />
    ),
    [isSubmissionDateOptionSelected, dispatch],
  );

  const isAssigneeSelected = useCallback(
    (userId: UserId) =>
      pipe(model.assigneeFilter, O.filter(S.elem(EqUserId)(userId)), O.isSome),
    [model.assigneeFilter],
  );

  const filteredItems: ApplicationListItem[] = useMemo(
    () =>
      pipe(
        model.applications,
        applySearch(model.search),
        applyStatusFilter(model.statusFilter),
        applyCreditCheckAuthorizedFilter(model.creditCheckAuthorizedFilter),
        applyMortgageTypeFilter(model.mortgageTypeFilter),
        applySubmissionDateFilter(model.submissionDateFilter),
        applyAssigneeFilter(model.assigneeFilter),
      ),
    [
      model.applications,
      model.search,
      model.statusFilter,
      model.creditCheckAuthorizedFilter,
      model.mortgageTypeFilter,
      model.submissionDateFilter,
      model.assigneeFilter,
    ],
  );

  const [btnFilterOpen, setbtnFilterOpen] = useState(false);
  const btnFilterRefContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (btnFilterOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        const clickedInsideContainer =
          btnFilterRefContainer.current &&
          btnFilterRefContainer.current.contains(event.target as Node);

        if (!clickedInsideContainer) {
          setbtnFilterOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [btnFilterRefContainer, btnFilterOpen]);

  const exportingModal = pipe(
    model.exportStatus,
    O.fromPredicate((exportStatus) =>
      ["InProgress", "Resolved"].includes(exportStatus.status),
    ),
    O.map((exportStatus) => {
      switch (exportStatus.status) {
        case "InProgress":
          return (
            <Modal onClose={O.none} title={O.some("Export Status")}>
              Please wait while we are exporting your application.
            </Modal>
          );
        case "Resolved": {
          const { value } = exportStatus;
          if (E.isLeft(value)) {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Status")}
              >
                There was an error exporting the documents.
                {showApiError(value.left)}
              </Modal>
            );
          } else {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Status")}
              >
                Successfuly exported the application!
              </Modal>
            );
          }
          return <></>;
        }
        default:
          return <></>;
      }
    }),
    O.getOrElse(() => <></>),
  );

  return (
    <Col padding="sm" gap="md">
      {exportingModal}
      {
        //show warning on 'incomplete' status exports
        pipe(
          model.exportWarningShown,
          O.chain(([v]) =>
            pipe(
              v,
              O.fromPredicate((v) => !v),
            ),
          ),
          O.map(() => (
            <Modal
              onClose={O.none}
              title={O.some(
                "This application is incomplete. You may continue with export, but much of the data will be missing from the exported data",
              )}
            >
              <Button
                type="primary"
                onClick={O.some(flow(AcceptExportWarning, dispatch))}
              >
                Ok
              </Button>
            </Modal>
          )),
          O.getOrElse(() => <></>),
        )
      }

      <Row alignVertical="center" gap="md">
        <Col alignVertical="stretch" className="pos-rel" grow={1}>
          <i className="fa-solid fa fa-search ac-search-icon"></i>
          <Input
            type="text"
            placeholder="Search"
            onChange={flow(SearchChanged, dispatch)}
            value={model.search}
            className="ac-search-box"
          />
        </Col>
        <Col>
          {pipe(
            O.some(deviceInfo),
            O.map((deviceInfo) => {
              switch (deviceInfo) {
                case "Mobile-Portrait":
                case "Tablet-Portrait":
                case "Tablet-Landscape":
                  return (
                    <div
                      className={`filter-container ${btnFilterOpen ? "filter-active" : ""}`}
                      ref={btnFilterRefContainer}
                      onClick={() => setbtnFilterOpen(!btnFilterOpen)}
                    >
                      <i className={`fa-solid fa-filter`}></i>
                      {btnFilterOpen && (
                        <Col
                          className="filter-values-container"
                          alignHorizontal="right"
                          alignVertical="center"
                        >
                          <Col padding="lg" className="filter-content" gap="md">
                            <Row>
                              <Col gap="xs">
                                <Label>Filter Status</Label>
                                <Checkbox
                                  label="All"
                                  checked={O.isNone(model.statusFilter)}
                                  onChange={flow(
                                    StatusFilterAllSelected,
                                    dispatch,
                                  )}
                                />
                                {Object.values(ApplicationStatusType).map(
                                  (v) => {
                                    return statusCheckbox(v);
                                  },
                                )}
                              </Col>
                            </Row>
                            {/* <Row>
                                <div className="divider"></div>
                              </Row> */}
                            <div className="divider"></div>
                            <Row>
                              <Col padding="xs" gap="xs">
                                <Checkbox
                                  label="All"
                                  checked={O.isNone(model.mortgageTypeFilter)}
                                  onChange={flow(
                                    MortgageTypeFilterAllSelected,
                                    dispatch,
                                  )}
                                />
                                {mortgageTypeCheckbox(ApplicationType.Purchase)}
                                {mortgageTypeCheckbox(ApplicationType.Equity)}
                                {mortgageTypeCheckbox(
                                  ApplicationType.Refinance,
                                )}
                              </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                              <Col padding="xs" gap="xs">
                                <Checkbox
                                  label="All"
                                  checked={O.isNone(model.submissionDateFilter)}
                                  onChange={flow(
                                    SubmissionDateFilterAllSelected,
                                    dispatch,
                                  )}
                                />
                                {submissionDateOptionCheckbox(
                                  SubmissionDateFilterOption.Last24Hours,
                                )}
                                {submissionDateOptionCheckbox(
                                  SubmissionDateFilterOption.LastWeek,
                                )}
                                {submissionDateOptionCheckbox(
                                  SubmissionDateFilterOption.Last2Weeks,
                                )}
                                {submissionDateOptionCheckbox(
                                  SubmissionDateFilterOption.LastMonth,
                                )}
                              </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                              <Col padding="xs" gap="xs">
                                <Checkbox
                                  label="All"
                                  checked={O.isNone(model.assigneeFilter)}
                                  onChange={flow(
                                    AssigneeFilterAllSelected,
                                    dispatch,
                                  )}
                                />
                                {pipe(
                                  assignees,
                                  A.map(([userId, name]) => (
                                    <Checkbox
                                      key={userId}
                                      label={name}
                                      checked={isAssigneeSelected(userId)}
                                      onChange={flow(
                                        constant(userId),
                                        AssigneeFilterToggled,
                                        dispatch,
                                      )}
                                    />
                                  )),
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      )}
                    </div>
                  );
                default:
                  return <></>;
              }
            }),
            O.getOrElse(() => <></>),
          )}
        </Col>
      </Row>
      {pipe(
        O.some(deviceInfo),
        O.map((deviceInfo) => {
          switch (deviceInfo) {
            case "Mobile-Portrait":
            case "Tablet-Portrait":
            case "Tablet-Landscape":
              return <></>;
            case "Desktop-Landscape":
              return (
                <Row gap="sm" alignVertical="top">
                  <Col gap="xs">
                    <Dropdown label="Filter by status">
                      <Col>
                        <Col padding="xs" gap="xs">
                          <Checkbox
                            label="All"
                            checked={O.isNone(model.statusFilter)}
                            onChange={flow(StatusFilterAllSelected, dispatch)}
                          />
                          {Object.values(ApplicationStatusType).map((v) => {
                            return statusCheckbox(v);
                          })}
                        </Col>
                      </Col>
                    </Dropdown>
                    <Row
                      gap="xs"
                      wrap={true}
                      className="ac-statuss-filter-chips ml-left-sm"
                    >
                      {O.isSome(model.statusFilter) && (
                        <>
                          {pipe(
                            model.statusFilter.value,
                            S.toArray(OrdStatusFilter),
                            A.map((status) => {
                              return (
                                <Tag
                                  type="neutral"
                                  iconAlign="none"
                                  icon={O.some("info")}
                                  className="text-xs font-weight-500"
                                >
                                  {status}
                                  <i
                                    onClick={flow(
                                      constant(status),
                                      StatusFilterToggled,
                                      dispatch,
                                    )}
                                    className="fa-solid fa-close ml-left-xxs"
                                  ></i>
                                </Tag>
                              );
                            }),
                          )}
                        </>
                      )}
                    </Row>
                  </Col>

                  <Col gap="xs">
                    <Dropdown label="Filter by authorization">
                      <Col padding="xs" gap="xs">
                        <Checkbox
                          label="All"
                          checked={O.isNone(model.creditCheckAuthorizedFilter)}
                          onChange={flow(
                            CreditCheckAuthorizedFilterAllSelected,
                            dispatch,
                          )}
                        />
                        {creditCheckAuthorizedCheckbox(true)}
                        {creditCheckAuthorizedCheckbox(false)}
                      </Col>
                    </Dropdown>
                    <Row gap="xs">
                      {pipe(
                        model.creditCheckAuthorizedFilter,
                        O.fold(
                          () => <></>,
                          (value) => {
                            return (
                              <Tag
                                type="neutral"
                                iconAlign="none"
                                icon={O.some("info")}
                                className="text-xs font-weight-500"
                              >
                                {value ? "Authorized" : "Not authorized"}
                                <i
                                  onClick={flow(
                                    constant(value),
                                    CreditCheckAuthorizedFilterToggled,
                                    dispatch,
                                  )}
                                  className="fa-solid fa-close ml-left-xxs"
                                ></i>
                              </Tag>
                            );
                          },
                        ),
                      )}
                    </Row>
                  </Col>
                  <Col gap="xs">
                    <Dropdown label="Filter by mortgage type">
                      <Col padding="xs" gap="xs">
                        <Checkbox
                          label="All"
                          checked={O.isNone(model.mortgageTypeFilter)}
                          onChange={flow(
                            MortgageTypeFilterAllSelected,
                            dispatch,
                          )}
                        />
                        {mortgageTypeCheckbox(ApplicationType.Purchase)}
                        {mortgageTypeCheckbox(ApplicationType.Equity)}
                        {mortgageTypeCheckbox(ApplicationType.Refinance)}
                      </Col>
                    </Dropdown>
                    <Row gap="xxs" wrap={true} className="ml-left-sm">
                      {O.isSome(model.mortgageTypeFilter) && (
                        <>
                          {pipe(
                            model.mortgageTypeFilter.value,
                            S.toArray(OrdApplicationTypeFilter),
                            A.map((appType) => {
                              return (
                                <Tag
                                  type="neutral"
                                  iconAlign="none"
                                  icon={O.some("info")}
                                  className="text-xs font-weight-500"
                                >
                                  {appType}
                                  <i
                                    onClick={flow(
                                      MortgageTypeFilterAllSelected,
                                      dispatch,
                                    )}
                                    className="fa-solid fa-close ml-left-xxs"
                                  ></i>
                                </Tag>
                              );
                            }),
                          )}
                        </>
                      )}
                    </Row>
                  </Col>

                  <Col gap="xs">
                    <Dropdown label="Filter by submission date">
                      <Col padding="xs" gap="xs">
                        <Checkbox
                          label="All"
                          checked={O.isNone(model.submissionDateFilter)}
                          onChange={flow(
                            SubmissionDateFilterAllSelected,
                            dispatch,
                          )}
                        />
                        {submissionDateOptionCheckbox(
                          SubmissionDateFilterOption.Last24Hours,
                        )}
                        {submissionDateOptionCheckbox(
                          SubmissionDateFilterOption.LastWeek,
                        )}
                        {submissionDateOptionCheckbox(
                          SubmissionDateFilterOption.Last2Weeks,
                        )}
                        {submissionDateOptionCheckbox(
                          SubmissionDateFilterOption.LastMonth,
                        )}
                      </Col>
                    </Dropdown>
                    <Row gap="xxs" wrap={true} className="ml-left-sm">
                      {O.isSome(model.submissionDateFilter) && (
                        <>
                          <Tag
                            type="neutral"
                            iconAlign="none"
                            icon={O.some("info")}
                            className="text-xs font-weight-500"
                          >
                            {model.submissionDateFilter.value}
                            <i
                              onClick={flow(
                                SubmissionDateFilterAllSelected,
                                dispatch,
                              )}
                              className="fa-solid fa-close ml-left-xxs"
                            ></i>
                          </Tag>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col gap="xs">
                    <Dropdown label="Filter by assignee">
                      <Col padding="xs" gap="xs">
                        <Checkbox
                          label="All"
                          checked={O.isNone(model.assigneeFilter)}
                          onChange={flow(AssigneeFilterAllSelected, dispatch)}
                        />
                        {pipe(
                          assignees,
                          A.map(([userId, name]) => (
                            <Checkbox
                              key={userId}
                              label={name}
                              checked={isAssigneeSelected(userId)}
                              onChange={flow(
                                constant(userId),
                                AssigneeFilterToggled,
                                dispatch,
                              )}
                            />
                          )),
                        )}
                      </Col>
                    </Dropdown>

                    <Row gap="xxs" wrap={true} className="ml-left-sm">
                      {O.isSome(model.assigneeFilter) && (
                        <>
                          {pipe(
                            model.assigneeFilter.value,
                            S.toArray(OrdAssigneFilter),
                            A.map((assignee) => {
                              return (
                                <Tag
                                  type="neutral"
                                  iconAlign="none"
                                  icon={O.some("info")}
                                  className="text-xs font-weight-500"
                                >
                                  {/* TODO: Display username instead of userId*/}
                                  {assignee}
                                  <i
                                    onClick={flow(
                                      constant(assignee),
                                      AssigneeFilterToggled,
                                      dispatch,
                                    )}
                                    className="fa-solid fa-close ml-left-xxs"
                                  ></i>
                                </Tag>
                              );
                            }),
                          )}
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
              );
            default:
              return <></>;
          }
        }),
        O.getOrElse(() => <></>),
      )}
      <Table
        className="data-table applications-table"
        data={filteredItems}
        itemKey={(app) => app.applicationId}
        columns={GetColumns(deviceInfo, props)}
        onSortedChanged={(column: string, dir) =>
          flow(constant(SortChanged(column, dir)), dispatch)()
        }
      />
    </Col>
  );
}

function GetColumns(
  deviceInfo: string,
  props: Props,
): TableColumn<ApplicationListItem>[] {
  return pipe(
    O.some(deviceInfo),
    O.map((deviceInfo) => {
      switch (deviceInfo) {
        case "Mobile-Portrait":
          return MobileTableView(props);
        case "Mobile-Landscape":
          return TabletTableView(props);
        case "Tablet-Portrait":
        case "Tablet-Landscape":
          return TabletTableView(props);
        case "Desktop-Landscape":
          return DesktopTableView(props);
        default:
          return DesktopTableView(props);
      }
    }),
    O.getOrElse(() => DesktopTableView(props)),
  );
}

const getClientNameForProflieId = (name: string) => {
  if (!name) {
    return "";
  }
  const spilttedName = name.split(" ");
  const hasLastName = spilttedName.length !== 1;
  if (hasLastName) {
    return `${spilttedName[0].charAt(0).toUpperCase()}${spilttedName[spilttedName.length - 1].charAt(0).toUpperCase()}`;
  } else {
    return spilttedName[0].charAt(0).toUpperCase();
  }
};

const DesktopTableView = (props: Props): TableColumn<ApplicationListItem>[] => {
  const { dispatch } = props;
  return [
    {
      columnKey: "client",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Client</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Row
          padding="sm"
          gap="xs"
          className="data-table-cell cell-first client-column"
          alignVertical="center"
          onClick={() => props.onApplicationSelected(app.applicationId)}
        >
          <Col>
            <div className="client-id">
              {getClientNameForProflieId(app.applicantsName)}
            </div>
          </Col>
          <Col>
            <Row>
              <Label className="client-name cp">{app.applicantsName}</Label>
            </Row>
            <Row>
              <Label className="client-description cp">RR --</Label>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      columnKey: "status",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Status</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col padding="sm" gap="xl" className="data-table-cell">
          <ApplicationProgress
            status={app.status}
            showProgressBar={true}
          ></ApplicationProgress>
        </Col>
      ),
    },
    {
      columnKey: "creditCheck",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Credit check</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          <Label className="black cp">
            {app.isCreditCheckAuthorized ? (
              <>
                Authorized <i className="fa-solid fa fa-check ml-left-xxs"></i>
              </>
            ) : (
              "-"
            )}
          </Label>
        </Col>
      ),
    },
    {
      columnKey: "mortgageType",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Mortgage type</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell mortagage-type-column"
          alignVertical="center"
        >
          <Tag
            type="neutral"
            iconAlign="none"
            icon={O.some("info")}
            className="text-sm font-weight-500"
          >
            {app.mortgageType}
          </Tag>
        </Col>
      ),
    },
    {
      columnKey: "submitted",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Submitted</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          <Tag
            type="neutral"
            iconAlign="none"
            icon={O.some("info")}
            className="text-sm font-weight-500"
          >
            {app.dateSubmitted.toLocaleString(DateTime.DATE_MED)}
          </Tag>
        </Col>
      ),
    },
    {
      columnKey: "assignees",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Assignees</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          <Label className="font-weight-500 cp">
            <i className="fa-solid fa fa-user-o ml-right-xxs" />
            {app.assignees
              .map(
                (assignee) =>
                  `${nesHead(assignee.firstName)} ${assignee.lastName}`,
              )
              .join(", ")}
          </Label>
        </Col>
      ),
    },
    {
      columnKey: "actions",
      header: (
        <Row>
          <></>
        </Row>
      ),
      view: (application: ApplicationListItem) => {
        return (
          <Col
            alignHorizontal="right"
            alignVertical="center"
            className="data-table-cell cell-last actions-cell"
          >
            <OptionMenu
              menuItems={[
                {
                  icon: "eye" as IconType,
                  label: "View",
                  onClick: O.some(() => {}),
                },
                {
                  icon: "file-export" as IconType,
                  label: "Export",
                  onClick: O.some(() => {
                    if (
                      ![
                        ApplicationStatusType.Complete,
                        ApplicationStatusType.Exported,
                      ].includes(application.status)
                    ) {
                      return flow(
                        ShowExportWarning(application.applicationId),
                        dispatch,
                      )();
                    } else {
                      return flow(
                        Started,
                        ExportDocuments(application.applicationId),
                        dispatch,
                      )();
                    }
                  }),
                },
                {
                  icon: "user" as IconType,
                  label: "Assign",
                  onClick: O.some(() => {
                    alert("hai");
                  }),
                },
                {
                  icon: "trash-can" as IconType,
                  label: "Archive",
                  onClick: O.some(() => {}),
                },
              ]}
            ></OptionMenu>
          </Col>
        );
      },
    },
  ];
};

const TabletTableView = (props: Props): TableColumn<ApplicationListItem>[] => {
  const { dispatch } = props;
  return [
    {
      columnKey: "client",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Client</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Row
          padding="sm"
          gap="xs"
          className="data-table-cell cell-first client-column"
          alignVertical="center"
          onClick={() => props.onApplicationSelected(app.applicationId)}
        >
          <Col>
            <div className="client-id">
              {getClientNameForProflieId(app.applicantsName)}
            </div>
          </Col>
          <Col>
            <Row>
              <Label className="client-name cp">{app.applicantsName}</Label>
            </Row>
            <Row>
              <Label className="client-description cp">RR --</Label>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      columnKey: "status",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Status</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          <ApplicationProgress
            status={app.status}
            showProgressBar={false}
          ></ApplicationProgress>
        </Col>
      ),
    },
    {
      columnKey: "assignees",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Assignees</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          <Label className="font-weight-500 cp">
            <i className="fa-solid fa fa-user-o ml-right-xxs" />
            {app.assignees
              .map(
                (assignee) =>
                  `${nesHead(assignee.firstName)} ${assignee.lastName}`,
              )
              .join(", ")}
          </Label>
        </Col>
      ),
    },
    {
      columnKey: "actions",
      header: (
        <Row>
          <></>
        </Row>
      ),
      view: (application: ApplicationListItem) => {
        return (
          <Col
            alignHorizontal="right"
            alignVertical="center"
            className="data-table-cell cell-last actions-cell"
          >
            <TooltipWrapper
              purpose="menu"
              tooltipChildren={O.some(
                <Col alignHorizontal="left" gap="xs">
                  {[
                    {
                      icon: "eye" as IconType,
                      label: "View",
                      onClick: O.some(() => {}),
                    },
                    {
                      icon: "file-export" as IconType,
                      label: "Export",
                      onClick: O.some(() => {
                        if (
                          ![
                            ApplicationStatusType.Complete,
                            ApplicationStatusType.Exported,
                          ].includes(application.status)
                        ) {
                          return flow(
                            ShowExportWarning(application.applicationId),
                            dispatch,
                          )();
                        } else {
                          return flow(
                            Started,
                            ExportDocuments(application.applicationId),
                            dispatch,
                          )();
                        }
                      }),
                    },
                    {
                      icon: "user" as IconType,
                      label: "Assign",
                      onClick: O.some(() => {
                        alert("hai");
                      }),
                    },
                    {
                      icon: "trash-can" as IconType,
                      label: "Archive",
                      onClick: O.some(() => {}),
                    },
                  ].map(({ icon, label, onClick }, i) => {
                    const menuContent = (
                      <Row
                        alignHorizontal="space-between"
                        gap="xxs"
                        className="text-xs"
                      >
                        <Row
                          alignHorizontal="left"
                          gap="xxs"
                          grow={0}
                          wrap={false}
                        >
                          <Icon type={icon} />
                          <div>{label}</div>
                        </Row>
                        <Icon type="chevron-right" />
                      </Row>
                    );
                    return (
                      <Button
                        type="inline-inverted"
                        onClick={onClick}
                        key={i}
                        className="width-auto"
                      >
                        {menuContent}
                      </Button>
                    );
                  })}
                </Col>,
              )}
            >
              <Button type="flat" onClick={O.some(() => {})}>
                <i className="fa-solid fa-ellipsis-vertical action-icon"></i>
              </Button>
            </TooltipWrapper>
          </Col>
        );
      },
    },
  ];
};

const MobileTableView = (props: Props): TableColumn<ApplicationListItem>[] => {
  return [
    {
      columnKey: "application-detail",
      header: (
        <Row>
          <></>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Row
          alignVertical="center"
          gap="xs"
          className="data-table-cell mobile-column"
        >
          <Col gap="xxxs" alignVertical="center" grow={1}>
            <Row>
              <ApplicationProgress
                status={app.status}
                showProgressBar={false}
              ></ApplicationProgress>
            </Row>
            <Row>
              <Label className="client-name cp">{app.applicantsName}</Label>
            </Row>
            <Row>
              <Label className="date cp">
                {app.dateSubmitted.toLocaleString(DateTime.DATE_MED)}
              </Label>
            </Row>
          </Col>
          <Col onClick={() => props.onApplicationSelected(app.applicationId)}>
            <i className="fa-solid fa-arrow-right text-smd" />
          </Col>
        </Row>
      ),
    },
  ];
};
