import { ApplicationStatusType } from "@/data/payload";
import "@/assets/styles/ApplicationProgressBar/applicationProgressBar.css";
import { Col, Label, Row } from "@/components/basic";

export type Props = {
  status: ApplicationStatusType,
  showProgressBar: boolean,
}

const showStepProgress = (status: ApplicationStatusType): string => {
  switch (status) {
    case ApplicationStatusType.New:
    case ApplicationStatusType.Exported:
      return "0%";
    case ApplicationStatusType.Invited:
      return "10%"
    case ApplicationStatusType.SurveyIncomplete:
      return "20%";
    case ApplicationStatusType.Attention: 
      return "40%";
    case ApplicationStatusType.Error:
      return "50%";
    case ApplicationStatusType.InProgress:
      return "60%";
    case ApplicationStatusType.Complete:
      return "100%";
    case ApplicationStatusType.Archived:
      return "";
  }
};

const getLabelForApplicationStatus = (status: ApplicationStatusType): string => {
  switch (status) {
    case ApplicationStatusType.New:
      return "New";
    case ApplicationStatusType.Exported:
      return "LOS Exported";
    case ApplicationStatusType.Invited:
      return "Invited";
    case ApplicationStatusType.SurveyIncomplete:
      return "Survey incomplete";
    case ApplicationStatusType.Attention: 
      return "Attention";
    case ApplicationStatusType.Error:
      return "Error";
    case ApplicationStatusType.InProgress:
      return "In progress";
    case ApplicationStatusType.Complete:
      return "Complete";
    case ApplicationStatusType.Archived:
      return "Archived";
  }
}

export function ApplicationProgress(props: Props): JSX.Element {
  return (
    <Row gap="sm">
      <Col gap="xxs" grow={1} >
        <Label className="text-xs progress-label">{getLabelForApplicationStatus(ApplicationStatusType[props.status])} 
        <i className="fa-thin fa fa-circle-info ml-left-xxs"></i></Label>
        {props.status !== ApplicationStatusType.Archived && props.showProgressBar ? 
        <div className={`app-progress-item ${props.status.toLowerCase()}`}>
        <div
          className={`app-progress-bar ${props.status.toLowerCase()}`}
          style={{ width: showStepProgress(props.status) }}
        ></div>
      </div> : ''}
      </Col>
    </Row>
  );
}
