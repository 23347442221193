import { ReactNode } from "react";
import { Col } from "../Col";
import "@/assets/styles/basic/Card/card.css";


export type CardProps = {
  title: string;
  className?: string;

  children: ReactNode;  
};

export function Card(props: CardProps): JSX.Element {
  return (
    <Col
      className={`grid-3 gap-md text-md ${props.className || ""}`}      
    >
      <div className="text-lg">{props.title}</div>
      {props.children}
    </Col>
  );
}
