import { NonEmptyString, withMessage } from "io-ts-types";
import { FormField, initFormField } from "@/utils/formField";
import {
  PositiveInt,
  PositiveNumber,
  numberCodecS,
  positiveIntCodec,
  positiveNumberCodec,
} from "@/utils/codecs";
import { nonEmptyStringCodec } from "@/utils/codecs/nonEmptyString";
import * as t from "io-ts";
import {
  AddressPayload,
  LivingArrangement,
  LivingArrangementType,
} from "@/data/payload";
import * as E from "fp-ts/lib/Either";
import { sequenceS } from "fp-ts/lib/Apply";
import { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { ZipCode, zipCodeCodecWithMessage } from "@/utils/codecs/zipCode";
import { stateCodec, USState } from "@/data/states";
import { ValidErrorMessage } from "../basic";

export type Model = {
  street: FormField<NonEmptyString>;
  unit: Option<NonEmptyString>;
  city: FormField<NonEmptyString>;
  state: FormField<USState>;
  zip: FormField<ZipCode>;
  monthsAtAddress: FormField<PositiveInt>;
  livingArrangementType: LivingArrangementType;
  monthlyRent: FormField<PositiveNumber>;
  addressId: Option<number>
};

export const monthsAtAddressCodec = withMessage(
  numberCodecS,
  () => "Should be a number",
).pipe(positiveIntCodec);

export const rentAmountCodec = withMessage(
  numberCodecS,
  () => "Should be a number",
).pipe(positiveNumberCodec);

export const init = (address: Option<AddressPayload>): Model => ({
  street: pipe(
    address,
    O.fold(
      () => "",
      ({ street }) => street,
    ),
    initFormField(nonEmptyStringCodec(false, ValidErrorMessage('street address')).decode),
  ),
  unit: pipe(
    address,
    O.chain(({ unit }) => unit),
  ),
  city: pipe(
    address,
    O.fold(
      () => "",
      ({ city }) => city,
    ),
    initFormField(nonEmptyStringCodec(false, ValidErrorMessage('city')).decode),
  ),
  state: pipe(
    address,
    O.fold(
      () => "",
      ({ state }) => state,
    ),
    initFormField(stateCodec.decode),
  ),
  zip: pipe(
    address,
    O.fold(
      () => "",
      ({ zip }) => zip,
    ),
    initFormField(zipCodeCodecWithMessage(ValidErrorMessage('zip code')).decode),
  ),
  monthsAtAddress: pipe(
    address,
    O.fold(
      () => "",
      ({ monthsAtAddress }) => monthsAtAddress.toString(),
    ),
    initFormField(monthsAtAddressCodec.decode),
  ),
  livingArrangementType: pipe(
    address,
    O.fold(
      () => "Rent",
      ({ livingArrangement }) => livingArrangement.type,
    ),
  ),
  monthlyRent: pipe(
    address,
    O.fold(
      () => "0",
      ({ livingArrangement }) =>
        livingArrangement.type === "Rent"
          ? livingArrangement.monthlyRent.toString()
          : "0",
    ),
    initFormField(rentAmountCodec.decode),
  ),
  addressId: pipe(address, 
    O.chain(({ addressId }) => addressId),
  )
});

export const result = (
  index: number,
  model: Model,
): t.Validation<AddressPayload> =>
  sequenceS(E.Apply)({
    sequence: E.right(index),
    street: model.street.val,
    unit: E.right(model.unit),
    city: model.city.val,
    state: model.state.val,
    zip: model.zip.val,
    addressId: E.right(model.addressId),
    monthsAtAddress: model.monthsAtAddress.val,
    livingArrangement:
      model.livingArrangementType === "Rent"
        ? pipe(
            model.monthlyRent.val,
            E.map(
              (monthlyRent): LivingArrangement => ({
                type: "Rent",
                monthlyRent,
              }),
            ),
          )
        : E.right({ type: model.livingArrangementType }),
  });
