import { BranchId, TeamId } from "@/data/client";
import { Option, none } from "fp-ts/lib/Option";
import * as EditTeam from "@/components/UserManagement/EditTeam";

export type DialogModel =
  | {
      type: "NewTeamDialog";
      dialogModel: EditTeam.Model;
    }
  | {
      type: "EditTeamDialog";
      teamId: TeamId;
      dialogModel: EditTeam.Model;
    }
  | {
      type: "DeleteTeamDialog";
      teamId: TeamId;
    };

export const NewTeamDialog = (dialogModel: EditTeam.Model): DialogModel => ({
  type: "NewTeamDialog",
  dialogModel,
});

export const EditTeamDialog =
  (teamId: TeamId) =>
  (dialogModel: EditTeam.Model): DialogModel => ({
    type: "EditTeamDialog",
    teamId,
    dialogModel,
  });

export const DeleteTeamDialog = (teamId: TeamId): DialogModel => ({
  type: "DeleteTeamDialog",
  teamId,
});

export type Model = {
  branchId: BranchId;
  dialog: Option<DialogModel>;
};

export const init = (branchId: BranchId): Model => ({
  branchId,
  dialog: none,
});

export const withDialogClosed = (model: Model): Model => ({
  ...model,
  dialog: none,
});
