import { SelfEmploymentType, WorkScheduleType } from "@/data/payload";
import { Model } from "./model";

export type Action =
  | {
      type: "W2WagesChanged";
      w2Wages: boolean;
    }
  | {
      type: "SelfEmployedChanged";
      selfEmployment: boolean;
    }
  | {
      type: "ActiveDutyMilitaryChanged";
      activeDutyMilitary: boolean;
    }
  | {
      type: "RetirementIncomeChanged";
      retirementIncome: boolean;
    }
  | {
      type: "SocialSecurityDisabilityChanged";
      socialSecurityDisability: boolean;
    }
  | {
      type: "OtherChanged";
      other: boolean;
    }
  | {
      type: "WorkScheduleTypeChanged";
      workScheduleType: WorkScheduleType;
    }
  | {
      type: "AtLeast2YearsChanged";
      atLeast2Years: boolean;
    }
  | {
      type: "BonuseOvertimeCommissionChanged";
      bonuseOvertimeCommission: boolean;
    }
  | {
      type: "SelfEmploymentTypeChanged";
      selfEmploymentType: SelfEmploymentType;
    }
  | {
      type: "SelfEmploymentW2WagesChanged";
      selfEmploymentW2Wages: boolean;
    }
  | {
      type: "OtherIncomeDescriptionChanged";
      description: string;
    };

export const W2WagesChanged = (w2Wages: boolean): Action => ({
  type: "W2WagesChanged",
  w2Wages,
});

export const SelfEmployedChanged = (selfEmployment: boolean): Action => ({
  type: "SelfEmployedChanged",
  selfEmployment,
});

export const ActiveDutyMilitaryChanged = (
  activeDutyMilitary: boolean,
): Action => ({
  type: "ActiveDutyMilitaryChanged",
  activeDutyMilitary,
});

export const RetirementIncomeChanged = (retirementIncome: boolean): Action => ({
  type: "RetirementIncomeChanged",
  retirementIncome,
});

export const SocialSecurityDisabilityChanged = (
  socialSecurityDisability: boolean,
): Action => ({
  type: "SocialSecurityDisabilityChanged",
  socialSecurityDisability,
});

export const OtherChanged = (other: boolean): Action => ({
  type: "OtherChanged",

  other,
});

export const WorkScheduleTypeChanged = (
  workScheduleType: WorkScheduleType,
): Action => ({
  type: "WorkScheduleTypeChanged",
  workScheduleType,
});

export const AtLeast2YearsChanged = (atLeast2Years: boolean): Action => ({
  type: "AtLeast2YearsChanged",
  atLeast2Years,
});

export const BonuseOvertimeCommissionChanged = (
  bonuseOvertimeCommission: boolean,
): Action => ({
  type: "BonuseOvertimeCommissionChanged",
  bonuseOvertimeCommission,
});

export const SelfEmploymentTypeChanged = (
  selfEmploymentType: SelfEmploymentType,
): Action => ({
  type: "SelfEmploymentTypeChanged",
  selfEmploymentType,
});

export const SelfEmploymentW2WagesChanged = (
  selfEmploymentW2Wages: boolean,
): Action => ({
  type: "SelfEmploymentW2WagesChanged",
  selfEmploymentW2Wages,
});

export const OtherIncomeDescriptionChanged = (description: string): Action => ({
  type: "OtherIncomeDescriptionChanged",
  description,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "W2WagesChanged":
      return {
        ...model,
        w2Wages: action.w2Wages,
      };
    case "SelfEmployedChanged":
      return {
        ...model,
        selfEmployed: action.selfEmployment,
      };
    case "ActiveDutyMilitaryChanged":
      return {
        ...model,
        activeDutyMilitary: action.activeDutyMilitary,
      };
    case "RetirementIncomeChanged":
      return {
        ...model,
        retirementIncome: action.retirementIncome,
      };
    case "SocialSecurityDisabilityChanged":
      return {
        ...model,
        socialSecurityDisability: action.socialSecurityDisability,
      };
    case "OtherChanged":
      return {
        ...model,
        other: action.other,
      };
    case "WorkScheduleTypeChanged":
      return {
        ...model,
        workSchedule: { ...model.workSchedule, type: action.workScheduleType },
      };
    case "AtLeast2YearsChanged":
      return {
        ...model,
        workSchedule: {
          ...model.workSchedule,
          atLeast2Years: action.atLeast2Years,
        },
      };
    case "BonuseOvertimeCommissionChanged":
      return {
        ...model,
        workSchedule: {
          ...model.workSchedule,
          receivesBonusOvertimeCommission: action.bonuseOvertimeCommission,
        },
      };
    case "SelfEmploymentTypeChanged":
      return {
        ...model,
        selfEmployment: {
          ...model.selfEmployment,
          type: action.selfEmploymentType,
        },
      };
    case "SelfEmploymentW2WagesChanged":
      return {
        ...model,
        selfEmployment: {
          ...model.selfEmployment,
          w2Wages: action.selfEmploymentW2Wages,
        },
      };
    case "OtherIncomeDescriptionChanged":
      return {
        ...model,
        otherDescription: action.description,
      };
  }
};
