import * as O from "fp-ts/Option";
import { Button, Row } from "../basic";
import { Card } from "../basic/Card";

type Props = {
  usersCount: number;
  applicationsCount: number;
};

export function WelcomeView(props: Props): JSX.Element {
  return (
    <Row alignHorizontal="left" gap="sm">
      {props.applicationsCount == 0 && (
        <Card title="Create an application">
          Create an application for your clients. Creating an application will
          send an AppCatcher Invite to the borrower. After registering
          themselves, they can upload the necessary documents
          <Button type="tertiary" onClick={O.some(() => {})}>
            Get Started
          </Button>
        </Card>
      )}
      <Card title="Update your settings ">
        Update notificaiton settings, view Billing Information and edit your profile.
        <Button type="tertiary" onClick={O.some(() => {})}>
          Get Started
        </Button>
      </Card>
      {props.usersCount == 0 && (
        <Card title="Invite Team">
          Invite your loan officers on this screen. You can group your officers by branch and/or by teams.
          <Button type="tertiary" onClick={O.some(() => {})}>
            Get Started
          </Button>
        </Card>
      )}
    </Row>
  );
}
