import "@/assets/styles/basic/Input/input.css";
export type InputType =
  | "text"
  | "email"
  | "tel"
  | "password"
  | "number"
  | "date"
  | "textarea";

export type InputProps = {
  value: string;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  onChange: (raw: string) => void;
  className?: string;
};

export function Input(props: InputProps): JSX.Element {
  return (
    <input
      className={`input text-md ${props.invalid ? "invalid" : ""} ${props.className}`}
      type={props.type || "text"}
      value={props.value}
      placeholder={props.placeholder}
      disabled={props.disabled}
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
}
