import * as Address from "@/components/Address";
import {
  Button,
  Col,
  InputField,
  Label,
  PhoneInputField,
  Row,
  Select,
  SSNInputField,
} from "@/components/basic";
import {
  citizenshipStatusOptions,
  maritalStatusOptions,
  showCitizenshipStatus,
  showMaritalStatus,
  totalMonthsAtAddressRequired,
} from "@/data/payload";
import { ChildProps } from "@/utils/reducerWithEffect";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { some } from "fp-ts/lib/Option";
import { Eq as EqString } from "fp-ts/lib/string";
import { Fragment, useMemo } from "react";
import {
  Action,
  AddressAction,
  AddressAdded,
  CitizenshipStatusChanged,
  DateOfBirthChanged,
  EmailChanged,
  MaritalStatusChanged,
  NameChanged,
  PhoneChanged,
  SocialSecurityNumberChanged,
} from "./action";
import { Model } from "./model";

export type Props = ChildProps<Model, Action>;

export function View({ model, dispatch }: Props): JSX.Element {
  const totalMonthsCount = useMemo(
    () =>
      pipe(
        model.addresses,
        A.mapWithIndex(Address.result),
        A.sequence(E.Applicative),
        E.map(A.reduce(0, (acc, address) => acc + address.monthsAtAddress)),
      ),
    [model.addresses],
  );

  return (
    <div className="grid-2 gap-xs collapse-gap-md">
      <InputField
        className="grid-item-1"
        label="Full Name"
        field={model.fullName}
        onChange={flow(NameChanged, dispatch)}
      />

      <InputField
        className="grid-item-1"
        label="Email"
        field={model.email}
        onChange={flow(EmailChanged, dispatch)}
      />

      <PhoneInputField
        className="grid-item-1"
        label="Phone"
        field={model.phone}
        onChange={flow(PhoneChanged, dispatch)}
      />

      <InputField
        className="grid-item-1"
        type="date"
        label="Date of Birth"
        field={model.dateOfBirth}
        onChange={flow(DateOfBirthChanged, dispatch)}
      />

      <SSNInputField
        className="grid-item-1"
        label="Social Security Number"
        field={model.socialSecurityNumber}
        onChange={flow(SocialSecurityNumberChanged, dispatch)}
      />

      <hr className="grid-item-2 line"></hr>

      <h3 className="grid-item-2 text-lg">Current Address</h3>

      {model.addresses.map((address, index) => (
        <Fragment key={index}>
          <h3 className="text-md grid-item-2">Address {index + 1}</h3>
          <Address.View
            model={address}
            dispatch={flow(AddressAction(index), dispatch)}
            onCoApplicantSharesAddressWithPrimaryApplicant={O.none}
          />
        </Fragment>
      ))}

      <Row gap="sm" className="grid-item-2">
        {pipe(
          totalMonthsCount,
          E.fold(
            () => <Label>Invalid Address</Label>,
            (totalMonths) => <Label>Total Months: {totalMonths}</Label>,
          ),
        )}
      </Row>

      {E.isRight(totalMonthsCount) &&
        totalMonthsCount.right < totalMonthsAtAddressRequired && (
          <Row gap="sm" className="grid-item-2">
            <Button type="primary" onClick={some(flow(AddressAdded, dispatch))}>
              Add Another Address
            </Button>
          </Row>
        )}

      <Col gap="sm" className="grid-item-2">
        <Label>What is your current marital status?</Label>
        <Row gap="sm" grow={1}>
          <Select
            options={maritalStatusOptions}
            selected={O.fromEither(model.maritalStatus.val)}
            valueEq={EqString}
            renderLabel={showMaritalStatus}
            onChange={flow(MaritalStatusChanged, dispatch)}
          />
        </Row>
      </Col>

      <Col gap="sm">
        <Label>What is your residency status?</Label>
        <Select
          options={citizenshipStatusOptions}
          selected={O.fromEither(model.citizenshipStatus.val)}
          valueEq={EqString}
          renderLabel={showCitizenshipStatus}
          onChange={flow(CitizenshipStatusChanged, dispatch)}
        />
      </Col>
    </div>
  );
}
