export type AlignHorizontal =
  | "center"
  | "left"
  | "right"
  | "space-between"
  | "space-around"
  | "stretch";
export type AlignVertical =
  | "center"
  | "top"
  | "bottom"
  | "space-between"
  | "space-around"
  | "stretch"
  | "baseline";

export const spacing = {
  xxxs: "0.25rem" as const,
  xxs: "0.5rem" as const,
  xs: "0.75rem" as const,
  sm: "1rem" as const,
  md: "1.5rem" as const,
  lg: "2rem" as const,
  xl: "3rem" as const,
  xxl: "4rem" as const,
  xxxl: "8rem" as const,
};

export const font = {
  s: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "0.875rem",
    lineHeight: "1.5",
  } as const,
  m: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "1rem",
    lineHeight: "1.5",
  } as const,
};

export type Spacing = keyof typeof spacing;

export const palette = {
  white: "#FFFFFF" as const,
  whiteSmoke: "#F8F8F8" as const,
  grey100: "#1D2125" as const,
  grey90: "#414A52" as const,
  grey80: "#55606A" as const,
  grey70: "#798897" as const,
  grey60: "#95A9BA" as const,
  grey50: "#B0BECA" as const,
  grey40: "#CAD5E0" as const,
  grey30: "#E1E8F0" as const,
  grey20: "#EDF2F7" as const,
  grey10: "#FAFAFC" as const,
  action70: "#2A2250" as const,
  action60: "#1D3EAF" as const,
  action50: "#244EDB" as const,
  action40: "#5071E2" as const,
  action30: "#7C95E9" as const,
  action20: "#C5D2FC" as const,
  yes70: "#04764D" as const,
  yes50: "#12C484" as const,
  yes30: "#58E4B2" as const,
  yes20: "#C5F2E2" as const,
  no70: "#A71B1B" as const,
  no50: "#DB5151" as const,
  no30: "#E19494" as const,
  no20: "#EDCFCF" as const,
  warn70: "#AFAB32" as const,
  warn50: "#DAD66C" as const,
  warn30: "#ECE9B2" as const,
  warn20: "#F5F4D6" as const,
};

export type Color = keyof typeof palette;

export const border = {
  none: "none" as const,
  default: `1px solid` as const,
};

export type WidthTypes = "fit-content" | "auto" | "1/4" | "2/4" | "3/4";

export function getWidth(width: WidthTypes) {
  switch (width) {
    case "auto":
      return "auto";

    case "fit-content":
      return "fit-content";

    case "1/4":
      return "25vw";

    case "3/4":
      return "75vw";

    case "2/4":
      return "50vw";
  }
}
