import "@/assets/styles/Wizard/wizard.css";

import * as Address from "@/components/Address";
import * as ContactInformation from "@/components/ContactInformation";
import * as FundsAndCoApplicant from "@/components/FundsAndCoApplicant";
import * as IncomeInformation from "@/components/IncomeInformation";
import * as MaritalAndResidency from "@/components/MaritalAndResidency";
import * as PropertyInformation from "@/components/PropertyInformation";
import * as ReferralInformation from "@/components/ReferralInformation";
import * as RetirementAndObligations from "@/components/RetirementAndObligations";
import * as SsnAndConsent from "@/components/SsnAndConsent";

import { ChildProps } from "@/utils/reducerWithEffect";
import {
  Action,
  AddressAction,
  AddressCompleted,
  CoApplicantSharesAddressWithPrimaryApplicant,
  ContactInformationAction,
  ContactInformationCompleted,
  FundsAndCoApplicantAction,
  FundsAndCoApplicantCompleted,
  IncomeInformationAction,
  IncomeInformationCompleted,
  MaritalAndResidencyAction,
  MaritalAndResidencyCompleted,
  PropertyInformationAction,
  PropertyInformationCompleted,
  ReferralInformationAction,
  ReferralInformationCompleted,
  RetirementAndObligationsAction,
  RetirementAndObligationsCompleted,
  SsnAndConsentAction,
  SsnAndConsentCompleted,
  StepCanceled,
} from "./action";
import { Model, surveyTipId } from "./model";

import { Button, Col, Icon, Label, Row } from "@/components/basic";
import {
  SurveyProgress,
  SurveyStepProgress,
} from "@/components/SurveyProgress";
import { SurveyPayload } from "@/data/payload";
import {
  ApplicantType,
  SurveyFlowPayload,
  toSurveyPayload,
  withSsnAndDateOfBirth,
} from "@/data/surveyFlowPayload";
import { OrdSurveyTips } from "@/data/surveyTips";
import { deferredToOption } from "@/utils/deferred";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { constFalse, flow, identity, pipe } from "fp-ts/function";
import { sequenceT } from "fp-ts/lib/Apply";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { ReactNode, useMemo } from "react";
import { ViewResolver, ViewResolverProps } from "@/utils/viewResolver";

export type Props = ChildProps<Model, Action> & {
  onFinish: (payload: SurveyPayload) => void;
  referringLoanOfficerId: Option<number>;
};

const titles: Record<ApplicantType, Record<string, string>> = {
  PrimaryApplicant: {
    CurrentAddress: "What best describes your current living arrangement?",
    PreviousAddress: "We need your previous address",
  },
  CoApplicant: {
    CurrentAddress:
      "What best describes your co-applicant's current living arrangement?",
    PreviousAddress: "We need your co-applicant's previous address",
  },
};

type StepContainerProps = {
  header?: string;
  payload: SurveyFlowPayload;
  children: ReactNode;
};

type ViewWrapperProps = {
  children: React.ReactNode;
};

function StepContainer(props: StepContainerProps): JSX.Element {
  return (
    <Col padding="xl" gap="xl">
      <SurveyProgress
        personal={pipe(
          sequenceT(O.Apply)(
            props.payload.primaryApplicantPayload.contactInformation,
            props.payload.primaryApplicantPayload.addresses,
            props.payload.primaryApplicantPayload.maritalAndResidency,
          ),
          O.map((): SurveyStepProgress => "Completed"),
          O.alt(() =>
            pipe(
              props.payload.primaryApplicantPayload.contactInformation,
              O.map((): SurveyStepProgress => "InProgress"),
            ),
          ),
          O.getOrElse((): SurveyStepProgress => "Empty"),
        )}
        financial={pipe(
          sequenceT(O.Apply)(
            props.payload.primaryApplicantPayload.incomeInformation,
            props.payload.primaryApplicantPayload.retirementAndObligations,
          ),
          O.map((): SurveyStepProgress => "Completed"),
          O.alt(() =>
            pipe(
              props.payload.primaryApplicantPayload.incomeInformation,
              O.map((): SurveyStepProgress => "InProgress"),
            ),
          ),
          O.getOrElse((): SurveyStepProgress => "Empty"),
        )}
        mortgage={pipe(
          sequenceT(O.Apply)(
            props.payload.fundsAndCoApplicant,
            props.payload.referralInformation,
          ),
          O.map((): SurveyStepProgress => "Completed"),
          O.alt(() =>
            pipe(
              props.payload.fundsAndCoApplicant,
              O.map((): SurveyStepProgress => "InProgress"),
            ),
          ),
          O.getOrElse((): SurveyStepProgress => "Empty"),
        )}
        verification={pipe(
          props.payload.primaryApplicantPayload.ssnAndDateOfBirthPayload,
          O.fold(
            (): SurveyStepProgress => "Empty",
            (): SurveyStepProgress => "Completed",
          ),
        )}
        documentation="Empty"
      />
      {props.header && <div className="survey-flow-header">{props.header}</div>}
      {props.children}
    </Col>
  );
}

function StepView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  switch (model.currentStep.type) {
    case "PropertyInformation":
      return (
        <PropertyInformation.View
          model={model.currentStep.propertyInformationModel}
          dispatch={flow(PropertyInformationAction, dispatch)}
          onCompleted={flow(PropertyInformationCompleted, dispatch)}
        />
      );

    case "ContactInformation":
      return (
        <StepContainer
          header={
            model.currentStep.applicantType === "PrimaryApplicant"
              ? "We need some initial bits of information before we get started."
              : "Let’s grab the personal details of your co-applicant."
          }
          payload={model.payload}
        >
          <ContactInformation.View
            model={model.currentStep.contactInformationModel}
            dispatch={flow(ContactInformationAction, dispatch)}
          />
        </StepContainer>
      );

    case "Address":
      {
        const header = pipe(model.currentStep, (v) => {
          const stepIndex =
            v.addressIndex == 0 ? "CurrentAddress" : "PreviousAddress";
          return titles[v.applicantType][stepIndex];
        });
        return (
          <StepContainer header={header} payload={model.payload}>
            <Address.View
              model={model.currentStep.addressModel}
              onCoApplicantSharesAddressWithPrimaryApplicant={pipe(
                model.currentStep.applicantType,
                O.fromPredicate((apTpye) => apTpye == "CoApplicant"),
                O.map(() =>
                  flow(CoApplicantSharesAddressWithPrimaryApplicant, dispatch),
                ),
              )}
              dispatch={flow(AddressAction, dispatch)}
            />
          </StepContainer>
        );
      }
      break;

    case "MaritalAndResidency":
      return (
        <StepContainer
          header="Final few questions regarding your personal information!"
          payload={model.payload}
        >
          <MaritalAndResidency.View
            model={model.currentStep.maritalAndResidencyModel}
            dispatch={flow(MaritalAndResidencyAction, dispatch)}
          />
        </StepContainer>
      );

    case "IncomeInformation":
      return (
        <StepContainer
          header="Personal information complete! Let’s run through your financial information."
          payload={model.payload}
        >
          <IncomeInformation.View
            model={model.currentStep.incomeInformationModel}
            dispatch={flow(IncomeInformationAction, dispatch)}
          />
        </StepContainer>
      );

    case "FundsAndCoApplicant":
      return (
        <StepContainer
          header="Great! Now let’s run through some of the mortgage related information."
          payload={model.payload}
        >
          <FundsAndCoApplicant.View
            model={model.currentStep.fundsAndCoApplicantModel}
            dispatch={flow(FundsAndCoApplicantAction, dispatch)}
          />
        </StepContainer>
      );

    case "RetirementAndObligations":
      return (
        <StepContainer
          header="Nearly there! Last bits of financial information?"
          payload={model.payload}
        >
          <RetirementAndObligations.View
            model={model.currentStep.retirementAndObligationsModel}
            dispatch={flow(RetirementAndObligationsAction, dispatch)}
          />
        </StepContainer>
      );

    case "ReferralInformation":
      return (
        <StepContainer
          header="Thanks! Before we move onto the final steps."
          payload={model.payload}
        >
          <ReferralInformation.View
            model={model.currentStep.referralInformationModel}
            dispatch={flow(ReferralInformationAction, dispatch)}
          />
        </StepContainer>
      );

    case "SsnAndConsent":
      return (
        <StepContainer payload={model.payload}>
          <SsnAndConsent.View
            model={model.currentStep.ssnAndConsentModel}
            dispatch={flow(SsnAndConsentAction, dispatch)}
          />
        </StepContainer>
      );
  }
}

function ViewWrapper(props: ViewWrapperProps): JSX.Element {
  const desktopView = <Row grow={1}>{props.children}</Row>;

  const mobileView = <Col grow={1}>{props.children}</Col>;

  const viewSetup: ViewResolverProps<JSX.Element> = {
    viewModes: [
      ["Default"],
      ["Mobile-Portrait", "Tablet-Portrait", "Mobile-Landscape", "Tablet-Landscape"],
    ],
    resolvedContent: [desktopView, mobileView],
  };
  return ViewResolver(viewSetup);
}

export function View(props: Props): JSX.Element {
  const { model } = props;

  const surveyTips = useMemo(() => {
    return pipe(
      model.tips,
      deferredToOption,
      O.chain(O.fromEither),
      O.fold(
        () => [],
        flow(
          A.filter(
            ({ pageIdentifier }) =>
              surveyTipId(model.currentStep) === pageIdentifier,
          ),
          A.sort(OrdSurveyTips),
        ),
      ),
    );
  }, [model.currentStep, model.tips]);

  return (
    <ViewWrapper>
      <Col grow={3} basis="0">
        <Col
          grow={1}
          className={ViewResolver({
            viewModes: [["Default"], ["Tablet-Landscape", "Tablet-Portrait", "Mobile-Portrait", "Mobile-Landscape"]],
            resolvedContent: ["survey-content", ""]
          })}
          alignHorizontal="stretch"
          background="grey10"
        >
          <StepView {...props} />
        </Col>
        <Navbar {...props} />
      </Col>
      <Col
        padding="sm"
        gap="sm"
        grow={1}
        className="tips"
        basis={ViewResolver({
          viewModes: [["Default"], ["Mobile-Portrait", "Tablet-Portrait", "Mobile-Landscape", "Tablet-Landscape"]],
          resolvedContent: ["0", "auto"],
        })}
      >
        {surveyTips.map((tip) => (
          <Col
            key={tip.pageIdentifier + tip.pageTipId}
            padding="sm"
            gap="sm"
            className="container"
            alignHorizontal="left"
          >
            <Row
              padding="xxxs"
              gap="xxxs"
              className="tip-question"
              alignVertical="center"
            >
              <Icon type="lightbulb" />
              <span>Why are you asking this?</span>
            </Row>
            <Row gap="xs">
              <img height={32} src={tip.avatarUrl} />
              <Label>
                <b>{tip.title}</b>
              </Label>
            </Row>
            <Label>{tip.content}</Label>
          </Col>
        ))}
      </Col>
    </ViewWrapper>
  );
}

export function Navbar(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const stepCompletedAction: Option<Action> = useMemo(() => {
    switch (model.currentStep.type) {
      case "PropertyInformation":
        return O.none;
      case "ContactInformation":
        return pipe(
          ContactInformation.result(model.currentStep.contactInformationModel),
          O.fromEither,
          O.map(ContactInformationCompleted(model.currentStep.applicantType)),
        );
      case "Address":
        return pipe(
          Address.result(
            model.currentStep.addressIndex,
            model.currentStep.addressModel,
          ),
          O.fromEither,
          O.map(
            AddressCompleted(
              model.currentStep.applicantType,
              model.currentStep.addressIndex,
            ),
          ),
        );

      case "MaritalAndResidency":
        return pipe(
          MaritalAndResidency.result(
            model.currentStep.maritalAndResidencyModel,
          ),
          O.fromEither,
          O.map(MaritalAndResidencyCompleted(model.currentStep.applicantType)),
        );
      case "FundsAndCoApplicant":
        return pipe(
          FundsAndCoApplicant.result(
            model.currentStep.fundsAndCoApplicantModel,
          ),
          O.fromEither,
          O.map(FundsAndCoApplicantCompleted),
        );
      case "RetirementAndObligations":
        return pipe(
          RetirementAndObligations.result(
            model.currentStep.retirementAndObligationsModel,
          ),
          RetirementAndObligationsCompleted(model.currentStep.applicantType),
          O.some,
        );
      case "IncomeInformation":
        return pipe(
          IncomeInformation.result(model.currentStep.incomeInformationModel),
          IncomeInformationCompleted(model.currentStep.applicantType),
          O.some,
        );
      case "ReferralInformation":
        return pipe(
          ReferralInformation.result(
            model.currentStep.referralInformationModel,
          ),
          O.fromEither,
          O.map(ReferralInformationCompleted),
        );
      case "SsnAndConsent":
        return pipe(
          SsnAndConsent.result(model.currentStep.ssnAndConsentModel),
          O.fromPredicate(([verifyModel, consents, consentErrors]) => {
            // console.log({verifyModel, consents, consentMethod});

            let isFormValid = E.isRight(consents) && E.isRight(verifyModel);

            isFormValid = isFormValid && consentErrors.left.length == 0;

            return isFormValid;
          }),
          O.flatMap(([verifyModel, consents]) => {
            if (E.isRight(verifyModel) && E.isRight(consents)) {
              const { primaryApplicant, coApplicant } = verifyModel.right;
              return O.some(
                SsnAndConsentCompleted(
                  primaryApplicant,
                  coApplicant,
                  consents.right,
                ),
              );
            } else {
              return O.none;
            }
          }),
        );
    }
  }, [model.currentStep]);

  const stepCanceledAction = useMemo(
    () => pipe(A.last(model.completedSteps), O.map(StepCanceled)),
    [model.completedSteps],
  );

  // for the property information step designs don't have the continue/back buttons'
  return model.currentStep.type === "PropertyInformation" ? (
    <></>
  ) : (
    <Row
      gap="xs"
      padding="xs"
      alignHorizontal="space-between"
      background="white"
    >
      <Button
        type="secondary"
        onClick={pipe(
          stepCanceledAction,
          O.map((action) => () => dispatch(action)),
        )}
      >
        <i className="fa-solid fa-arrow-left ml-right-xxs" /> Go back
      </Button>
      {model.currentStep.type === "SsnAndConsent" ? (
        <Button
          type="primary"
          onClick={pipe(
            O.of((action: Action) => (payload: SurveyPayload) => () => {
              dispatch(action);
              props.onFinish(payload);
            }),
            O.ap(stepCompletedAction),
            O.ap(
              pipe(
                SsnAndConsent.result(model.currentStep.ssnAndConsentModel),
                O.fromPredicate(([verifyModel, consents, consentMethod]) => {
                  console.log({ verifyModel, consents, consentMethod });

                  let isFormValid =
                    E.isRight(consents) && E.isRight(verifyModel);

                  isFormValid = isFormValid && consentMethod.left.length == 0;

                  return isFormValid;
                }),
                O.flatMap(([surveyModel, consents]) => {
                  if (E.isRight(surveyModel) && E.isRight(consents)) {
                    const { primaryApplicant, coApplicant } = surveyModel.right;
                    return O.some(
                      withSsnAndDateOfBirth(
                        primaryApplicant,
                        coApplicant,
                        consents.right,
                        pipe(
                          model.isLoanOfficerInviting,
                          O.fold(constFalse, identity),
                        ),
                      )(model.payload),
                    );
                  } else {
                    return O.none;
                  }
                }),

                // discarding the possible error of converting to survey payload
                // but it could be used to identify the survey step responsible for the missing data that caused the error
                // if we want to indicate it as an error message to the user
                // or navigate backe to that step
                O.chain(
                  flow(
                    toSurveyPayload(
                      model.dateTime,
                      props.referringLoanOfficerId,
                    ),
                    O.fromEither,
                  ),
                ),
              ),
            ),
          )}
        >
          {pipe(
            model.isLoanOfficerInviting,
            O.fold(
              () => <>Submit <i className="fa-solid fa-arrow-right ml-left-xxs"/></>,
              (v) => (v ? <>Send Invite and Continue <i className="fa-solid fa-arrow-right ml-left-xxs" /></> : <>Submit <i className="fa-solid fa-arrow-right ml-left-xxs" /></>),
            ),
          )}
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={pipe(
            stepCompletedAction,
            O.map((action) => () => dispatch(action))
          )}
        >
          Continue <i className="fa-solid fa-arrow-right ml-left-xxs" />
        </Button>
      )}
    </Row>
  );
}

// type SubmitSurveyButtonProps = {
//   payload: SurveyFlowPayload;
//   onFinish: (payload: SurveyPayload) => void;
// }

// function SubmitSurveyButton(props: )
