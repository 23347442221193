import { PageType } from "@/app/model";
import { ExtensionRole } from "@/data/client";
import { Application } from "@/data/payload";
import { isSuperUser } from "@/utils/user";
import { constFalse, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { parse } from "regexparam";
import { NavigationMeta } from "./navigation";

const defaultAdminRoles = [ExtensionRole.LoanOfficer, ExtensionRole.SuperUser];
export type NavigationWithApplication = NavigationMeta & { application?: Application };

export type RouteMeta = {
  route: string;
  type: PageType;
  pattern: RegExp;
  keys: string[];
  roles?: ExtensionRole[];
  accessPredicate?: (meta: NavigationWithApplication) => boolean;
};

export default [
  {
    route: "/summary/:applicationId",
    type: "Summary" as PageType,
    roles: defaultAdminRoles,
    accessPredicate: ({ user, application }: NavigationWithApplication) =>
      pipe(
        application,
        O.fromNullable,
        O.fold(
          constFalse,
          (v) => v.survey.primaryApplicant.email == user.email,
        ),
      ),
  },
  {
    route: "/account-settings/:subPage",
    type: "AccountSettings" as PageType,
    roles: defaultAdminRoles,
    accessPredicate: ({ user, matches }: NavigationWithApplication) => {
      switch (matches.subPage) {
        case "company":
        case "billing":
          if (isSuperUser(user)) {
            return true;
          }
          break;
        case "general":
        case "notifications":
          return true;
      }

      return false;
    },
  },
  {
    route: "/branches/:subPage?/:modelId?",
    type: "Branches" as PageType,
    roles: defaultAdminRoles,
  },
  {
    route: "/dashboard",
    type: "Dashboard" as PageType,
    roles: defaultAdminRoles,
  },
  {
    route: "/applications-list",
    type: "ApplicationsList" as PageType,
    roles: defaultAdminRoles,
  },
  {
    route: "/homebuyer-landing",
    type: "HomebuyerLanding" as PageType,
  },
  {
    route: "/invite",
    type: "Invite" as PageType,
  },
  {
    route: "/internalsignup",
    type: "LoInvite" as PageType,
  },
  {
    route: "/login",
    type: "Login" as PageType,
  },
  {
    route: "/applynow/*?",
    type: "ApplyNow" as PageType,
  },
].map((v): RouteMeta => {
  return {
    ...v,
    ...parse(v.route) /*pattern, keys*/,
  };
});

export function getRouteMatches(
  path: string,
  result: { pattern: RegExp; keys: string[] },
): RouteMatch {
  let i = 0;

  const out: Record<string, string | number | null> = {};
  const matches = result.pattern.exec(path);
  while (i < result.keys.length) {
    out[result.keys[i]] = (matches && matches[++i]) || null;
  }
  return out;
}

export type RouteMatch = Record<string, string | number | null>;
