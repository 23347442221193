import {
  Button,
  Col,
  Icon,
  Input,
  InputField,
  Label,
  Row,
  Select,
} from "@/components/basic";
import { LivingArrangementType } from "@/data/payload";
import { allUsStates, EqUSState, showUsState } from "@/data/states";
import { ChildProps } from "@/utils/reducerWithEffect";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Eq as EqString } from "fp-ts/lib/string";
import {
  Action,
  CityChanged,
  LivingArrangementTypeChanged,
  MonthsAtAddressChanged,
  RentAmountChanged,
  StateChanged,
  StreetChanged,
  UnitChanged,
  ZipChanged,
} from "./action";
import { Model } from "./model";
import { ViewResolver } from "@/utils/viewResolver";

const livingArrangementOptions: LivingArrangementType[] = [
  "LivingRentFree",
  "Rent",
  "OwnAndMakingPayments",
  "OwnAndPaidInFull",
];

function showLivingArrangementType(
  livingArrangementType: LivingArrangementType,
): string {
  switch (livingArrangementType) {
    case "LivingRentFree":
      return "Living Rent Free";
    case "Rent":
      return "Rent";
    case "OwnAndMakingPayments":
      return "Own and Making Payments";
    case "OwnAndPaidInFull":
      return "Own and Paid in Full";
  }
}

export type Props = ChildProps<Model, Action> & {
  onCoApplicantSharesAddressWithPrimaryApplicant: O.Option<() => void>;
};

export function View({
  model,
  dispatch,
  onCoApplicantSharesAddressWithPrimaryApplicant,
}: Props): JSX.Element {

  const normalView = (v: () => void) => (
    <Row
      padding="lg"
      gap="sm"
      alignVertical="center"
      className="property-item column-span-2"
    >
      <Col gap="sm" grow={1}>
        <h3>Same address as Primary Applicant</h3>
      </Col>
      <Button type="primary" onClick={O.some(v)} className="same-address-btn">
        <Icon type="arrow-right" />
      </Button>
    </Row>
  );
  const collapsedView = (v: () => void) => (
    <Col gap="sm" alignVertical="center" className="property-item collapse-view w-100">
      <Button type="secondary" onClick={O.some(v)} className="same-address-btn">
        Same address as Primary Applicant
        <Icon type="arrow-right" />
      </Button>
    </Col>
  );

  const sameAddressAsApplicantViewWrapper = (v: () => void) =>
    ViewResolver({
      viewModes: [["Default"], ["Mobile-Portrait"]],
      resolvedContent: [normalView(v), collapsedView(v)],
    });
  return (
    <>
      {pipe(
        onCoApplicantSharesAddressWithPrimaryApplicant,
        O.fold(
          () => <></>,
          (v) => (
            sameAddressAsApplicantViewWrapper(v)
          ),
        ),
      )}

      <div className="grid-2 gap-xs collapse-gap-md">
        <Col gap="sm">
          <Label>Your current living arrangement</Label>
          <Select
            options={livingArrangementOptions}
            selected={pipe(model.livingArrangementType, O.some)}
            valueEq={EqString}
            renderLabel={showLivingArrangementType}
            onChange={flow(LivingArrangementTypeChanged, dispatch)}
          />
        </Col>

        {model.livingArrangementType === "Rent" && (
          <InputField
            type="number"
            label="Rent Amount"
            field={model.monthlyRent}
            onChange={flow(RentAmountChanged, dispatch)}
            required={true}
          />
        )}

        <InputField
          type="number"
          label="Months at Address"
          field={model.monthsAtAddress}
          onChange={flow(MonthsAtAddressChanged, dispatch)}
          required={true}
        />

        <InputField
          label="Street Address"
          field={model.street}
          onChange={flow(StreetChanged, dispatch)}
          required={true}
        />

        <Col gap="sm">
          <Label>Unit</Label>
          <Input
            type="text"
            value={pipe(model.unit, O.fold(constant(""), identity))}
            onChange={flow(UnitChanged, dispatch)}
          />
        </Col>

        <InputField
          label="City"
          field={model.city}
          onChange={flow(CityChanged, dispatch)}
          required={true}
        />

        <Col gap="sm">
          <Label>State *</Label>
          <Select
            options={allUsStates}
            selected={pipe(model.state.val, O.fromEither)}
            renderLabel={showUsState}
            valueEq={EqUSState}
            menuPlacement="top"
            onChange={flow(StateChanged, dispatch)}
          />
        </Col>

        <InputField
          label="Zip Code"
          field={model.zip}
          onChange={flow(ZipChanged, dispatch)}
          required={true}
        />
      </div>
    </>
  );
}
