import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Col, Icon, Modal } from "../basic";
import { BounceLoader } from "react-spinners";
import { Deferred } from "@/utils/deferred";
import { ApiResult } from "@/utils/request";
import { pipe } from "fp-ts/lib/function";

export function TakePicture(props: {
  onUpload: (imageData: string) => void;
  onClose: () => void;
  imageUploadStatus: Deferred<ApiResult<void>>;
}): JSX.Element {
  type VideoStatus = "ok" | "none" | "pending";

  const camera = useRef<Webcam>(null);
  const [image, setImage] = useState<string>("");
  const [videoStatus, setVideoStatus] = useState<VideoStatus>("pending");
  const [error, setError] = useState<string | DOMException | null>(null);

  const videoConstraints = {
    facingMode: { ideal: "asdsd" },
  };

  const capture = useCallback(() => {
    setImage(camera.current?.getScreenshot() as string);
  }, [camera]);

  const errorMessage = error
    ? (error as DOMException).name
      ? (error as DOMException)?.name
      : error
    : "";

  useEffect(() => {
    if (
      props.imageUploadStatus.status == "Resolved" &&
      E.isRight(props.imageUploadStatus.value)
    ) {
      props.onClose();
    }
  }, [props]);

  const containsImage = image.length > 0;

  const buttonsWhileImagesPresent = pipe(
    props.imageUploadStatus,
    (imageStatus) => {
      switch (imageStatus.status) {
        case "NotStarted":
          return (
            <>
              <button
                onClick={() => {
                  setImage("");
                  setVideoStatus("pending");
                }}
              >
                <Icon type="xmark"></Icon>
              </button>
              <button onClick={() => props.onUpload(image)}>
                <Icon type="upload"></Icon>
              </button>
            </>
          );
        case "InProgress":
        case "Updating":
          return <>Uploading Image...</>;
        case "Resolved":
          if (E.isLeft(imageStatus.value)) {
            return <>There was an error while upload the image </>;
          }
          return <>Image successfully uploaded </>;
      }
    },
  );
  return (
    <Modal
      onClose={O.some(props.onClose)}
      title={O.some(`Take Photo: ${videoStatus}`)}
    >
      <Col className="take-picture-modal">
        <Col alignHorizontal="center">
          <Col width="2/4" alignHorizontal="center" gap="xxs">
            <div className={`media-container ${videoStatus}`}>
              <Col className="camera-container ">
                {containsImage ? (
                  <div className="preview">
                    <div
                      style={{ width: "fit-content" }}
                      className="image-container "
                    >
                      <img
                        src={image}
                        alt="Taken photo"
                        className={`${image ? "selected" : ""}`}
                      />
                    </div>
                  </div>
                ) : (
                  <Webcam
                    audio={false}
                    onUserMediaError={(e) => {
                      setVideoStatus("none");
                      setError(e);
                    }}
                    className="preview"
                    onUserMedia={() => setVideoStatus("ok")}
                    ref={camera}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    display: "flex",
                    width: "100%",
                    gap: "1em",
                    justifyContent: "center",
                  }}
                >
                  {containsImage ? (
                    buttonsWhileImagesPresent
                  ) : (
                    <button onClick={capture}>
                      <Icon type="camera"></Icon>
                    </button>
                  )}
                </div>
              </Col>
              {videoStatus == "none" && error && (
                <p>
                  <>{errorMessage}</>
                </p>
              )}
              {videoStatus == "pending" && (
                <Col grow={1} alignHorizontal="center" padding="xxl">
                  <BounceLoader />
                </Col>
              )}
            </div>
          </Col>
        </Col>
      </Col>
    </Modal>
  );
}
